.tooltip-trigger {
  position: relative;
  margin-left: 10px;
  margin-top: 3px;

  img {
    cursor: pointer;
  }

  .tooltip-window {
    position: absolute;
    top: 25px;
    left: -10px;
    background: $secondary-color;
    color: $white-color;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    gap: 10px;
    width: 317px;
    z-index: 100;
  }

  .tooltip-arrow {
    position: absolute;
    top: -8px;
    left: 6%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $secondary-color;
  }

  .tooltip-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
  }

  .tooltip-title p {
    margin: 0;
    font-weight: bold;
    color: $white-color;
    @extend .montserrat_bold;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
  }

  .tooltip-navigation {
    display: flex;
    gap: 10px;
    width: 50px;
  }

  .tooltip-arrow-left,
  .tooltip-arrow-right {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    img {
      width: 20px;
      height: 20px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

    .tooltip-separator {
    border: 0;
    border-top: 1px solid $white-color;
    margin: 3px 0 10px 0;
  }

  .tooltip-content p {
    color: $white-color;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 16px;
    @extend .montserrat_regular;
  }

  .tooltip-content .events-p {
    line-height: 18px;
  }

  .tooltip-content span {
    color: $white-color;
    font-size: 12px;
    line-height: 16px;
    @extend .montserrat_semibold;
  }
}
