$dark-blue: $secondary-color;
$Light-blue-clr: $primary-color;
$light-black: $form-lable-color;
$success-msg-green: $success-msg-color;
$red-clr-required: $red-required;
$red-clr-border: $red-border;
$success-clr-bg: $success-color;
$grey-100: $grey-color;
$light-clr-yellow: $light-yellow;
$light-clr-sky: $light-sky;
$lighten-dark-bluescroll: $lighten-dark-blue;
$white-clr-transparent: $transparent;

@mixin global_styles($styles: $global-styles) {
  font-size: map-get($styles, font-size);
  -webkit-font-smoothing: antialiased;
  scrollbar-width: thin;
  scrollbar-color: map-get($styles, scrollbar-color);
  text-rendering: optimizeLegibility;
}

// Added mixins to replace commonly used classes and lessen @extend usage to reduce compile time significantly 

@mixin d_flex {
  display: flex;
  display: -webkit-flex;
}

@mixin d_flex_center {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

@mixin d_flex_between {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}

@mixin d_block {
  display: block;
}

@mixin d_none {
  display: none;
}

@mixin flex_align_center {
  align-items: center;
}

@mixin flex_justify_center {
  justify-content: center;
}

@mixin flex_justify_between {
  justify-content: space-between;
}

@mixin justify_content_end {
  justify-content: flex-end;
}

@mixin over_hidden {
  overflow: hidden;
}

@mixin w_100 {
  width: 100%;
}

@mixin h_100 {
  height: 100%;
}

@mixin fs_16 {
  font-size: 1.6rem;
}

@mixin fw_700 {
  font-family: "Montserrat_bold";
  font-weight: 700;
}

@mixin fw_400 {
  font-family: "Montserrat_ragular";
  font-weight: 400;
}

@mixin trans {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

$transition-properties: (
  "webkit": "-webkit-",
  "moz": "-moz-",
  "ms": "-ms-",
  "o": "-o-",
  "standard": "",
);

@mixin transition($property) {
  $property-name: if(
    $property == "all",
    "all",
    map-get($transition-properties, "standard") + $property
  );

  @each $prefix, $prefix-value in $transition-properties {
    $prefixed-property: map-get($transition-properties, $prefix) + $property;
    #{$prefixed-property}: $property-name;
  }

  transition: $property-name;
}

@function sum($numbers...) {
  $sum: 0;
  @each $number in $numbers {
    $sum: $sum + $number;
  }
  @return $sum;
}

@mixin button {
  max-width: 24.9rem;
  @include w_100;
  padding: 1.2rem 2.4rem;
  border-radius: 0.8rem;
  border: unset;
  @include fs_16;
  @extend .montserrat_bold;
  @include fw_700;
  line-height: sum(1rem, 1.4rem);
  color: $white-color;
  @include d_flex_center;
  margin: 0.8rem 0.8rem 0.8rem 0;
  @include trans;
  &:hover {
    @include trans;
  }
}

@mixin button($styles: $button) {
  font-size: map-get($styles, font-size);
  border-radius: map-get($styles, border-radius);
  background-color: map-get($styles, background-color);
  color: map-get($styles, color);
}

@function calculate-padding($values) {
  $sum: 0;
  @each $value in $values {
    $sum: $sum + $value;
  }
  @return $sum;
}

@mixin button($style: ()) {
  $bg-color: map-get($style, "bg-color", $white-color);
  $text-color: map-get($style, "text-color", $light-black);
  $border: map-get($style, "border", $Light-blue-clr);
  $hover-bg-color: map-get($style, "hover-bg-color", $error-msg);

  max-width: 24.9rem;
  @include w_100;
  padding: 1.2rem 2.4rem;
  border-radius: 0.8rem;
  border: map-get($style, $bg-color, unset);
  @include fs_16;
  @extend .montserrat_bold;
  @include fw_700;
  line-height: 2.4rem;
  color: $text-color;
  background-color: map-get($style, "bg-color", transparent);
  @include d_flex_center;
  margin: 0.8rem 0.8rem 0.8rem 0;
  @include trans;

  &:hover {
    background-color: map-get($style, "hover-bg-color", $Light-blue-clr);
  }
}

@mixin back_btn {
  button {
    @extend .fs_18;
    @extend .montserrat_medium;
    @extend .fw_500;
    line-height: 140%;
    .back_btn_img {
      border: 0.1rem solid $tab-text;
      border-radius: 5rem;
      width: 2.4rem;
      margin-right: 2rem;
      padding: 0.2rem;
    }
  }
}

@mixin label {
  @include fs_16;
  line-height: 2.2rem;
  @include d_block;
  @include fw_700;
  margin-bottom: 0.8rem;
  color: $light-black;
}

@mixin profile_small {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 5rem;
  flex-shrink: 0;
}

@mixin plus_more {
  @extend .fs_12;
  @include fw_700;
  line-height: 140%;
  color: $Light-blue-clr;
  text-decoration: underline;
  @extend .cursor_pointer;
}

@mixin step-count {
  width: 2.4rem;
  height: 2.4rem;
  @extend .radius-50;
  border: 0.2rem solid $dark-bg;
  @include fs_16;
  @include fw_700;
  color: $dark-bg;
  @include d_flex;
  @extend .justify_content_center;
  @extend .align_items_center;
  margin-right: 1.6rem;
  &.active {
    background-color: $dark-bg;
    color: $white-color;
  }
  &.disable {
    background-color: $separtor-color;
    color: $white-color;
    border-color: $separtor-color;
  }
}

@mixin position_relative {
  position: relative;
}

@mixin position_absolute {
  position: absolute;
}

@mixin position_fixed {
  position: fixed;
}

@mixin position($sticky) {
  position: $sticky;
}

$sticky: sticky;

@mixin tooltip($tool-properties: $tooltip-properties) {
  color: map-get($tool-properties, color);
  @extend .fs_14;
  @include fw_400;
  line-height: map-get($tool-properties, line-height);
  padding: map-get($tool-properties, padding);
  @extend .radius_4;
  background: map-get($tool-properties, background);
  @extend .d_flex_align;
  gap: map-get($tool-properties, gap);
  @include position_absolute;
  top: map-get($tool-properties, top);
  left: map-get($tool-properties, left);
  display: map-get($tool-properties, display);
  @extend .white_space_nowrap;
  @extend .hover_transition;
}

@mixin full_username($custom-properties: $full-username-properties) {
  @extend .fs_14;
  @include fw_400;
  line-height: map-get($custom-properties, line-height);
  padding: map-get($custom-properties, padding);
  @extend .radius_4;
  background: map-get($custom-properties, background);
  color: map-get($custom-properties, color);
  @extend .all_tooltip_according;
  @extend .d_flex_align;
  gap: map-get($custom-properties, gap);
  @include position_absolute;
  top: map-get($custom-properties, top);
  left: map-get($custom-properties, left);
  right: map-get($custom-properties, right);
  z-index: map-get($custom-properties, z-index);
  display: map-get($custom-properties, display);
  @extend .cursor_pointer;
  @extend .white_space_nowrap;

  img {
    width: 1.6rem;
    height: 1.6rem;
    @extend .img_object_center;
    @include d_none;
  }
}

@mixin i_popup_content($ipopup-custom-properties: $ipopup-content-properties) {
  background-color: map-get($ipopup-custom-properties, background-color);
  padding: map-get($ipopup-custom-properties, padding);
  margin: map-get($ipopup-custom-properties, margin);
  border-radius: map-get($ipopup-custom-properties, border-radius);
  box-shadow: map-get($ipopup-custom-properties, box-shadow);
  @extend .position_fixed;
  @include w_100;
  top: map-get($ipopup-custom-properties, top);
  left: map-get($ipopup-custom-properties, left);
  transform: map-get($ipopup-custom-properties, transform);
  max-width: map-get($ipopup-custom-properties, max-width);
  overflow-y: map-get($ipopup-custom-properties, overflow-y);
  overflow-x: map-get($ipopup-custom-properties, overflow-x);
  @include trans;
}

@mixin modal_common {
  width: 100%;
  padding: 0 1.5rem;
  @include position_relative;
  z-index: 99999;
  @include apply_animation(popup_animate);
}

@mixin modal_common_after {
  content: "";
  @extend .position_fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  background-color: #1623534d;
  z-index: 0;
  @include apply_animation(popup_animate);
}

@function get-animation($name) {
  @return map-get($animation-configurations, $name);
}
@mixin apply_animation($name) {
  $config: get-animation($name);
  animation: map-get($config, animation);
  @keyframes {
    $keyframes: map-get($config, keyframes);
    @each $key, $value in $keyframes {
      #{$key} {
        @each $property, $property-value in $value {
          #{$property}: $property-value;
        }
      }
    }
  }
}

@function get-color($name) {
  @return map-get($bgcolor-configurations, $name);
}

@mixin apply_bgcolor($name) {
  $config: get-color($name);
  background: map-get($config, background);

  @if map-has-key($config, keyframes) {
    $keyframes: map-get($config, keyframes);

    @each $key, $value in $keyframes {
      @keyframes #{$key} {
        @each $property, $property-value in $value {
          #{$property}: $property-value;
        }
      }
    }
  }
}

@function get-body($name) {
  @return map-get($body-configurations, $name);
}

@mixin apply_body($name) {
  $config: get-body($name);
  background: map-get($config, background);

  @if map-has-key($config, keyframes) {
    $keyframes: map-get($config, keyframes);

    @each $key, $value in $keyframes {
      @keyframes #{$key} {
        @each $property, $property-value in $value {
          #{$property}: $property-value;
        }
      }
    }
  }
}

@function get-color($name) {
  @return map-get($color-configurations, $name);
}

@mixin apply_color($name) {
  $config: get-color($name);
  color: map-get($config, color);

  @if map-has-key($config, keyframes) {
    $keyframes: map-get($config, keyframes);

    @each $key, $value in $keyframes {
      @keyframes #{$key} {
        @each $property, $property-value in $value {
          #{$property}: $property-value;
        }
      }
    }
  }
}

$media-query-guard: null !default;

@function is-custom-media-query($custom) {
  @return (
    type-of($custom) == "string" and str-index($custom, "media") != null and
      str-index($custom, "max-width") != null
  );
}

$media-query-guard: true;


@mixin media_query($breakpoint) {
  $max-width: map-get($breakpoints, $breakpoint);

  @if $max-width {
    @media screen and (max-width: $max-width) {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint #{$breakpoint}.";
  }
}

@mixin i_popup($properties: ()) {
  $merged-properties: map-merge($ipopup-properties, $properties);

  @each $property, $value in $merged-properties {
    #{$property}: $value;
  }
}

@mixin common-head-li($custom-styles: ()) {
  $styles: map-merge($default-li-styles, $custom-styles);

  @each $property, $value in $styles {
    #{$property}: $value;
  }
  @include d_flex_center;
  @extend .text_center;
  @include w_100;
}

@mixin shape-styles($custom-styles: (), $hover-bg-color: $dark-blue) {
  $styles: map-merge($default-shape-styles, $custom-styles);

  @each $property, $value in $styles {
    #{$property}: $value;
  }

  @extend .position-relative;

  &:hover {
    background-color: $table-hover;
    border-color: $primary-color;
    @include trans;
  }

  &.circle-shape {
    &::before {
      @extend .position-absolute;
      width: 0.8rem;
      height: 0.8rem;
      background-color: $Light-blue-clr;
      @extend .radius-50;
      right: 0;
      top: 0;
      @include d_none;
    }
  }
}

@mixin table-styles($custom-styles: ()) {
  $styles: map-merge($default-table-styles, $myStyles);
  $styles: map-merge($styles, $custom-styles);
  @each $property, $value in $styles {
    #{$property}: $value;
  }
}

@mixin apply_optionStatus($custom-styles: ()) {
  $styles: map-merge($option-dropdown-configurations, $custom-styles);
  @each $property, $value in $styles {
    #{$property}: $value;
  }
}

@mixin status_tab($class-name: null) {
  $selector: if($class-name, $class-name, ".status_tab");

  :at-root #{$selector} {
    @include fs_16;
    @include fw_400;
    color: $tab-head;
    padding: 0.5rem 1.2rem;
    min-height: 3rem;
    background-color: transparent;
    @extend .radius_6;
    vertical-align: middle;
    @include d_flex;
    @extend .align_items_center;

    &.active {
      background-color: $success-clr-bg;
    }
    &.pending {
      background-color: $grey-100;
    }
    &.inprogress {
      background-color: $light-yellow;
    }
    &.event_disabled {
      background-color: #eeeeee;
    }
    &.completed {
      background-color: $light-sky;
    }
    &.notstarted {
      background-color: #fbe7e9;
    }
    &.matching_inprogress {
      background-color: #f7dcb4;
    }
    &.ready_to_be_matched {
      background-color: #d1f3f1;
    }
    &.matched {
      background-color: $success-clr-bg;
      &.matched_not_registered {
        max-width: 16rem;
      }
    }
    &.matched_not_registered {
      max-width: 13rem;
    }
    &.invited {
      background-color: $grey-100;
    }
    &.archived {
      background-color: $border-color;
    }
    &.draft {
      background-color: $white-color;
      color: $tab-text;
    }
    &.declined {
      background-color: #ea332333;
      color: $black-color;
    }
    &.invited_border {
      border-color: #d9d9d9 !important;
    }
    &.completed_border {
      border-color: #9aa7c7 !important;
    }
    &.matched_border {
      border-color: #91e48f !important;
    }
  }
}

@mixin apply_btn($btn_type, $additional_properties: ()) {
  @each $type, $properties in $button-styles {
    @if $type == $btn_type {
      &.#{$type} {
        @each $prop, $value in $properties {
          #{$prop}: $value;
        }
        @each $prop, $value in $additional_properties {
          #{$prop}: $value;
        }
        &:hover {
          @each $hover_prop, $hover_value in map-get($properties, hover) {
            #{$hover_prop}: $hover_value;
          }
        }
      }
    }
  }
}
