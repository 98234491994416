.reporting_page {
  .filter_dropdown {
    .raect_dropdown {
      .css-1n6sfyn-MenuList {
        .css-tr4s17-option {
          padding: 1.5rem 1.6rem !important;
        }
      }
    }
  }

  .reporting_feed {
    .reporting_feed_wrapper {
      background: $table-hover;
      border-radius: 0.8rem;
      border: 0.1rem solid $primary-color;
      min-height: 7.2rem;
      padding: 1.3rem 2rem;
      .feed_instruction {
        @include fs_16;
        @extend .fw_500;
        color: $tab-text;
      }
      .btn {
        &.border_btn {
          min-width: 20.2rem;
          min-height: 4.4rem;
          padding: 0;
        }
      }
    }
  }

  .form_group {
    .select_region {
      .form_control {
        &.school_down_gray_react {
          background: url(../../../public/images/down_gray_react.svg) no-repeat right 1.5rem center;
          &.active {
            background: url(../../../public/images/down_gray_react_Up.svg) no-repeat right 1.5rem center !important;
          }
        }
      }
    }
  }

  .reporting-chart-wrap {
    padding: 3.2rem;
  }
}
