.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $grey-text;
  background-color: $white-color;
  width: 100%;
  height: 56px;
  padding: 13px 32px 0 32px;
  margin-bottom: -11px;
  @include fs_16;
  @extend .montserrat_medium;
  @extend .fw_500;
}

.navbar-label {
  margin-right: 23px;
}

.navbar button {
  margin-right: 22px;
  padding: 11px 0;
  border: none;
  background: none;
  cursor: pointer;
}

.navbar button.active {
  @extend .montserrat_bold;
  color: $primary-color;
  border-bottom: 3px solid $primary-color;
}
