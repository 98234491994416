.wrapper {
  @include media_query(two_extra_large) {
    overflow-y: hidden;
  }
  .app {
    @include apply_bgcolor(dark_blue);
    width: 256px;
    @extend .position_fixed;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    padding: 1.7rem 0 0 0;
    transition: all 0.3s ease-in-out;
    @include media_query(two_extra_large) {
      transform: translate(-100%, 0%)
    }
    .logo-box {
      @include d_flex;
      @extend .justify_content_center;
      padding-bottom: 5.2rem;
    }
    .dashboard-menu {
      height: calc(100vh - 118px);
      .menu-wrapper {
        overflow-y: auto;
        height: 100%;
        overflow-x: hidden;
        padding-bottom: 6rem;
        scrollbar-width: none; /* For FireFox Only */
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        &::-webkit-scrollbar {
          width: 0;
          @include d_none;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
        }
        .menu {
          @include over_hidden;
          .menu_list {
            @extend .position-relative;
            transition: .5s ease;
            padding: 16px 24px 16px 24px;
            margin-top: 10px;
            margin-bottom: 10px;
            height: 57px;
            .menu_name_icon {
              background-color: transparent;
              @extend .position-relative;
              @include trans;
              .menu-icon {
                margin-right: 1.5rem;
                @include d_flex;
                @extend .align_items_center;
                svg {
                  path {
                    stroke: #B2C6E2;
                  }
                  &.mentees_icon,
                  &.groups_icon {
                    path {
                      stroke: none;
                      fill: #B2C6E2;
                    }
                  }
                  &.events_icon {
                    path {
                      stroke: none;
                    }
                  }
                }
              }
              .menu-text {
                color: #B2C6E2;
                font-size: 16px;
                // font-weight: medium;
                font-weight: 500;
                line-height: 140%;
                @extend .text_capitalize;
              }
            }
            &.active {
              background-color: rgba(255, 255, 255, 0.1);
              .menu_name_icon {
                .menu-text {
                  color: #FFFFFF;
                  // @include fw_700;
                  font-weight: 600;
                }
                svg {
                  path {
                    stroke: #FFFFFF;;
                  }
                  line {
                    stroke: #FFFFFF;;
                  }
                  b v ellipse {
                    stroke: #FFFFFF;
                  }
                  circle {
                    stroke: #FFFFFF;;
                  }
                  ellipse {
                    stroke: #FFFFFF;
                  }
                  &.mentees_icon,
                  &.groups_icon,
                  &.message_icon {
                    path {
                      fill: #FFFFFF;
                    }
                    line {
                      fill: #FFFFFF;
                    }
                    b v ellipse {
                      fill: #FFFFFF;
                    }
                    circle {
                      fill: #FFFFFF;
                    }
                    ellipse {
                      fill: #FFFFFF;
                    }
                  }
                  &.events_icon {
                    path {
                      stroke: none;
                      fill: #FFFFFF;
                    }
                  }
                  &.content_Icon {
                    path {
                      fill: #FFFFFF;
                    }
                  }
                }
              }
              &:after {
                right: 0;
                opacity: 1;
              }
            }
            &:hover {
              .menu_name_icon {
                .menu-text{
                  color: #FFFFFF;
                }
                svg {
                  path {
                    stroke: #FFFFFF;;
                  }
                  line {
                    stroke: #FFFFFF;;
                  }
                  b v ellipse {
                    stroke: #FFFFFF;
                  }
                  circle {
                    stroke: #FFFFFF;;
                  }
                  ellipse {
                    stroke: #FFFFFF;
                  }
                  &.mentees_icon,
                  &.groups_icon,
                  &.message_icon {
                    path {
                      fill: #FFFFFF;
                    }
                    line {
                      fill: #FFFFFF;
                    }
                    b v ellipse {
                      fill: #FFFFFF;
                    }
                    circle {
                      fill: #FFFFFF;
                    }
                    ellipse {
                      fill: #FFFFFF;
                    }
                  }
                  &.events_icon {
                    path {
                      stroke: none;
                      fill: #FFFFFF;
                    }
                  }
                  &.content_Icon {
                    path {
                      fill: #FFFFFF;
                    }
                  }
                }
              }
              background-color:#3A4A89;
            }
            &.active::before {
              content: '';
              @extend .position-absolute;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
              top: 0rem;
              left: 0rem;
              height: 57px;
              width: 7px;
              background-color: #1373E7;
            }
          }
        }
      }
    }
  }
  .main {
    display: -webkit-box;
    display: -ms-flexbox;
    @include d_flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc(100% - 26rem);
    min-block-size: 100vh;
    margin-left: auto;
    @include position_relative;
    @include over_hidden;
    transition: all 0.3s ease-in-out;
    @include media_query(two_extra_large){
      width: 100%;
      min-width: 991px;
    }
    &::before {
      content: "";
      background: url(../../../public/images/main_bg.svg) no-repeat center;
      @extend .position_fixed;
      top: 50%;
      width: 28.3rem;
      height: 29.9rem;
      left: 12rem;
      z-index: -1;
    }
    &::after {
      content: "";
      background: url(../../../public/images/main_bg.svg) no-repeat center;
      @extend .position_fixed;
      top: 22rem;
      width: 28.3rem;
      height: 29.9rem;
      right: -9rem;
      z-index: -1;
    }
    header {
      padding: 1.5rem 4.3rem 1.5rem 3.2rem;
      height: 8.2rem;
      background-color: #F9F9FC;
      @include position_relative;
      z-index: 9;
      .header-left {
        @include d_flex;
        @extend .align_items_center;
        .cms_hamburger{
          margin-right: 2rem;
          @include d_none;
          @include media_query(two_extra_large) {
            display: block;
          }
          .menu_icon{
            width: 4rem;
            height: 4rem;
            border: 0.1rem solid $border-color;
            border-radius: 0.5rem;
            @include d_flex;
            @extend .flex_direction_column;
            @extend .align_items_center;
            @extend .justify_content_center;
            span{
              width: 50%;
              height: 2px;
              margin: 2.5px auto;
              background-color: $secondary-color;
              @include position_relative;
              transition: all 0.3s ease-in-out;
              &:before,
              &:after{
                content: "";
                position: absolute;
                width: 100%;
                background-color: $secondary-color;
                height: 2px;
                left: 0;
                transition: all 0.3s ease-in-out;
              }
              &:before{
                top: -7px;
              }
              &:after{
                bottom: -7px;
              }
            }
          }
        }
        .user_title {
          @extend .fs_20;
          @extend .fw_600;
          line-height: 110%;
          @extend .position-relative;
          color: $title-color;
        }
        .back_btn {
          @extend .d_flex_align;
          align-items: self-start;
          color: $black-color;
          @extend .fs_18;
          @extend .fw_500;
          line-height: 140%;
          padding-left: 4.5rem;
          .back_text {
            @include fw_700;
            margin-left: 0.5rem;
          }
          .back_icon {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 2rem;
            @include d_flex_center;
            @extend .cursor_pointer;
          }
          &.return_page {
            padding-left: 0;
          }
        }
      }
      .header-right {
        li {
          margin-left: 1.6rem;
          .shape {
            @extend .header_notification;
          }
          .user-text {
            padding-left: 1.6rem;
            padding-right: 2rem;
            @extend .position-relative;
            p {
              @include fs_16;
              @extend .fw_600;
              line-height: 2rem;
              color: $dark-blue;
            }
            span {
              @extend .fs_12;
              line-height: 2rem;
              @extend .fw_500;
              color: $grey-text;
            }
            &:after {
              @extend .position-absolute;
              top: 6px;
              right: 0;
              background-image: url(../../../public/images/down_arrow.svg);
              background-repeat: no-repeat;
              width: 1rem;
              height: 0.7rem;
              background-size: contain;
            }
          }
        }
        .notification_dot {
          @include position_absolute;
          top: 0;
          right: 0;
          z-index: 99;
        }
      }
      &.header_sticky {
        @extend .position_fixed;
        width: inherit;
        z-index: 99;
        background: $white-color;
      }
    }
  }
  &.open_menu{
    @include media_query(two_extra_large) {
    overflow: hidden;
    .app{
      transform: translate(0px, 0px);
    }
    .main {
      transform: translate(245px, 0%);
      .cms_hamburger {
        .menu_icon {
          span{
            background: transparent !important;
            &:before{
              transform: rotate(45deg);
              top: 0px !important;
            }
            &:after{
              transform: rotate(-45deg);
              bottom: 0 !important;
            }
          }
        }
      }
    }
    }
  }
}
.status_tab {
  @include fs_16;
  @include fw_400;
  color: $tab-head;
  padding: 0.5rem 1.2rem;
  min-height: 3rem;
  background-color: transparent;
  @extend .radius_6;

  &.active {
    background-color: $success-clr-bg;
  }

  &.pending {
    background-color: $grey-100;
  }
  &.status_lable {
    @extend .d_inline_block;
    border-radius: 3rem;
    background: $grey-100;
    margin-bottom: 0.9rem;
    .remove_cross {
      width: 1.6rem;
      height: 1.6rem;
      @include d_flex_center;
      @extend .cursor_pointer;
    }
  }
}

.lable_menu {
  @extend .d_inline_block;
  @extend .d_flex_align;
  @extend .flex_wrap;
  &.add_more_lable {
    margin-bottom: 4.8rem;
  }
  gap: 0.4rem;
  .lable_tab {
    border-radius: 3rem;
    background: $grey-100;
    margin-bottom: 0.9rem;
    padding: 0.8rem 1.2rem;
    @include d_flex_center;
    @extend .justify_content_start;
    gap: 0.4rem;
    .status_lable {
      @extend .d_inline_block;
      @extend .fs_12;
      @include fw_400;
      line-height: 140%;
      color: $tab-head;
    }
    .remove_cross {
      width: 1.6rem;
      height: 1.6rem;
      @include d_flex_center;
      @extend .cursor_pointer;
    }
  }
}

.return_btn {
  @include d_flex_center;
  gap: 2rem;
  .back_btn {
    @extend .d_flex_align;
    align-items: self-start;
    @extend .cursor_pointer;
    padding-left: 0;
  }
  .back_text {
    color: $black-color;
    @extend .fs_18;
    @extend .fw_500;
    line-height: 140%;
  }
}
