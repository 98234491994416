.add_mentors_user {
  .toster_waper {
    max-width: 55rem;
  }
  .custom_select_mentor {
    .blue_border {
      .css-13cymwt-control {
        border: 0.1rem solid #0e56ad !important;
      }
    }
    .css-t3ipsp-control {
      height: 48px;
      border: 0.1rem solid #0e56ad !important;
    }
    .css-t3ipsp-control:hover {
      height: 48px;
      border: 0.1rem solid #0e56ad !important;
    }
    .css-13cymwt-control {
      height: 48px;
      border: 0.1rem solid #b9bed0;
    }
    .raect_dropdown.mentor_option_details.css-b62m3t-container {
      span.css-1u9des2-indicatorSeparator {
        display: none !important;
      }
      .css-1dimb5e-singleValue {
        color: black;
        font-family: 'Montserrat_ragular';
        font-weight: 400;
        font-size: 1.6rem;
      }
    }
  }
  .add_mentor_header {
    padding: 1.2rem 1rem 1.2rem 28rem;
    gap: 4.8rem;
    position: sticky;
    top: 0;
    margin-top: 0.1rem;
    @extend .position_fixed;
    background: $white-color;
    top: 8.5rem;
    z-index: 1;
    right: 0;
    left: 0;
    .upload_img {
      max-width: 16.7rem;
      padding: 1rem 2rem;
      .btn_loader {
        position: initial;
        transform: none;
        img {
          max-width: 2.2rem;
          margin: 0;
        }
      }
    }
  }
  h2 {
    @extend .montserrat_semibold;
    line-height: 110%;
    font-size: 2.4rem;
  }
  .mentor_info {
    padding-bottom: 2.4rem;
    &.Education_leval {
      padding: 0;
      .education_level_select {
        .form_group {
          @include position_relative;
          .err_field {
          }
        }
      }
      .filter_dropdown {
        .form_group {
          @include position_relative;
          .err_field {
            + .err_field {
              @include position_absolute;
              top: 6.5rem;
              right: 1.3rem;
              background: $white-color;
              padding: 0.3rem;
            }
          }
        }
      }
      .filter_dropdown {
        .form_group {
          label {
            @extend .fs_14;
            color: $form-lable-color;
          }
        }
      }
      &.assign_school_sec {
        .top_title {
          h4 {
            @extend .fs_18;
            @include fw_700;
            color: $dark-bg;
            margin-bottom: 2.4rem;
            margin-top: 1rem;
          }
        }
        .required_field {
          color: $red-required;
        }
      }
    }
    .form-steps {
      .form_group {
        .form_control {
          border: 0.1rem solid $lighten-dark-blue;
          color: $black-color;
          padding-right: 4rem;
          &::placeholder {
            @include fw_400;
            color: $form-input-color;
          }
        }

        select {
          background: url(../../../public/images/Dropdown_Arrow.svg) no-repeat right 0.8rem center;
          padding-right: 3rem;
          border: 0.1rem solid $lighten-dark-blue !important;
          &.react-datepicker__month-select {
            appearance: none;
            padding: 0 0 0.3rem 0.3rem;
            background-size: 1.7rem 1.7rem;
            background-position: top 0.2rem right 0.3rem;
            @extend .border_0;
            width: 9rem;
            @extend .fw_500;
            font-size: 1.3rem;
            color: $black-color;
            background: url(../../../public/images/calender_arrow.svg) no-repeat center right -0.1rem;
          }
          &.react-datepicker__year-select {
            appearance: none;
            padding: 0 2.4rem 0.3rem 0.3rem;
            background-size: 1.7rem 1.7rem;
            background-position: top 0.2rem right 0.3rem;
            @extend .border_0;
            @extend .fw_500;
            font-size: 1.3rem;
            color: $black-color;
            background: url(../../../public/images/calender_arrow.svg) no-repeat center right -0.1rem;
          }
        }
        .react-datepicker__header__dropdown {
          padding-bottom: 2rem;
        }
        table {
          margin-top: 2rem;
          tr {
            @include position_relative;
            th {
              padding-bottom: 1.6rem;
              @include fw_400;
              border-bottom: 0.1rem solid $form-input-border;
              font-size: 1.2rem;
              color: $tab-text;
            }
            td {
              padding: 1.6rem;
              border-bottom: 0.1rem solid $form-input-border;
              img {
                vertical-align: middle;
              }
              .flag_name {
                @include d_flex_center;
                @extend .justify_content_start;
                gap: 1.2rem;
                img {
                  width: 2.4rem;
                }
                .flag_title {
                  color: $black-color;
                  @include fs_16;
                  @include fw_700;
                  line-height: 150%;
                }
              }
            }
          }
        }
        .show_error_msg {
          padding-left: 0;
        }
        .react-datepicker-wrapper,
        .react-datepicker__tab-loop {
          + .err_field {
            .error_icon {
              padding: 0.5rem;
              top: 3.9rem;
            }
          }
        }
        .radio-box {
          .radio-info {
            .add_plus_icon {
              top: 11px;
              right: 5.6rem;
            }
            [type='radio']:checked {
              + label {
                &::after {
                  width: 1.2rem;
                  height: 1.2rem;
                  left: 1.6rem;
                  transform: translateY(-51%);
                }
              }
            }
            [type='radio']:not(:checked) {
              + label {
                padding: 1rem 1.5rem 1rem 4.4rem;
                &::before {
                  left: 1rem;
                }
              }
            }
          }
        }
      }
      &.demographic {
        padding-top: 1.5rem;
        .options_status {
          top: 8rem;
        }
      }
    }
    > .top_title {
      + .custom-checkbox {
        @include fs_16;
        @include fw_700;
        color: $tab-text;
        @include d_flex;
        @extend .align_items_center;
        > .checkmark {
          margin: -0.3rem 1rem 0 0;
        }
        .checked {
          + span {
            &::before {
              width: 2.4rem;
              height: 2.4rem;
            }
            &::after {
              top: -0.3rem;
              left: -1.1rem;
              width: 0.7rem;
              height: 1.5rem;
            }
          }
        }
      }
    }
    .form_group {
      .react-datepicker__tab-loop {
        .react-datepicker-popper {
          .react-datepicker {
            min-height: 29.5rem;
            padding: 1.6rem;
            .react-datepicker__month-container {
              .react-datepicker__day {
                width: 2.5rem;
                padding: 0.3rem 0;
                border: 0.1rem solid transparent;
                &:hover {
                  @extend .radius_round;
                  background-color: transparent;
                  border: 0.1rem solid $primary-color;
                  width: 2.5rem;
                  padding: 0.3rem 0;
                  color: $tab-head;
                }
                &.react-datepicker__day--today {
                  background-color: $primary-color;
                  @extend .radius_round;
                  color: $white-color;
                }
              }
            }
          }
        }
        .react-datepicker__day-names {
          margin: 0;
          @include d_flex_center;
          gap: 1rem;
        }
        .react-datepicker__week {
          padding-bottom: 0.7rem;
          @include d_flex_between;
          gap: 1rem;
          .react-datepicker__day--keyboard-selected {
            background-color: $primary-color;
            width: 2.5rem;
            @extend .radius_round;
            color: $white-color;
            padding: 0.4rem 0;
          }
          .react-datepicker__day--selected {
            background-color: $dark-blue;
            width: 2.5rem;
            @extend .radius_round;
            color: $white-color;
            padding: 0.4rem 0;
          }
        }
      }
    }
    .react-datepicker__header {
      background-color: $white-color;
      @extend .border_0;
    }
  }
  .mentor_tab {
    ol {
      gap: 4rem;
      li {
        gap: 0.8rem;
        @include position_relative;
        color: $dark-blue;
        @extend .fw_500;
        font-size: 1.8rem;
        cursor: pointer;
        @include position_relative;
        &.active {
          font-size: 1.8rem;
          @include fw_700;
          color: $dark-blue;
          &::after {
            content: '';
            background-color: $primary-color;
            @include w_100;
            height: 0.4rem;
            @include position_absolute;
            bottom: -2.3rem;
            left: 0;
            right: 0;
            border-radius: 1rem;
          }
          .blue_icon {
            @include d_block;
          }
          .gray_icon {
            @include d_none;
          }
        }
        &::before {
          content: '';
          @include position_absolute;
          background: url(../../../public/images/mentor_tab_arrow.svg) no-repeat;
          background-size: cover;
          background-position: center;
          width: 2.4rem;
          height: 2.4rem;
          top: 50%;
          transform: translateY(-50%);
          left: -3.5rem;
        }
        span {
          color: #c00c2d;
        }
        .gray_icon {
          @include d_block;
        }
        &.completed {
          .success {
            @include d_block;
          }
          .gray_icon {
            @include d_none;
          }
        }
      }
    }
  }
  .add_mentor_footer {
    border-top: 0.1rem solid $border-color;
    background: $white-color;
    box-shadow: -0.8rem -0.8rem 2.5rem 0rem rgba(30, 45, 102, 0.03);
    padding: 1.6rem 3.2rem;
    &.footer_sticky {
      @extend .position_fixed;
      bottom: 0;
      right: 0;
      left: 0;
      padding-left: 28rem;
      z-index: 9;
      @include media_query(two_extra_large) {
        padding-inline: 1.5rem;
      }
    }
    ul {
      @include position_relative;
      li {
        @include media_query(Large) {
          width: 50%;
        }
        &:nth-child(2) {
          @include w_100;
          gap: 0.8rem;
          @include media_query(Large) {
            display: block;
          }
          button {
            &:first-child {
              @include media_query(Large) {
                margin-bottom: 1rem;
              }
            }
          }
        }
        .upload_img {
          max-width: 20.9rem;
          &.continue_btn {
            @include fw_700;
            color: $white-color;
            gap: 1.2rem;
            padding: 1rem 0;
            @extend .justify_content_center;
            img {
              margin-right: 0;
            }
          }
          &.complete_info {
            @extend .justify_content_center;
            color: $white-color;
            min-width: 19.2rem;
            img {
              filter: brightness(31);
            }
          }
        }
        .cancel {
          @include fw_700;
          font-size: 1.6rem;
          color: $primary-color;
          @include position_absolute;
          top: 50%;
          transform: translateY(-50%);
          &.need_contect_help {
            color: $light-gray;
            font-size: 13.5104px;
            @extend .fw_500;
            .contect_need {
              color: $primary-color;
              @include fw_700;
              padding-left: 0.5rem;
              @extend .cursor_pointer;
            }
          }
        }
      }
    }
    .footer_area_section {
      @include w_100;
      .tab_dotted {
        @include d_flex;
        @extend .justify_content_center;
        gap: 1.6rem;
        margin-bottom: 1.6rem;
        li {
          width: auto;
          button {
            width: 0.8rem;
            height: 0.8rem;
            @include position_relative;
            &::before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background-color: #e0e8f6;
              border-radius: 5rem;
              top: 0;
              left: 0;
            }
          }
          &.active {
            button {
              &::before {
                background-color: $primary-color;
                border-radius: 5rem;
              }
            }
          }
          &.completed {
            button {
              &::before {
                background-color: $dark-blue;
                border-radius: 5rem;
              }
            }
          }
          &.completed {
            button {
              &::before {
                background-color: $dark-blue;
                border-radius: 5rem;
              }
            }
          }
        }
      }
    }
  }
  .additional_info {
    .mentor_info {
      .form-steps {
        .form_group {
          .form_control {
            border: 0.1rem solid $separtor-color;
            &.blue_border {
              border: 0.1rem solid $lighten-dark-blue;
            }
            &.focus_border:focus {
              border: 0.1rem solid $lighten-dark-blue;
            }
            .demographi_list {
              font-size: 13px;
            }
          }
          .phone_number.focus_border {
            border: 0.1rem solid $lighten-dark-blue !important;
          }
        }
      }
    }
  }
  NaNpxove_toolkit {
    @include trans;
    &:hover {
      @include d_flex;
      NaNpxove_mentees {
        @include trans;
        opacity: 1;
      }
    }
    NaNpxove_mentees {
      background: $dark-bg;
      @include position_absolute;
      color: $white-color;
      right: -4rem;
      line-height: 140%;
      padding: 0.8rem 1rem;
      top: -2.8rem;
      border-radius: 0.8rem;
      @include trans;
      opacity: 0;
    }
  }
  &.edit_mentors_user {
    .add_mentor_footer {
      @extend .position_fixed;
      bottom: 0;
      right: 0;
      left: 0;
      padding-left: 28rem;
      z-index: 9;
      background: $white-color;
      .upload_img {
        max-width: unset;
        width: auto;
        .right_icon {
          filter: brightness(21);
        }
      }
    }
    .tab_dotted {
      margin-left: 2.1rem;
    }
  }
}
.form_small_container {
  max-width: 69.4rem;
  @include w_100;
  padding: 0 1.5rem;
  margin: auto;
  .form-wrapper {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    .top_title {
      h4 {
        font-size: 3.2rem;
        @extend .montserrat_semibold;
        font-weight: 600;
        line-height: 110%;
        padding-bottom: 0.8rem;
      }
      p {
        color: $tab-text;
        @include fw_400;
        @extend .fs_14;
        line-height: 140%;
      }
    }
    form {
      max-width: unset;
      .form-steps {
        margin-bottom: 2.4rem;
        &:last-child {
          margin-bottom: 0;
        }

        .form-box {
          .form_group {
            width: calc(50% - 0.9rem);
            @include position_relative;
            .required_field {
              color: $red-required;
            }
            input[type='date']::-webkit-calendar-picker-indicator {
              background-image: url(../../../public/images/Calendar.svg);
              width: 2.4rem;
              height: 2.4rem;
            }
            .main_picture_upload {
              border-radius: 0.8rem;
              background: rgba(231, 241, 253, 0.5);
            }
            &.city {
              max-width: 25.8rem;
            }
            &.state {
              max-width: 10rem;
              .mentor_option_details {
                .css-1nmdiq5-menu {
                  .css-jiu3od-MenuList {
                    max-height: 11.5rem;
                  }
                }
              }
            }
            &.zip {
              max-width: 25.8rem;
            }
            .phone_number {
              border: 0.1rem solid $lighten-dark-blue;
              color: $black-color;
              .flag-container {
                .selected-flag {
                  z-index: -1;
                  @include position_relative;
                }
              }
              + .err_field {
                .error_icon {
                  right: 1.75rem;
                  top: 4.6rem;
                }
              }
            }
            &.w_100 {
              @include w_100;
              max-width: 100%;
              &.bottom_text {
                margin-bottom: 0;
              }
            }
            table {
              tr {
                td {
                  .radio-box {
                    @extend .justify_content_center;
                    .radio-info {
                      padding: 0;
                      width: 5.5rem;
                      @include d_flex_center;
                      [type='radio']:checked + label,
                      [type='radio']:not(:checked) + label {
                        border: 0;
                      }
                      [type='radio']:checked + label:before,
                      [type='radio']:not(:checked) + label:before {
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                      }
                      [type='radio']:checked + label:after,
                      [type='radio']:not(:checked) + label:after {
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                      }
                      [type='radio']:checked + label {
                        width: auto;
                      }
                    }
                  }
                }
              }
            }
            .error_icon {
              @include position_absolute;
              right: 1.5rem;
              top: 3.5rem;
              background-color: $white-color;
              &:hover {
                + .remove_tooltip {
                  @include d_block;
                  .tooltip {
                    @include d_block;
                  }
                }
              }
            }
            .remove_tooltip {
              @include position_absolute;
              top: -1.5rem;
              right: 0;
              .tooltip {
                position: initial;
                top: -1.5rem;
                right: 0;
              }
            }
            label {
              color: $form-lable-color;
            }
          }
          &.d_flex_center {
            gap: 2.4rem;
          }
        }
        .form_group {
          .profile_label {
            color: $tab-text;
            margin: 2.4rem 0;
          }
          .main_picture_upload {
            border-radius: 0.8rem;
            background: rgba(231, 241, 253, 0.5);
            padding: 1.6rem 2.4rem;
            @include position_relative;
            .profile_img {
              border: 0.2rem solid#B6D4F8;
              background: #dceafb;
              border-radius: 50%;
              width: 8.8rem;
              min-width: 8.8rem;
              height: 8.8rem;
              @include over_hidden;
              padding: 0;
              img {
                @extend .img_object_center;
                @include w_100;
                height: 100%;
                border-radius: 50%;
              }
              &.upload_event_thumbnail {
                img {
                  object-fit: cover;
                  object-position: center;
                }
              }
            }
            .dragBox {
              max-width: 27.5rem;
              @include w_100;
              @include position_relative;
              color: $form-input-color;
              line-height: 140%;
              .drag_img {
                @extend .montserrat_bold;
                font-weight: 700;
                line-height: 140%;
              }
              input[type='file'] {
                @include position_absolute;
                height: 100%;
                @include w_100;
                opacity: 0;
                top: 0;
                left: 0;
              }
            }
            .upload_photo {
              @include button;
              font-size: 1.4rem;
              line-height: 140%;
              max-width: 16.2rem;
              color: $form-lable-color;
              @include w_100;
              border: 0.2rem solid $primary-color;
              margin: 0;
              gap: 1rem;
              padding: 1rem 2.4rem;
              background-color: $white-color;
              img {
                margin-left: 0;
                margin-right: 0;
                &.plus_upload {
                  margin-left: 0;
                }
              }
              &:hover {
                background-color: $primary-color;
                color: $white-color;
              }
              .plus_upload {
                @include d_block;
              }
              .edit_upload {
                @include d_none;
              }
              &.upload_pic {
                .plus_upload {
                  @include d_block;
                }
                .edit_upload {
                  @include d_none;
                }
              }
              &.edit_photo {
                .plus_upload {
                  @include d_none;
                }
                .edit_upload {
                  @include d_block;
                }
              }
              &.upload_pic {
                max-width: 18.7rem;
              }
            }
            .delete_img_btn {
              img {
                height: 1.6rem;
              }
            }
            .danger_btn {
              border-radius: 0.8rem;
              border: 0.1rem solid #d64444;
              background: rgba(214, 68, 68, 0.1);
              color: #d64444;
              &:hover {
                color: $white-color;
                @include trans;
              }
            }
            .dot_menu_modal {
              right: 2.4rem;
              top: calc(100% - 24px);
              z-index: 9;
              .list_dot_menu {
                @extend .montserrat_medium;
              }
            }
          }
        }
        &.home_add {
          margin-bottom: 0;
          margin-top: 1.5rem;
        }
        &.contact_info {
          margin-top: 1.5rem;
          &.contact_info {
            margin-bottom: 0;
          }
        }
        .preload_mentees {
          border-radius: 0.8rem;
          background: rgba(231, 241, 253, 0.5);
          padding: 1.6rem;
          margin-top: 1.5rem;
          .title {
            h5 {
              @extend .montserrat_bold;
              font-size: 1.6rem;
              line-height: 140%;
              margin-left: 3rem;
              margin-left: 0;
            }
          }
          .user_pic {
            width: 4rem;
            height: 4rem;
            @extend .d_inline_block;
            border-radius: 5rem;
            @include over_hidden;
            img {
              @include w_100;
            }
          }
          .mentees_name {
            padding-left: 0.8rem;
            padding-top: 3rem;
            border-bottom: 0.1rem solid #dceafb;
            li {
              padding-bottom: 2.4rem;
              gap: 1.6rem;
              span {
                @extend .fw_500;
                font-size: 1.6rem;
                text-decoration-line: underline;
                color: $dark-blue;
              }
              .main_mentees_list {
                gap: 1.6rem;
                padding: 0.4rem 1rem;
                @include position_relative;
                @extend .cursor_pointer;
                &:hover {
                  border-radius: 0.4rem;
                  background: #dceafb;
                  @include trans;
                  NaNpxove {
                    @include d_flex_center;
                    opacity: 1;
                  }
                }
                NaNpxove {
                  opacity: 0;
                  @include trans;
                }
              }
            }
          }
          .preload_btn {
            gap: 2.4rem;
            margin-top: 2.4rem;
            .upload_img {
              max-width: 15.5rem;
              padding: 0.8rem 1.2rem;
            }
            .upload_csv {
              @include fw_700;
              color: $primary-color;
              font-size: 1.4rem;
              gap: 1rem;
            }
            .upload_csv_hover {
              @include position_relative;
              &:hover {
                .hover_csv {
                  @include d_block;
                  opacity: 1;
                  @include trans;
                }
              }
              .hover_csv {
                background-color: $dark-bg;
                padding: 1.7rem 1.8rem;
                @include position_absolute;
                top: -9rem;
                left: -1.6rem;
                width: 17.8rem;
                p {
                  @include fw_400;
                  color: $white-color;
                  margin-bottom: 0.8rem;
                }
                span {
                  @include w_100;
                  color: #3489ef;
                  @extend .text_center;
                  @include fw_700;
                  img {
                    vertical-align: middle;
                  }
                }
              }
            }
          }
          &.references {
            background: 0;
            padding-left: 0;
            padding-right: 0;
            .title {
              border-bottom: 0.1rem solid $form-input-border;
              padding-bottom: 1.5rem;
              h5 {
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
    .mentor_info {
      .form-steps {
        .form-box {
          @extend .align_items_baseline;
        }
        .form_group {
          .radio-box {
            gap: 0;
            @include position_relative;
            &.add_mentees_radio {
              padding-bottom: 0;
            }
            .radio-info {
              width: 13rem;
              padding-left: 0;
              @extend .justify_content_start;
              @include d_flex;
              @extend .justify_content_center;
              border: 0;
              padding-top: 1rem;
              padding-bottom: 0;
              @extend .align_items_center;
            }
          }

          textarea {
            @include w_100;
            resize: none;
          }
        }
      }
      &.event_edit_info {
        padding-bottom: 10rem;
      }
    }
  }
  .form-wrapper {
    .additional_info {
      form {
        .mentor_info {
          .form-steps {
            .form-box {
              .form_group {
                .phone_number {
                  border: 0.1rem solid $separtor-color;
                  .form_control {
                    border: 0;
                  }
                }
              }
            }
          }
        }
        .add_new_ref_btn {
          .upload_img {
            border: 0;
            max-width: 23.5rem;
            @extend .justify_content_end;
            padding-right: 0;
            gap: 1rem;
            @include fw_700;
            font-size: 1.6rem;
            line-height: 150%;
            color: $primary-color;
            padding-bottom: 3.4rem;
            padding-top: 0;
            margin: 0 0 0 auto;
          }
        }
      }
    }
  }
  &.matching_questions_main {
    max-width: 74.2rem;
    .radio-info {
      .filter_dropdown {
        padding-top: 0;
        margin-top: -0.7rem;
      }
    }
    .edit_anyway_sec {
      border-radius: 0.8rem;
      background: rgba(199, 152, 55, 0.1);
      padding: 2.4rem;
      margin-bottom: 4.3rem;
      h5 {
        @extend .fw_600;
        color: $black-color;
        @extend .text_center;
        line-height: 140%;
        gap: 0.4rem;
        margin-bottom: 1.2rem;
      }
      p {
        color: $black-color;
        @include fw_400;
        @extend .text_center;
        font-size: 1.4rem;
        margin-bottom: 2.4rem;
      }
      button {
        margin: 0 auto;
      }
    }
    .form-steps {
      .form-box {
        .form_group {
          li {
            &.disabled {
              .custom-checkbox {
                color: #bdbdbd;
              }
            }
            .custom-checkbox {
              @extend .d_inline_flex;
              @extend .align_items_center;
              @include fw_400;
              @extend .cursor_pointer;
              margin-bottom: 1.3rem;
              &.active {
                @include fw_700;
              }
              label {
                cursor: pointer;
                margin-bottom: 0;
                min-width: 70rem;
                max-width: 65rem;
              }
              .checked {
                & + span {
                  margin-right: 1rem;
                  vertical-align: middle;
                }
                & + span::before {
                  width: 2.4rem;
                  height: 2.4rem;
                }
                & + span::after {
                  width: 0.9rem;
                  height: 2rem;
                }
              }
            }
          }
          textarea {
            border: 0.1rem solid $separtor-color;
          }
        }
      }
    }
    .career_exp {
      .top_title {
        margin-bottom: 1.6rem;
      }
      .form-steps {
        .form-box {
          .form_group {
            .form_control {
              border: 0.1rem solid $separtor-color;
            }
          }
        }
      }
    }

    .education_info {
      .top_title {
        margin-bottom: 1.6rem;
      }
      .form-steps {
        .form-box {
          .form_group {
            p {
              margin-bottom: 1rem;
            }
            .form_control {
              border: 0.1rem solid $separtor-color;
            }
            textarea {
              border-radius: 0.5rem;
              padding: 1.4rem;
              @include fw_400;
              color: $form-input-color;
              &::placeholder {
                @include fw_400;
                color: $form-input-color;
              }
            }
          }
        }
      }
    }
    .matching_questions {
      .mentor_info {
        .form-steps {
          .form-box {
            .form_group {
              .checkbox {
                ul {
                  li {
                    min-width: 79rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.form_group {
  li {
    &.disabled {
      .custom-checkbox {
        .checked {
          & + span::before {
            background-color: $border-color;
            border: 0.2rem solid #bdbdbd;
          }
        }
      }
    }
  }
}

.mentore_decide {
  .decide_btn {
    img {
      vertical-align: sub;
      @extend .d_inline_block;
    }
  }
}

.mentors_page {
  .table_wrapper {
    margin-top: 4rem;
    .table_responsive {
      .table {
        &.mentors {
          min-width: 138rem;
          tr {
            &.dot_add_note {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: unset;
                }
              }
            }
            &.dot_add_note_last {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: 0;
                  @include position_absolute;
                }
              }
              &:first-child,
              &:nth-child(2) {
                .dot_icon_modal {
                  .dot_menu_modal {
                    @extend .position_fixed;
                  }
                }
              }
            }
            &.first_one {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: unset;
                }
              }
              &:first-child {
                .dot_icon_modal {
                  .dot_menu_modal {
                    bottom: auto;
                    @include position_absolute;
                  }
                }
              }
            }
            &.last_three {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: unset;
                }
              }
              &.dot_add_note_last {
                .dot_icon_modal {
                  .dot_menu_modal {
                    bottom: 0 !important;
                  }
                }
              }
            }
            &.last_three.first_one.dot_add_note_last {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: auto !important;
                  @extend .position_fixed;
                }
              }
            }
            &.last_three.first_one {
              .dot_icon_modal {
                .dot_menu_modal {
                  @extend .position_fixed;
                }
              }
            }
            &:nth-child(7) {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: 0;
                }
              }
            }
            &:nth-child(8) {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: 0;
                }
              }
            }
          }
          td {
            &.checkbox_hidden {
              @include d_none;
            }
            &:nth-child(2) {
              width: 19%;
            }
            &:nth-child(3) {
              width: 17%;
            }
            &:nth-child(4) {
              width: 23%;
            }
            &:nth-child(5) {
              width: 20%;
            }
            &:nth-child(6) {
              width: 20%;
            }
            &:nth-child(7) {
              width: 2%;
            }
            &:nth-child(8) {
              width: 20%;
            }
            &:nth-child(9) {
              width: 5%;
            }
            &:nth-child(9) {
              width: 5%;
            }
            &:nth-child(9) {
              width: 5%;
            }
          }
          &.mentors_tablewrap {
            min-width: 120rem;
            tr {
              &.last_three {
                .dot_icon_modal {
                  @include d_block;
                  .dot_menu_modal {
                    bottom: 0;
                  }
                }
                &:nth-last-child(-n + 2) .dot_icon_modal {
                  @include d_block;
                  .dot_menu_modal {
                    bottom: auto;
                  }
                }
              }
              td {
                &:nth-child(2) {
                  width: 12%;
                }
                &:nth-child(4) {
                  width: 11%;
                }
                &:nth-child(5) {
                  width: 15%;
                }
                &:nth-child(6) {
                  width: 9%;
                }
                &:nth-child(7) {
                  width: 6%;
                }
              }
            }
          }
        }
      }
    }
  }
  .i_popup_content {
    .imentor_info {
      border-bottom: 0;
    }
  }
  + .modal_comman {
    .csv_modal {
      .upload_box {
        @include position_relative;
        .upload {
          .csv_up {
            @include w_100;
            height: 100%;
            left: 0;
            top: 0;
            transform: none;
            opacity: 0;
            @extend .position_fixed;
            margin: auto;
            @extend .text_center;
            max-width: 56rem;
            margin: 0;
          }
        }
      }
    }
  }
  + .modal_comman {
    .skip_modal {
      &.active {
        .skip_user_modal {
          .table_responsive {
            .skip_modal_listing {
              tr {
                td {
                  &:first-child {
                    width: 10%;
                  }
                  &:nth-child(2) {
                    width: 10%;
                  }
                  &:nth-child(3) {
                    width: 15%;
                  }
                  &:nth-child(4) {
                    width: 13%;
                  }
                  &:nth-child(5) {
                    width: 14%;
                  }
                  &:nth-child(6) {
                    width: 15%;
                  }
                  .skip_data {
                    max-width: 15.5rem;
                    @include over_hidden;
                    @extend .text_ellipsis;
                    @extend .white_space_nowrap;
                  }
                  .heading {
                    @extend .white_space_nowrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ul {
    &.user_info {
      @include position_relative;
      li {
        &:hover {
          .tooltip {
            @include d_block;
            left: 0;
            top: -4rem;
          }
        }
      }
    }
  }
}

.comman_head {
  gap: 1rem;
  ul.react-tabs__tab-list li.react-tabs__tab {
    min-width: 20.2rem;
  }
}

.form_group {
  .App {
    .css-wsp0cs-MultiValueGeneric {
      color: $black-color;
      @extend .fs_12;
      @include fw_400;
      line-height: 140%;
    }
    .css-13cymwt-control,
    .css-t3ipsp-control {
      min-height: 4.8rem;
      background: url(../../../public/images/filter_drop_down_arrow.svg) no-repeat center right 1.6rem $white-color;
    }
    .css-1hb7zxy-IndicatorsContainer {
      @include d_none;
    }
    .css-3w2yfm-ValueContainer {
      gap: 1rem;
      padding: 0.75rem;
    }
    .css-1wy0on6 {
      @include d_none;
    }
    .css-1dyz3mf {
      gap: 1rem;
      padding: 0.75rem;
    }
  }
}

.disable_mentor {
  .radio-info [type='radio']:checked + label {
    border: 0.2rem solid $light-gray;
  }
  .radio-info [type='radio']:checked + label:before {
    border: 0.2rem solid $light-gray;
  }
  .radio-info [type='radio']:checked + label:after {
    background: $light-gray;
  }
  &.edit_disable_mentor {
    .radio-info [type='radio']:checked + label {
      border: 0.2rem solid $primary-color;
    }
  }
}
.radio-box.edit_disable_mentor .radio-info [type='radio']:checked + label:before {
  border-color: $primary-color !important;
}
.radio-box.edit_disable_mentor .radio-info [type='radio']:checked + label::after {
  background: $primary-color !important;
}
.edit_disabled .custom-checkbox .checked + label::before {
  background: $border-color !important;
  border: 0.2rem solid $light-gray !important;
}

.form_group {
  &.error_msg {
    .error_icon {
      @include position_absolute;
      right: 1.5rem;
      top: 4.4rem;
      background-color: $white-color;

      &:hover {
        + .remove_tooltip {
          @include d_block;
          .tooltip {
            @include d_block;
          }
        }
      }
    }
    .remove_tooltip {
      @include position_absolute;
      top: -1.5rem;
      right: 0;
      .tooltip {
        position: initial;
        top: -1.5rem;
        right: 0;
      }
    }
  }
}
