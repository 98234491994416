.surveys_wrapper {
  flex-basis: 100%;
  align-items: end;

  .survey_item {
    background-color: #b6d4f86e;
    padding: 13px;
    border-radius: 3px;
    border: solid #e1e1e1 1px;
    cursor: pointer;
    margin-bottom: 20px;
  }
}

.question_item {
  margin-bottom: 2em;
  font-size: 1.3em;
}

.pairs_page {
  .table_wrapper {
    .d_flex_between {
      &.mb_10 {
        margin-bottom: 0;
      }
    }
  }

  .pair_stats_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 20px;
  }

  .pairs_activities {
    @include w_100;
    max-width: 94rem;
    @extend .m_auto;
    @extend .radius_8;
    border: 0.2rem solid #dceafb;
    padding: 3.2rem 0;
    margin: 7.3rem auto;
    @include position_relative;

    .inner_pairs_activity {
      padding: 0 2.9rem;

      .pagination {
        padding: 0;
      }
    }

    h2 {
      color: $black-color;
      @extend .fs_32;
      @extend .fw_600;
      @extend .text_center;
      @extend .mb_40;
      line-height: 110%;
    }

    .pair_tabs {
      margin-bottom: 3.2rem;

      ul.active_tabs {
        @include d_flex_center;
        gap: 0.8rem;

        li {
          @include d_flex_center;
          @extend .fs_18;
          @extend .fw_500;
          line-height: 140%;
          border-radius: 8px 8px 2px 2px;
          color: $tab-text;
          background: transparent;
          padding: 0.8rem 1.5rem;
          min-height: 4.7rem;
          @include w_100;
          min-width: 21.2rem;
          @extend .white_space_nowrap;
          @extend .text_center;
          @extend .cursor_pointer;
          border-bottom: 0.4rem solid transparent;

          &.active {
            @include fw_700;
            color: $dark-blue;
            background: $form-input-active-bg;
            border-bottom: 0.4rem solid $primary-color;
          }
        }
      }
    }

    .serch_media_file {
      @include d_flex_center;
      @extend .justify_content_between;
      margin-bottom: 3.2rem;
      .form_group {
        @include w_100;
        max-width: 66.5rem;
        @include position_relative;
        margin: 0;

        .form_control {
          @include fs_16;
          @include fw_400;
          line-height: 2.2rem;
          color: $title-color;
          border: 0.2rem solid $border-color;
          padding: 0.8rem;
          padding-left: 5rem;
          padding-right: 4rem;
          border-radius: 10rem;
          min-height: 4rem;

          &:focus {
            border: 2px solid $blue-color;
            box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
          }
        }

        .serch_icon {
          @include d_flex_center;
          width: 2.4rem;
          height: 2.4rem;
          @include position_absolute;
          left: 1.6rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .remove_search_history {
        @include position_absolute;
        @include d_flex;
        width: auto;
        height: auto;
        right: 1.6rem;
        top: 50%;
        transform: translateY(-50%);
      }

      .remove_search_history_text {
        @include position_absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 4rem;
        color: $dark-blue;
      }

      .events_select {
        label {
          @include fw_700;
          font-size: 1.2rem;
          margin-bottom: 0.4rem;
          @include d_block;
        }

        .select_form_control {
          @include w_100;
          border-radius: 0.4rem;
          border: 0.1rem solid $separtor-color;
          background: $white-color;
          width: 24rem;
          min-height: 4rem;
          background: url(../../../public/images/Dropdown_Arrow.svg) center right 1rem no-repeat;
          appearance: none;
          padding: 0 1.6rem;
        }
      }

      &.serch_event {
        align-items: end;

        .search_event_box {
          max-width: 60.4rem;
        }

        .event_dropdown {
          min-width: 24rem;
          padding: 0 1.6rem;
          background: url(../../../public/images/filter_drop_down_arrow.svg) center right 1.5rem no-repeat;
          appearance: none;
          border-radius: 0.4rem;
        }
      }
    }

    .chat_pairs {
      @extend .radius_8;
      border: 0.1rem solid #dceafb;
      background: rgba(231, 241, 253, 0.35);
      padding: 2.4rem;
      max-height: 73.4rem;
      overflow-y: auto;
      @include d_flex;
      @extend .flex_direction_column_reverse;
      position: static;

      .msg_time {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
      }

      .chat_history_day {
        @include d_flex_center;
        color: $form-input-color;
        @extend .fs_12;
        @include fw_700;
        line-height: 140%;
        min-height: 4.2rem;
      }

      .recive_msg {
        @include d_flex;
        @extend .justify_content_start;
        gap: 0.8rem;

        .chat_msg_time {
          margin: 0 0 0.4rem;
          display: block;
        }

        .pic_of_recived {
          @include d_flex;
          @extend .justify_content_center;
          @include w_100;
          max-width: 4.2rem;
          height: 4.2rem;
          @extend .radius_round;
          @include position_relative;
          margin-bottom: 0;
          @extend .align_items_end;

          &:hover {
            .tooltip {
              display: block !important;
              top: -4rem;
              left: -3rem;
            }
          }

          img {
            width: 4.2rem;
            height: 4.2rem;
            @extend .img_object_center;
            @extend .radius_round;
            @extend .radius_round;
            box-shadow: 2.3333334922790527px 2.3333334922790527px 11.666666984558105px 0px rgba(134, 172, 218, 0.44);
            border: 0.1rem solid $white-color;
            width: 4.2rem;
            height: 4.2rem;
            @extend .radius_round;
            box-shadow: 2.3333334922790527px 2.3333334922790527px 11.666666984558105px 0px rgba(134, 172, 218, 0.44);
          }

          .tooltip {
            @include tooltip();
          }

          .user_pic {
            padding: 1.5rem;
            width: 4.2rem;
            height: 4.2rem;
            font-size: 1.5rem;
          }
        }

        .msg_of_recived {
          @include position_relative;
          width: 100%;
          label {
            @include d_block;
            color: $black-color;
            @extend .fs_14;
            @include fw_700;
            line-height: 140%;
            margin-bottom: 0.4rem;
          }

          .img_view_show {
            @extend .flex_direction_column;
            img {
              width: 30rem;
              height: 20rem;
              @extend .img_object_center;
            }
            .emoji_rection_main {
              margin: -1.5rem 0 0 1.5rem;
            }
          }

          .msg {
            color: $form-lable-color;
            @include fs_16;
            @extend .fw_500;
            line-height: 140%;
            padding: 1.6rem;
            border-radius: 0.8rem 0.8rem 0.8rem 0;
            border: 0.1rem solid $grey-100;
            background: $white-color;
            word-break: break-word;

            span {
              word-break: break-word;
            }

            .link_btn {
              margin-left: 5px;
              color: $primary-color;
            }

            a:not(.link_btn) {
              color: $primary-color;
              font-style: normal;
              text-decoration: underline;
            }

            .link_btn {
              margin-left: 5px;
            }
          }

          .message_box {
            padding: 0;
          }
        }

        &.send_msg {
          @extend .justify_content_end;

          .msg_of_recived {
            @extend .text_right;
            @include d_flex;
            @extend .flex_direction_column;
            @extend .align_items_end;
            .msg {
              color: $white-color;
              border-radius: 0.8rem 0rem 0.8rem 0.8rem;
              background: #1168d0;
              @extend .text_left;
              word-break: break-all;

              span {
                a {
                  color: currentColor;
                }
              }
            }

            .msg_time {
              color: $black-color;
              @extend .fs_12;
              @include fw_400;
              line-height: 140%;
              margin-top: 2.8rem;
              margin-bottom: 0.8rem;
              margin-top: 0.8rem;
            }
            .link_btn {
              color: $white-color;
            }
          }
        }
        &.file_class,
        .file_class {
          border: 0.1rem solid #cccccc8d;
          border-radius: 1rem;
          padding: 1rem;
          background: $white-color;
          width: max-content;
          .pdf_send {
            @extend .cursor_pointer;
            @include d_flex;
            @extend .align_items_center;
            margin-right: 0;
            gap: 1rem;

            .pdf_file {
              width: 3.2rem;
              height: 3.2rem;

              img {
                width: 3.2rem;
                height: 3.2rem;
              }
            }

            .pdf_title {
              .pdf_name {
                color: $black-color;
                @include fs_16;
                @include fw_700;
                line-height: 140%;
              }

              .pdf_size {
                color: $black-color;
                @extend .text_right;
                @extend .fs_12;
                @include fw_400;
                line-height: 140%;
                margin-right: 2.5rem;
              }
            }
          }
        }
        &.send_msg {
          .quote_msg_wrap {
            @include d_flex;
            @extend .flex_direction_column;
            @extend .align_items_end;
            width: 100%;
            .quote_msg_content {
              background: $secondary-color;
              border-top-right-radius: 0px;
              box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.1490196078);
              text-align: left;
              border-radius: 0.8rem;
              border-top-right-radius: 0px;
              p {
                color: $white-color;
              }
              .quote_msg_main {
                background-color: $quote_bg;
                .quote_text_msg {
                  p {
                    color: $white-color;
                  }
                }
                .replay_file_msg {
                  img {
                    width: 3.2rem;
                    height: 3.2rem;
                  }
                  span {
                    color: $white-color;
                    @extend .fw_500;
                  }
                }
                .replay_content_msg {
                  .content_wrapper_video {
                    .content_video_inner {
                      .content_dec {
                        h4,
                        span {
                          color: $white-color;
                        }
                      }
                      .read-more-text {
                        span {
                          color: $white-color;
                        }
                      }
                    }
                  }
                }
                .quote_msg_footer {
                  h4,
                  span {
                    color: $white-color;
                  }
                }
              }
              .chat_text_msg {
                border-radius: 0px;
              }
            }
          }
        }
        .quote_msg_wrap {
          .quote_msg_content {
            border-radius: 0.8rem;
            padding: 1.2rem;
            max-width: 80%;
            width: fit-content;
            border-top-left-radius: 0px;
            box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.0784313725);
            background-color: $white-color;
            border: 1px solid $border-color;
            .quote_msg_main {
              background: $quote-white-bg;
            }
            @include position_relative;
            .quote_msg_main {
              border-radius: 0.8rem;
              padding: 1.2rem;
              margin: 0 0 0.8rem;
              .quote_text_msg {
                p {
                  font-size: 1.6rem;
                  line-height: 2.2rem;
                  letter-spacing: 0.5px;
                  @include fw_400;
                }
              }
              .quote_media_msg {
                img {
                  height: 12.5rem;
                  margin: 0 auto;
                }
              }
              .quote_msg_footer {
                margin: 1.2rem 0 0;
                h4 {
                  font-size: 1.2rem;
                  line-height: 1.6rem;
                  letter-spacing: 0.5px;
                  @include fw_700;
                }
                > span {
                  display: block;
                  margin: 0.4rem 0 0;
                  font-size: 1rem;
                  line-height: 1.3rem;
                  letter-spacing: 0.5px;
                }
              }
              .replay_content_msg {
                min-width: 395px;
                .content_wrapper_video {
                  max-width: 100%;
                  width: 100%;
                  border: 0px;
                  box-shadow: none;
                  .content_video_inner {
                    .content_dec {
                      padding: 0;
                    }
                    .read-more-text {
                      border: none;
                      width: 100%;
                      padding: 1.2rem 0 0;
                    }
                  }
                }
              }
              .replay_audio_msg {
                .voice_msg {
                  margin: 0;
                  border-radius: 0.6rem;
                  padding: 0.3rem 1rem;
                }
              }
            }
            .chat_text_msg {
              border-radius: 0px;
              border: none;
              padding: 0;
              box-shadow: none;
            }
          }
        }
      }

      .mesg_chat_loader {
        top: 28%;
        right: 0;
        left: 0;
        z-index: 9999;
      }

      .chat_loading_area_mesg {
        background-color: $primary-color;
        color: $white-color;
        border-radius: 1rem;
        max-width: 14rem;
        margin: auto;
        padding: 0.7rem 0;

        span {
          animation: spin 1s linear infinite;

          > img {
            filter: invert(21);
          }

          @keyframes spin {
            0% {
              transform: rotate(0);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
      .admin_img_send {
        .image_wrap_main {
          max-width: 21rem;
          width: 100%;
          margin: 0 auto 3rem;
          img {
            max-width: 100%;
          }
        }
        + .msg_time {
          text-align: center;
        }
      }
      .admin_pdf_send {
        margin: 0 auto 3rem;
      }
    }

    .Events_sec {
      .events_wrapper {
        h4 {
          @include fw_700;
          color: $form-input-color;
          font-size: 2rem;
        }
      }

      .card {
        margin: 0 -1.2rem;
        padding-top: 3rem;

        .card_body {
          width: 33.33%;
          padding: 0 1.2rem 2.4rem;

          .card_inner {
            border-radius: 0.8rem;
            border: 0.1rem solid $border-color;
            background: $white-color;
            box-shadow: 0.8rem 0.4rem 2.5rem 0 rgba(82, 99, 116, 0.04);
            min-width: 27.8rem;
            @include over_hidden;

            .card_content {
              padding: 1.6rem;

              img {
                @include w_100;
              }

              .card_title {
                @include fw_700;
                font-size: 1.8rem;
                line-height: 140%;
                color: $black-color;
                margin-bottom: 1.6rem;
                height: 7.5rem;
                @include over_hidden;
              }

              p {
                @include fw_700;
                color: $primary-color;
                font-size: 1.6rem;
                line-height: 150%;
                margin-bottom: 4rem;
              }

              span {
                @include fw_400;
                color: $black-color;
                font-size: 1.4rem;
                line-height: 140%;
              }

              .map_location {
                gap: 0.5rem;

                img {
                  width: auto;
                  max-width: 100%;

                  &.map_point_svg {
                    width: 2.4rem;
                    height: 2.4rem;
                  }
                }
              }

              .content_tag {
                @include fw_700;
                font-size: 1.2rem;
                color: $primary-color;
                margin-bottom: 0.8rem;
                @include d_block;
              }

              .post_time {
                font-size: 1.4rem;
                @include fw_400;
                color: $form-input-color;
                margin-bottom: 2rem;
              }

              .user_post_by {
                @extend .fw_500;
                color: $tab-text;
                @include over_hidden;
                gap: 0.8rem;

                img {
                  width: 3.2rem;
                  height: 3.2rem;
                  border-radius: 10rem;
                }
              }
            }

            img {
              max-width: 100%;
              height: 14.8rem;
              @extend .img_object_center;
              @include w_100;
            }
          }
        }
      }

      &.content_accessed_sec {
        .card {
          .card_body {
            .card_inner {
              .card_content {
                padding: 2rem;

                .card_title {
                  height: 5rem;
                  margin-bottom: 0.8rem;
                  max-width: 22rem;
                }
              }
            }
          }
        }

        .slide_btn_wrapper {
          gap: 3.2rem;
          padding-right: 2.4rem;

          .content_arrow {
            gap: 1.6rem;

            .slide_btn {
              border-radius: 10rem;
              border: 0.1rem solid $tab-text;
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .back_content {
            @include d_flex;
            gap: 1.2rem;

            img {
              transform: rotate(180deg);
            }
          }

          span {
            @include fw_700;
            font-size: 1.4rem;
            color: $primary-color;
          }
        }

        .events_wrapper {
          padding: 1.5rem 0 1rem 0;

          .content_slide_view {
            > .content_arrow {
              gap: 4rem;

              > .slide_btn {
                border-radius: 10rem;
                border: 0.1rem solid $tab-text;
                width: 2.4rem;
                height: 2.4rem;
              }

              span {
                @include position_relative;
                margin: 0 0 0 -2.4rem;

                &::after {
                  content: '';
                  width: 1rem;
                  height: 1rem;
                  background-color: $primary-color;
                  border-radius: 10rem;
                  @include position_absolute;
                  top: 50%;
                  right: -2.5rem;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }

      &.events_projects {
        .card {
          .card_body {
            .card_inner {
              .card_content {
                .hastag {
                  gap: 0.5rem;
                }

                .card_title {
                  max-width: 22rem;
                  height: 5rem;
                  margin-bottom: 1rem;
                }

                .user_of_prosess {
                  @include d_flex_center;
                  gap: 1.5rem;
                  margin-bottom: 2.4rem;

                  .user_pic {
                    @include d_flex_center;
                    @include w_100;
                    max-width: 2.4rem;
                    height: 2.4rem;
                    border-radius: 23.668px;
                    border: 0.1rem solid $white-color;
                    box-shadow: 1.7222224473953247px 1.7222224473953247px 8.611111640930176px 0px
                      rgba(134, 172, 218, 0.44);
                    padding: 0;

                    &.user_profile_process_data {
                      max-width: 3.4rem;
                      height: 3.4rem;
                      margin-right: 0;
                    }

                    img {
                      @include w_100;
                      height: 100%;
                      border-radius: 23.668px;
                      @extend .img_object_center;
                    }
                  }

                  .user_progresing {
                    @include w_100;

                    .user_progess_percentage {
                      @include d_flex_center;
                      @extend .justify_content_between;
                      margin-bottom: 0.8rem;

                      .progess {
                        @include d_block;
                        color: $black-color;
                        @extend .fs_14;
                        @extend .fw_600;
                      }

                      .percentage {
                        @include d_block;
                        color: $black-color;
                        @extend .fs_14;
                        @extend .fw_500;
                      }
                    }

                    .progrees_bar {
                      @include w_100;
                      height: 0.7rem;
                      border-radius: 100px;
                      background: #d9d9d9;
                      @include over_hidden;

                      .progress {
                        @include d_block;
                        @include apply_bgcolor(Light_blue_clr);
                        @include w_100;
                        height: 100%;
                      }
                    }
                  }
                }
              }

              .multi_member {
                gap: 1.6rem;
                margin-bottom: 3rem;
                padding-left: 0.8rem;

                span {
                  overflow: hidden;
                  width: 2.5rem;
                  height: 2.5rem;
                  @extend .d_inline_block;
                  margin-left: -0.8rem;

                  img {
                    border-radius: 10rem;
                    width: 2.5rem;
                    height: 2.5rem;
                  }
                }

                .count_member {
                  margin-bottom: 0;
                  @extend .fw_500;
                  font-size: 1.4rem;
                  color: $tab-text;

                  span {
                    height: auto;
                    @include fw_700;
                    color: $primary-color;
                  }
                }
              }
            }
          }
        }
      }

      .custom_modalbox {
        background-color: rgba(22, 35, 83, 0.3);
        box-shadow: 0 0 3rem 0 hsla(0, 0%, 45%, 0.15);
        @extend .d_grid;
        @extend .position_fixed;
        height: 100%;
        @include w_100;
        left: 0;
        @include over_hidden;
        place-items: center;
        top: 0;
        z-index: 99;
        animation: popupRoll 0.6s ease-in-out forwards;

        @keyframes popupRoll {
          0% {
            opacity: 0;
            transition: all 0.6s ease-in-out;
          }

          100% {
            opacity: 1;
            transition: all 0.6s ease-in-out;
          }
        }
      }
    }

    &::after {
      content: '';
      @include position_absolute;
      border: 0.1rem solid #dceafb;
      top: -25rem;
      right: 0;
      left: 0;
      width: 0.1rem;
      height: 25rem;
      margin: 0 auto;
    }

    .message_box {
      height: auto;

      .chat_box {
        padding-top: 0;
        width: 26.1rem;

        .chatbox_area {
          padding: 0;
          height: auto;
          overflow: initial;

          .content_wrapper_video {
            .content_video_inner {
              .content_video {
                > p {
                  background-color: $dark-blue;
                  border-radius: 0 0 0.8rem 0.8rem;
                }
              }

              > span {
                &.msg_content_pic {
                  img {
                    height: 15rem;
                    border-radius: 0.8rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .Pagination_wrapper {
    border-top: 0.1rem solid #dceafb;
    background: $white-color;
    box-shadow: 0px 0px 3rem 0px rgba(214, 226, 245, 0.2);
  }

  .switch {
    margin-bottom: 1.2rem;
  }

  .no_conversation {
    .no_conversation_wrapper {
      background: rgba(231, 241, 253, 0.35);
      border-radius: 0.8rem;
      padding: 2.4rem;

      .image {
        width: 11.5rem;
        height: 11.5rem;
        background-color: $form-input-active-bg;
        border-radius: 10rem;
        @include d_flex;
        @extend .align_items_center;
        @extend .justify_content_center;
        margin: 0 auto 2.4rem;
      }

      .content {
        h3 {
          @extend .fw_600;
          font-size: 2.4rem;
          line-height: 110%;
          color: $tab-head;
          @extend .text_center;
          margin-bottom: 1.6rem;
        }

        span {
          @include fw_400;
          font-size: 1.6rem;
          line-height: 140%;
          max-width: 30.4rem;
          @include d_block;
          @extend .text_center;
          margin: 0 auto 1.5rem;
        }
      }

      &.no_result_event {
        background: transparent;
        padding-top: 7rem;
        padding-bottom: 5rem;

        .image {
          img {
            width: 5.6rem;
            height: 5.6rem;
          }
        }
      }
    }
  }

  .potential_match_details {
    .main_send_msg {
      .inner_details {
        .profile_img {
          margin-bottom: 1.6rem;
        }

        button {
          min-width: 18.2rem;
          @extend .gap_12;
          min-height: 4rem;
          padding: 0.6rem 2.4rem;

          &:hover {
            border: 0.2rem solid $primary-color;

            > img {
              filter: brightness(21);
            }
          }
        }
      }
    }

    .pairs_activity_detail {
      .pair_activity_info {
        &:first-child {
          padding-left: 4rem;
        }
      }

      .main_user_info {
        border: 0;

        &:first-child {
          .user_info {
            &.pair_activity_userinfo {
              padding-right: 0;
              padding-left: 9rem;
              min-width: 29rem;
              max-width: 29rem;
            }
          }
        }

        &:last-child {
          .user_info {
            &.pair_activity_userinfo {
              padding-right: 0;
              padding-left: 2rem;
              min-width: 29rem;
              max-width: 29rem;
            }
          }
        }
      }
    }
  }

  .i_popup {
    .i_popup_content {
      .send_main_form {
        .form-group.mail_msg {
          textarea {
            min-height: 13.2rem;
          }
        }
      }
    }
  }

  .table_wrapper {
    .table_responsive {
      .pairs_user_listing {
        min-width: 135rem;

        &.disabled {
          tbody {
            tr {
              opacity: 0.65;
              background: #f6f6f6;
            }
          }
        }
      }
    }
  }

  .chat_pairs::-webkit-scrollbar {
    width: 0.8rem;
    height: 8rem;
  }

  .chat_pairs::-webkit-scrollbar-track {
    background: $form-input-active-bg;
    border-radius: 5px;
  }

  .chat_pairs::-webkit-scrollbar-thumb {
    background: $lighten-dark-blue;
    border-radius: 100px;
    height: 8rem;
  }

  .i_popup {
    &.i_new_msg_popup {
      .i_popup_content {
        max-width: 78rem !important;
        max-height: 82rem;
        height: 90%;
        @include d_flex;
        @extend .flex_direction_column;

        .i_popup_header {
          padding-bottom: 3.2rem;
        }
      }
    }
  }

  .pair_report_main {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .pair_report_inner {
      padding: 0 3.2rem;

      .reporting_ul {
        .pairs_reporting_li {
          border-radius: 0.8rem;
          border: 0.1rem solid $border-color;
          background: $white-color;
          box-shadow: 0.8rem 0.4rem 2.5rem 0 rgba(82, 99, 116, 0.04);
          min-width: 26.476rem;
          max-width: 27rem;
          min-height: 40rem;

          .reporting_head {
            border-radius: 0.8rem 0.8rem 0 0;
            background: rgba(19, 115, 231, 0.1);
            padding: 2rem 0 2rem 2rem;
            @include d_flex;
            @extend .align_items_center;
          }

          .pairs_reporting_main_wrap {
            > ul {
              .inner_div_pairs {
                min-height: 20rem;
              }

              li {
                padding: 1rem 0;

                .progress_detail {
                  .sent_by_name {
                    color: $black-color;
                  }

                  .persantage {
                    color: $light-gray;
                  }
                }

                .progress_graph {
                  background-color: $border-color;

                  .graph {
                    background-color: $border-color;
                    min-height: 0.4rem;
                    @include w_100;
                    @include d_block;
                    border-radius: 1rem;
                    max-width: 100%;

                    &.Pairs_graph_active {
                      background-color: $primary-color;
                    }
                  }
                }
              }
            }

            .message {
              color: $light-gray;
            }

            .counting {
              color: $primary-color;
            }
          }

          .pairs_reporting_main_wrap.skeleton_li {
            .skeleton_profile {
              width: 15%;
              height: 3.2rem;

              .react-loading-skeleton {
                height: 100%;
              }
            }

            .message_width {
              width: 70%;
            }

            .sent_by_name {
              width: 64%;
            }

            .persantage {
              width: 20%;
            }

            .progress_graph {
              height: 0.7rem;

              .react-loading-skeleton {
                height: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .voice_msg {
    max-width: 33rem;
  }
}

.i_popup {
  &.i_new_msg_popup {
    .i_popup_content {
      .send_main_form {
        flex: 1;
      }

      .i_popup_footer {
      }

      .mesg_image_upld_wrapper {
        .upload_img_mesg_li {
          .mesg_image_upld {
            border: 0.1rem solid $border-color;
            border-radius: 0.8rem;
            @include over_hidden;
            @include d_flex_center;

            > img {
              height: 12rem;
            }

            .loader_bg {
              background: transparent;

              .loader {
                > img {
                  padding: 4.5rem;
                }
              }
            }

            .white_bg {
              background-color: $white-color;
              border-radius: 0.8rem;
            }
          }
        }
      }

      .upload_file_mesg {
        overflow: auto;
      }
    }
  }
}

.modal_popup.audio_popup.zoomin_popup {
  @extend .position_fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(4.9064698219px);
  background-color: rgba(45, 45, 45, 0.65);
  height: 16.6rem;
  max-width: 85.2rem;
  @include w_100;
  padding: 2.4rem 3.2rem;
  border-radius: 2rem;
  @include trans;
  animation: zoom 0.6s ease-in-out forwards;

  @keyframes zoom {
    0% {
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }

    100% {
      opacity: 1;
      transition: all 0.8s ease-in-out;
    }
  }
}

.audio_popup {
  .modal_heading {
    margin-bottom: 4rem;
  }

  h2.text_left {
    color: $white-color;
    @extend .fs_24;
    @extend .fw_600;
    line-height: normal;
    margin-bottom: 0;
  }

  .modal_popup_close {
    img {
      filter: brightness(0) invert(1);
    }
  }

  .control__box {
    .inner__controls {
      @include d_flex;
      @extend .align_items_center;
      @extend .justify_content_around;
      gap: 3rem;
      padding: 0 1rem;
      height: 4.1rem;

      .play_push_btn {
        img {
          width: 3.8rem;
          height: 3.8rem;
          object-fit: contain;
        }
      }

      .play_btn_circle_pause {
        background-color: $white-color;
        width: 3.8rem;
        height: 3.8rem;
        @include d_flex_center;
        border-radius: 10rem;

        img {
          width: 1.2rem;
          height: 1.2rem;
          filter: brightness(0.3);
        }
      }
    }

    .spanVideo {
      color: $white-color;
      font-size: 23.512px;
      @include fw_400;
      line-height: normal;
      min-width: fit-content;
    }

    .progress_bar {
      @include w_100;
      max-width: 51.4rem;

      input {
        @include w_100;
      }
    }
  }
}

.modal_popup.video_popup.zoomin_popup {
  @extend .position_fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include w_100;
  height: 59.9rem;
  border-radius: 3rem;
  overflow: hidden;
  max-width: 1024px;
  animation: zoom 0.6s ease-in-out forwards;

  @keyframes zoom {
    0% {
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }

    100% {
      opacity: 1;
      transition: all 0.8s ease-in-out;
    }
  }
}

.video_popup {
  video {
    object-fit: cover;
  }

  .modal_popup_close {
    z-index: 999;
    cursor: pointer;
  }

  .modal_heading {
    @include position_absolute;
    top: 0;
    background: transparent;
    padding: 2.4rem 3.2rem;
    @include w_100;
    opacity: 0;
    transition: all 0.6s ease-in-out;
  }

  .mid__container {
    @include position_absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include d_flex;
    @extend .align_items_center;
    gap: 11.5rem;
    opacity: 0;
    transition: all 0.6s ease-in-out;

    button {
      > img {
        width: 5rem;
        height: 5rem;
        object-fit: contain;
      }
    }
  }

  .bottom__container {
    @include position_absolute;
    bottom: 0;
    @include w_100;
    max-width: 944px;
    margin: 0 auto;
    background: var(--Button, rgba(45, 45, 45, 0.65));
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    opacity: 0;
    transition: all 0.6s ease-in-out;

    .play_btn_circle_pause {
      background-color: $white-color;
      width: 3.8rem;
      height: 3.8rem;
      @include d_flex_center;
      border-radius: 10rem;

      img {
        width: 1.2rem;
        height: 1.2rem;
        filter: brightness(0.3);
      }
    }
  }

  h2.text_left {
    color: $white-color;
    @extend .fs_24;
    @extend .fw_600;
    line-height: normal;
    margin-bottom: 0;
  }

  .modal_popup_close {
    img {
      filter: brightness(0) invert(1);
    }
  }

  .control__box {
    .inner__controls {
      @include d_flex;
      @extend .align_items_center;
      justify-content: space-around;
      gap: 3rem;

      button {
        > img {
          width: 3.8rem;
          height: 3.8rem;
          object-fit: contain;
        }
      }
    }

    .play_push_btn {
      width: 3.58rem;
      height: 3.58rem;
      border-radius: 100%;
      background: $white-color;
    }

    .spanVideo {
      color: $white-color;
      font-size: 23.512px;
      @include fw_400;
      line-height: normal;
      min-width: fit-content;
    }

    .progress_bar {
      @include w_100;
      max-width: 51.4rem;

      input {
        @include w_100;
      }
    }
  }

  .play_btn {
    border-bottom: 2.5rem solid $white-color;
    border-left: 2.5rem solid $white-color;
    border-color: $white-color $white-color transparent transparent;
    border-radius: 0.5rem;
    border-style: solid;
    border-width: 2.5rem;
    height: 5rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 5rem;
    @include d_flex;
  }

  &:hover {
    .modal_heading {
      opacity: 1;
      transition: all 0.6s ease-in-out;
    }

    .video_popup {
      .mid__container {
        opacity: 1;
        transition: all 0.6s ease-in-out;
      }

      .bottom__container {
        opacity: 1;
        transition: all 0.6s ease-in-out;
      }
    }
  }
}

.highlighted_mentor_search {
  background-color: #dceafb;
  color: #333333;
  @include fw_700;
  padding: 0.45rem 0.35rem;
}

.highlighted_mentee_search {
  background-color: #072851;
  color: #ffff;
  @include fw_700;
  padding: 0.45rem 0.35rem;
}

.search_length {
  width: auto;
  height: auto;
  right: 3.8rem;
  top: 50%;
  transform: translateY(-50%);
}

.msg {
  white-space: pre-line;
}

.chat_admin_msg,
.chat_admin_file {
  max-width: calc(100% - 10rem);
  width: 100%;
  margin: 0 auto 30px;

  + .msg_time {
    @extend .text_center;
  }

  .msg_time {
    @extend .text_center;
    margin-bottom: 0.8rem;
    width: 100%;
    order: 1;
  }

  ul {
    @include d_flex;
    @extend .flex_direction_column;
    @extend .align_items_center;

    li {
      border-radius: 0px 4px 4px 4px;
      background: $dark_gray;
      padding: 16px;
      font-style: italic;
      @extend .fw_500;
      @include fs_16;
      line-height: 140%;
      color: $white-color;
      border: 1px solid #fff;
      @extend .text_center;

      .link_btn {
        margin-left: 5px;
        color: $white-color;
      }

      span {
        a {
          color: currentColor;
          font-style: normal;
          text-decoration: underline;
        }

        @include fs_16;
      }
    }
  }
}

.chat_admin_file {
  .msg_time {
    @extend .text_center;
  }

  .admin_file_list {
    @include d_flex;
    @extend .flex_wrap;
    @extend .align_items_center;
    @extend .justify_content_center;
    gap: 2rem;

    &:not(.msg_time) > div {
      order: 2;
    }

    .recive_msg {
      &.send_msg {
        order: 2;
      }
    }

    .admin_pdf_send {
      @include d_flex;
      @extend .align_items_center;
      order: 2;
      gap: 1rem;

      .pdf_file {
        img {
          @include d_block;
        }
      }

      .pdf_title {
        @include d_flex;
        @extend .align_items_center;
        gap: 1rem;
        padding: 0 10px;

        .pdf_name {
          color: $black-color;
          @extend .fs_18;
          @include fw_700;
          line-height: 140%;
        }

        .pdf_size {
          color: $black-color;
          @extend .text_right;
          @extend .fs_12;
          @include fw_400;
          line-height: 140%;
        }
      }
    }

    .admin_img_send {
      max-width: 180px;
      width: 100%;
      border: 1px solid $border-color;
      border-radius: 8px;
      position: relative;
      padding-bottom: 29.2%;
      overflow: hidden;
      order: 2;

      img {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.calender_sl_main {
  padding: 1.6rem 0;

  .calender_sl {
    @include d_flex;
    @extend .align_items_center;
    max-width: 39.5rem;
    width: 100%;
    margin: 0 auto;

    .slick-list {
      padding: 0 0 1.2rem;

      .calender_item {
        @extend .cursor_pointer;

        h3 {
          @include fs_16;
          line-height: 1.4;
          color: $tab-text;
          @extend .montserrat_bold;
          @extend .text_center;
        }

        &.current_month {
          h3 {
            color: $primary-color;
            text-decoration: underline;
            text-underline-offset: 7px;
            text-decoration-thickness: 2px;
          }
        }
      }
    }

    .slick-arrow {
      position: static;
      transform: translate(0px, -7px);
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      transition: all 0.2s ease-in-out;

      &:before {
        content: none;
      }

      &.slick-next {
      }

      &.slick-disabled {
        opacity: 0;
      }

      &.arrow_disable {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .calender_date_list {
    ul {
      @include d_flex;
      @extend .align_items_center;
      @extend .justify_content_center;
      gap: 8px;

      li {
        width: 24px;
        height: 24px;
        cursor: pointer;

        span {
          @include w_100;
          @include h_100;
          @include d_flex;
          @extend .align_items_center;
          @extend .justify_content_center;
          border-radius: 50%;
          @extend .fs_14;
          @extend .montserrat_medium;
        }

        &.active_date {
          span {
            background: $primary-color;
            color: $white-color;
          }
        }
      }
    }
  }
}

.cl_message_history {
  .cl_ms_inner {
    padding: 0.5rem 0;
    @include d_flex;
    @extend .align_items_center;
    @extend .justify_content_center;

    .cl_ms_inner_list {
      @include position_relative;

      ul {
        @include d_flex;
        @extend .align_items_center;
        @extend .justify_content_center;
        gap: 10px;

        li {
          width: 2.2rem;
          height: 2.2rem;
          background: transparent;
          border: 1px solid $dark_gray_full;
          border-radius: 4px;

          &.gray_chat_box {
            background: $dark_gray_full;
            border-color: transparent;
          }

          &.pr_border_onl_box {
            border-color: $primary-color;
            background: transparent;
          }

          &.pr_msg_box {
            border-color: $primary-color;
            background: $pair-bg;
          }

          &.both_msg_box {
            border-color: transparent;
            background: $pair-bg;
          }

          &.pr_event_msg {
            border-color: $primary-color;
            background: $pair-bg;
            @include position_relative;

            &:before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: $primary-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }

          &.pr_event_invitation {
            border-color: $primary-color;
            background: $pair-bg;
            @include position_relative;

            &:before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              border: 1px solid $primary-color;
              height: 0.8rem;
              background: $white-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }

          &.pr_event_invitation_accept {
            background: transparent;
            @include position_relative;

            &:before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              border: 1px solid $primary-color;
              height: 0.8rem;
              background: transparent;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }

          &.pr_event_invitation_decline {
            border-color: $primary-color;
            background: transparent;
            @include position_relative;

            &:before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              border: 1px solid $primary-color;
              height: 0.8rem;
              background: transparent;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }

          &.pr_event_msg_pair {
            border-color: $primary-color;
            background: transparent;
            @include position_relative;

            &:before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: $primary-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }

          &.pr_event_msg_ind {
            background: $dark_gray_full;
            background: transparent;
            @include position_relative;

            &:before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: $primary-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }

          &.pr_border_event_msg {
            border-color: transparent;
            background: $pair-bg;
            @include position_relative;

            &:before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: $primary-color;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
            }
          }

          &.pr_border_not_event_msg {
            border-color: transparent;
            background: $pair-bg;
            @include position_relative;

            &:before {
              content: '';
              @include position_absolute;
              width: 0.8rem;
              height: 0.8rem;
              background: white;
              bottom: 0.2rem;
              right: 0.2rem;
              border-radius: 0.2rem;
              border: 1px solid $primary-color;
            }
          }
        }
      }

      h3 {
        @include position_absolute;
        top: 50%;
        left: calc(100% + 8px);
        top: 1px;
        @include fs_16;
        color: $tab-text;
        @extend .montserrat_medium;
      }
    }
  }
}

.pr_message_info {
  .pr_red_dot {
    @include d_flex;
    @extend .align_items_center;
    @include d_block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $red-border;
    margin-right: 1rem;
  }
}

.emoji_rection_main {
  margin: 3px 4px -24px 0;
  @include d_flex;
  .react_emoji {
    border: 0.1rem solid $border-color;
    border-radius: 50px;
    min-width: 2.8rem;
    height: 2.8rem;
    @include d_flex_center;
    z-index: 1;
    background: $white-color;
    margin: 0px 4px 0px 0;
    @include position_relative;
    @extend .trans;
    width: max-content;
    &:hover {
      border-color: $black-color;
      background: $reaction_back;
      z-index: 2;
    }
    span {
      &.default_emoji {
        margin-top: 0;
        padding: 0 0.6rem;
        font-size: 1.2rem;
        @include d_flex_center;
        .reaction_count {
          @extend .fs_12;
          margin: 0 0 0 0.3rem;
          @extend .fw_500;
          color: $msg-date;
        }
      }
    }
    .reaction_popup {
      box-shadow: 0 25px 25px rgba(0, 0, 0, 0.15);
      background: $white-color;
      padding: 1rem 0;
      @include position_absolute;
      z-index: 9;
      min-width: 18rem;
      top: calc(100% + 1.5rem);
      left: 50%;
      right: 0;
      margin: 0 auto;
      transform: translate(-50%, 0px);
      @extend .trans;
      border: 0.1rem solid $border-color;
      border-radius: 0.4rem;
      opacity: 0;
      visibility: hidden;
      &:before {
        content: '';
        @include position_absolute;
        width: 1.5rem;
        height: 1.5rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: calc(100% - 0.7rem);
        background: $white-color;
        transform: rotate(45deg);
        pointer-events: none;
        border-top: 0.1rem solid $border-color;
        border-left: 0.1rem solid $border-color;
      }

      ul {
        padding: 0rem 1rem;
        max-height: 10rem;
        overflow: auto;
        background: $white-color;
        @include position_relative;
        li {
          @include d_flex;
          @extend .align_items_center;
          &:not(:last-child) {
            padding: 0 0 1rem;
          }
          .reaction_profile {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 0.6rem;
            @extend .radius_round;
            @include over_hidden;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          span {
            margin: 0;
            font-size: 1.2rem;
            @extend .fw_500;
            color: $tab-text;
            line-height: 1.7rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 12.6rem;
            width: 100%;
          }
        }
      }
    }
    &:hover {
      .reaction_popup {
        opacity: 1;
        visibility: visible;
      }
    }
    &.my_rection {
      border-color: $primary-color;
      .default_emoji {
        .reaction_count {
          color: $primary-color;
        }
      }
    }
  }
}
.pairs_count_container {
  .pairs_count_label {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    margin-right: 2px;
    color: #191988;
  }

  .pairs_count_value {
    font-size: 20px;
    font-weight: bolder;
    color: #0c0c56;
  }

  .react_emoji {
    border: 0.1rem solid $border-color;
    border-radius: 50px;
    min-width: 2.8rem;
    height: 2.8rem;
    @include d_flex_center;
    z-index: 1;
    background: $white-color;
    margin: 0px 4px 0px 0;
    @include position_relative;
    @extend .trans;
    width: max-content;

    &:hover {
      border-color: $black-color;
      background: $reaction_back;
      z-index: 2;
    }

    span {
      &.default_emoji {
        margin-top: 0;
        padding: 0 0.6rem;
        font-size: 1.2rem;
        @include d_flex_center;

        .reaction_count {
          @extend .fs_12;
          margin: 0 0 0 0.3rem;
          @extend .fw_500;
          color: $msg-date;
        }
      }
    }

    .reaction_popup {
      box-shadow: 0 25px 25px rgba(0, 0, 0, 0.15);
      background: $white-color;
      padding: 1rem 0;
      @include position_absolute;
      z-index: 9;
      min-width: 18rem;
      top: calc(100% + 1.5rem);
      left: 50%;
      right: 0;
      margin: 0 auto;
      transform: translate(-50%, 0px);
      @extend .trans;
      border: 0.1rem solid $border-color;
      border-radius: 0.4rem;
      opacity: 0;
      visibility: hidden;

      &:before {
        content: '';
        @include position_absolute;
        width: 1.5rem;
        height: 1.5rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: calc(100% - 0.7rem);
        background: $white-color;
        transform: rotate(45deg);
        pointer-events: none;
        border-top: 0.1rem solid $border-color;
        border-left: 0.1rem solid $border-color;
      }

      ul {
        padding: 0rem 1rem;
        max-height: 10rem;
        overflow: auto;
        background: $white-color;
        @include position_relative;

        li {
          @include d_flex;
          @extend .align_items_center;

          &:not(:last-child) {
            padding: 0 0 1rem;
          }

          .reaction_profile {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 0.6rem;
            @extend .radius_round;
            @include over_hidden;
            flex-shrink: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          span {
            margin: 0;
            font-size: 1.2rem;
            @extend .fw_500;
            color: $tab-text;
            line-height: 1.7rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 12.6rem;
            width: 100%;
          }
        }
      }
    }

    &:hover {
      .reaction_popup {
        opacity: 1;
        visibility: visible;
      }
    }

    &.my_rection {
      border-color: $primary-color;

      .default_emoji {
        .reaction_count {
          color: $primary-color;
        }
      }
    }
  }
}

.pair_view_visualization_container {
  margin-top: 1.6rem;
  display: flex;
  gap: 3.2rem;
  width: 100%;
}

.pair_view_visualization_box {
  width: 250px;
  height: 250px;
  background-color: #b6d4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: black;
}

.pair_view_graph-container {
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  padding: 20px 0 0 10px;
}

.priority_groups_chart_main_box {
  background-color: transparent;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: flex-start;
}

.message_and_pairs_main_box {
  background-color: transparent;
  align-items: flex-start;
  width: auto;
}

.pair_view_visualization_placeholder_text {
  font-size: 24px;
  font-weight: bold;
}

.messageAndEventData {
  display: flex;
  position: relative;
  text-align: center;
  padding: 20px;

  th {
    padding-left: 20px;
    padding-right: 20px;
  }

  td {
    height: 60px;
  }

  .tableHeaderMainBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .messageAndEventNumbers {
    font-size: 2.8rem;
    margin-left: 8.5rem;
    font-weight: bold;
  }

  img {
    width: 4rem;
  }

  .textLabels {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .subTextLabel {
    font-size: 18px;
    font-style: italic;
    font-weight: normal;
  }

  .eventLabel {
    position: absolute;
    top: -5rem;
    left: 95%;
    transform: translateX(-50%);
  }
}
