.message_box {
  @include d_flex;
  padding: 8.2rem 0 0;
  height: 100vh;
  @include d_flex;
  .connection_list {
    padding: 1.5rem 0 0;
  }
  .pagination_wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .pagination {
    padding: 0 !important;
    margin-left: -5px;
  }
  &:not(.expand_box) {
    .connection_list {
      max-width: 35rem;
      width: 40%;
      @include d_flex;
      @extend .flex_direction_column;
      padding: 1rem 0 0;
    }
    .chat_box {
      flex: 1;
    }
    .main-head {
      h3 {
        display: none;
      }
    }
  }
  .filter_dropdown {
    padding-right: 0;
  }
  .comman_lay {
    border-right: 0.1rem solid $border-color;
    scrollbar-width: thin;
    width: 100%;
    @include d_flex;
    @extend .flex_direction_column;
    .serchbox {
      padding: 0 3.2rem;
      margin: 0 0 3rem;
      form {
        @include position_relative;
        min-width: 29.2rem;
        input {
          background-color: $search-input;
          width: 100%;
          height: 5.5rem;
          border-radius: 5rem;
          border: 0;
          padding-left: 5rem;
          @extend .montserrat_medium;
          font-size: 1.6rem;
          color: $tab-text;
          line-height: 2.2rem;
          padding-right: 4.5rem;
          &:active,
          &:focus {
            outline: none;
          }
        }
        span {
          border: 0;
          background-color: transparent;
          @include position_absolute;
          left: 1.9rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .serch_icon_remove {
        @include position_absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2rem;
        left: auto;
        &:hover {
          .tooltip {
            @include d_block;
            top: -3.5rem;
            left: auto;
            right: 0;
          }
        }
      }
    }
    h2 {
      font-size: 2.4rem;
      @extend .montserrat_medium;
      color: $black-color;
      padding: 2.5rem 3.2rem;
      line-height: normal;
    }
    .chat_listing {
      overflow: auto;
      max-height: 69rem;
      flex: 1;
      scrollbar-width: thin;
      margin: 1.5rem 0 0;
      border-top: 0.1rem solid $border-color;
      .chat_info {
        padding: 2rem 3rem;
        &:not(:first-child) {
          border-top: 0.1rem solid $border-color;
        }
        &:hover,
        &.selected {
          background-color: rgba(214, 226, 245, 0.2);
        }
        .chat_profile {
          p {
            @extend .montserrat_semibold;
            font-size: 1.6rem;
            color: $black-color;
            max-width: 16rem;
            @include over_hidden;
            @extend .text_ellipsis;
            @extend .white_space_nowrap;
          }
          img {
            box-shadow: 0px 0.2rem 0.4rem 0px rgba(0, 0, 0, 0.25);
            border-radius: 5rem;
            margin-right: 1rem;
            width: 4rem;
            height: 4rem;
          }
          .user-info {
            .user_profile {
              @extend .border_0;
              &:hover {
                @extend .border_0;
              }
            }
          }
        }
        span {
          color: $light-gray;
          @extend .montserrat_medium;
          font-size: 1.4rem;
          max-width: 16rem;
          @extend .text_ellipsis;
          @include over_hidden;
          @extend .white_space_nowrap;
          @include d_block;
          &.user_profile_pic {
            &.user_pic {
              @include d_flex_center;
              text-transform: uppercase;
              color: $white-color;
              width: 4rem;
              height: 4rem;
              margin-right: 0;
              padding: 0;
              > img {
                width: 4rem;
                height: 4rem;
                @extend .img_object_center;
                margin-right: 0;
              }
            }
          }
        }
        .chat_status {
          span {
            @include d_flex;
            &.chat_time {
              font-size: 1.3rem;
            }
            &.msg_num {
              width: 1.8rem;
              height: 1.8rem;
              border-radius: 5rem;
              color: $white-color;
              font-size: 1rem;
              @extend .montserrat_bold;
              background-color: $primary-color;
              @extend .justify_content_center;
              @extend .align_items_center;
              margin-left: auto;
              margin-top: 0.1rem;
            }
          }
          img {
            margin-left: auto;
            text-align: right;
            @include d_block;
          }
        }
        &:last-child {
          border-bottom: 0.1rem solid $border-color;
        }
      }
      .chat_profile {
        width: 100%;
        > span {
          max-width: unset;
        }
        .skel_img_wrap {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          margin-right: 1.5rem;
          + br {
            display: none;
          }
        }
        .skel_cnt_wrap {
          width: 100%;
          p {
            > span {
              width: calc(100% - 5rem);
              max-width: 100%;
              margin: 0 0 0.7rem;
              span {
                width: 100%;
                display: block;
                max-width: 100%;
              }
            }
          }
          br {
            display: none;
          }
        }
      }
    }
    .chat_listing_wrapper {
      overflow: auto;
      height: 71.1rem;
    }
  }
  .chat_box {
    padding-bottom: 0;
    @include position_relative;
    @include d_flex;
    @extend .flex_direction_column;
    .chat_header_main {
      @include position_relative;
    }
    .chat_box_info {
      padding: 2.5rem 3.2rem;
      border-bottom: 0.1rem solid $border-color;
      position: sticky;
      top: 0;
      z-index: 10;
      background: $white-color;
      .chat_box_profile {
        .user_profile {
          padding: 0;
          .user_profile_pic {
            width: 5.5rem;
            height: 5.5rem;
            border-radius: 50%;
            @include over_hidden;
            margin-right: 1.2rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .chat_profile_name {
          p {
            @extend .montserrat_semibold;
            color: $black-color;
            font-size: 1.6rem;
          }
        }
        .user-info {
          .user_profile {
            @extend .border_0;
            &:hover {
              @extend .border_0;
            }
          }
        }
        span {
          &.user_profile_pic {
            &.user_pic {
              @include d_flex_center;
              text-transform: uppercase;
              color: $white-color;
              padding: 0;
              @extend .fs_12;
            }
          }
        }
      }
      .chat_close {
        @extend .msg_border_icon;
        img {
          width: 2.4rem;
          height: 2.4rem;
        }
        &:hover {
          background-color: $dark-blue;
          img {
            filter: brightness(21);
          }
        }
      }
    }
    .old_msg_date {
      @extend .text_center;
      background: $white-color;
      padding: 0.5rem 0;
      @include position_relative;
      border-bottom: 1px solid $border-color;
      z-index: 9;
      margin: -70px 0 0;
      opacity: 0;
      visibility: hidden;
      @extend .trans;
      span {
        padding: 0.8rem 2.2rem;
        font-size: 1.6rem;
        @extend .fw_600;
        color: $msg-date;
        border: 1px solid $border-color;
        border-radius: 50px;
        line-height: 2.2rem;
        display: inline-flex;
        background: $white-color;
        box-shadow: 0px 4px 40px 0px #24498914;
        @include position_relative;
        bottom: -23px;
      }
      &.active_old_msg {
        margin: 0;
        opacity: 1;
        visibility: visible;
      }
    }
    .unread_msg {
      @include position_absolute;
      top: calc(100% + -2rem);
      z-index: 9;
      background-color: $primary-color;
      left: 0;
      right: 0;
      display: inline-flex;
      @extend .align_items_center;
      width: max-content;
      padding: 0.55rem 1.2rem;
      border-radius: 50px;
      margin: 0 auto;
      @extend .trans;
      opacity: 0;
      visibility: hidden;
      h4 {
        color: $white-color;
        font-size: 1.2rem;
        @include fw_400;
        line-height: 2.2rem;
      }
      img {
        margin-left: 1rem;
        cursor: pointer;
      }
      &.show_unread_msg {
        top: calc(100% + 2.6rem);
        opacity: 1;
        visibility: visible;
      }
    }
    .chatbox_area {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 2.5rem 2.7rem;
      flex: 1;
      &::-webkit-scrollbar {
        width: 0.6rem;
      }
      &::-webkit-scrollbar-track {
        background: $border-color;
      }
      &::-webkit-scrollbar-thumb {
        @include apply_bgcolor(Light_blue_clr);
      }
      @include position_relative;
      .suggestion_tab {
        @include position_absolute;
        bottom: 2.5rem;
        left: 3.4rem;
        ul {
          list-style-type: none;
          column-gap: 1.2rem;
          row-gap: 1rem;
          @extend .flex_wrap;
          li {
            @extend .montserrat_medium;
            font-size: 1.6rem;
            color: $tab-text;
            line-height: 2.2rem;
            padding: 1rem 2rem;
            background-color: $search-input;
            border-radius: 5rem;
          }
        }
      }
      .chatting {
        @include d_flex;
        @extend .flex_direction_column;
        .media_img_wrapper {
          width: max-content;
          .media_img {
            object-fit: cover;
            max-height: 30rem;
            max-width: 30rem;
            width: auto;
            height: auto;
            border-radius: 1rem;
          }
          &:hover {
            .media_img_icon_section {
              opacity: 1;
            }
          }
          .media_img_icon_section {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            opacity: 0;
            .media_img_icon {
              width: 36px;
              height: 36px;
              background: rgba(30, 37, 56, 0.5);
              border-radius: 3px;
              display: flex;
              margin: 0 5px;
              cursor: pointer;
              svg {
                margin: auto;
              }
            }
          }
        }

        .message {
          @extend .flex_direction_column;
          margin-bottom: 2.5rem;
          @extend .justify_content_end;
          @extend .align_items_end;
          display: inline-flex;
          @include position_relative;
          width: 100%;
          &.my_message {
            .chat_text_msg {
              margin: 0 0 0 auto;
            }
          }
          .emoji_reaction {
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 50%;
            border: 1px solid $border-color;
            background: $white-color;
            @include d_flex;
            @extend .cursor_pointer;
            box-shadow: 0px 4px 40px 0px #24498914;
            @include position_absolute;
            bottom: 11px;
            flex-shrink: 0;
            left: calc(100% + 0.8rem);
            img {
              width: 1.4rem;
              height: auto;
              margin: auto;
            }
          }
          .chat_profile_pic {
            margin: 0.4rem 1rem 0 0;
            .user-info {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
              @include over_hidden;
              img {
                width: 100%;
                @include h_100;
                @extend .img_object_center;
              }
            }
          }
          .message_main {
            @include d_flex;
            @extend .align_items_end;
            width: 100%;
            .chat_text_msg {
              max-width: 80%;
            }
            &:empty {
              display: none;
            }
          }
          .chat_text_msg {
            padding: 1.4rem 2.2rem;
            @extend .montserrat_regular;
            font-size: 1.6rem;
            line-height: 2.2rem;
            border-radius: 0.8rem;
            border: 0.1rem solid $border-color;
            background-color: $white-color;
            color: $tab-text;
            word-break: break-word;
            @extend .d_inline_block;
            .link_btn {
              color: $white-color;
              text-decoration: underline;
              margin-left: 0.5rem;
            }
          }

          &.my_message {
            .chat_text_msg {
              background-color: $dark-blue;
              color: $white-color;
              border-top-right-radius: 0;
              @extend .d_inline_block;
              box-shadow: 0px 0px 30px 0px #74747426;
              a:not(.link_btn) {
                color: currentColor;
                text-decoration: underline;
              }
              span {
                color: $white-color;
                @include fs_16;
              }
            }
          }
          &.other_message {
            @extend .flex_direction_row;
            @extend .align_items_start;
            .chat_msg_right {
              width: 100%;
              .cnat_admin_name {
                margin: 0 0 0.4rem;
                h4 {
                  font-size: 1.2rem;
                  color: $msg-date;
                  @include fw_700;
                  line-height: 1.6rem;
                }
              }
            }
            .chat_text_msg {
              box-shadow: 0px 4px 40px 0px #24498914;
              @extend .fw_500;
              border-top-left-radius: 0px;
              @include position_relative;
              .link_btn {
                color: $primary-color;
                @include fw_700;
                text-decoration: underline;
              }
              a:not(.link_btn) {
                color: $primary-color;
                text-decoration: underline;
              }
              span {
                @include fs_16;
                @extend .fw_500;
              }
            }
            &.other_file {
              > div {
                > div {
                  > .media_img {
                    object-fit: cover;
                    max-height: 30rem;
                    max-width: 30rem;
                    width: auto;
                    height: auto;
                    border-radius: 1rem;
                  }
                }
              }
              p {
                border: 0;
                background: 0;
                @include d_flex;
                @extend .align_items_center;
                gap: 1rem;
                padding: 0;
                img {
                  width: 3.2rem;
                }
              }
            }
            > div.position_relative {
              div.text_right {
                text-align: left;
              }
            }
            &.my_message {
              .react_emoji {
                bottom: 2rem;
                right: -0.5rem;
                left: auto;
              }
            }
            &.other_file {
              .chat_msg_right {
                .message_main {
                  .chat_text_msg {
                    span {
                      &:empty {
                        display: none !important;
                      }
                    }
                    .emoji_reaction {
                      position: unset;
                    }
                  }
                }
              }
            }
          }
          &.my_file {
            p {
              border: 0;
              color: $black-color;
              @extend .d_flex_align;
              padding: 0;
              img {
                margin-right: 1rem;
                width: 3.2rem;
                height: 3.2rem;
                vertical-align: middle;
              }
            }
            img {
              width: 25rem;
            }
            > div {
              > div {
                > img {
                  object-fit: cover;
                  max-height: 30rem;
                  max-width: 30rem;
                  width: auto;
                  height: auto;
                  border-radius: 1rem;
                }
              }
            }
            &.other_message {
              > div {
                > div {
                  > img {
                    object-fit: cover;
                    max-height: 30rem;
                    max-width: 30rem;
                    width: auto;
                    height: auto;
                    border-radius: 1rem;
                  }
                }
              }
            }
            .emoji_rection_main {
              margin-left: 15px;
            }
          }
          &.my_message_left {
            @extend .justify_content_start;
            @extend .align_items_start;
            max-width: 50%;
          }
          .voice_msg {
            background-color: #e8f1fd;
            border-radius: 2.4rem;
            padding: 0.8rem 1.2rem;
            max-width: max-content;
            margin-bottom: 0.5rem;
            @include position_relative;
            .volume_box {
              @include d_flex;
              @extend .align_items_center;
              gap: 1.2rem;
              p {
                border: 0;
                padding: 0;
                @extend .montserrat_regular;
                @extend .fs_14;
                color: $black-color;
                opacity: 0.6;
                background: transparent;
                white-space: nowrap;
                img {
                  width: 4rem;
                  height: 4rem;
                  @extend .img_object_center;
                }
              }
              .wave {
                max-width: 17rem;
              }
              > img {
                width: auto;
              }
            }
          }
          &.voice_msg {
            .volume_box {
              p {
                background-color: transparent;
                min-width: 5rem;
              }
            }
          }
          p {
            img {
              width: 2rem;
              max-width: 100%;
              @extend .img_object_center;
            }
          }
          .emoji_rection_main {
            @include d_flex;
            margin: 3px 4px -24px 0;
          }
          .react_emoji {
            border: 0.1rem solid $border-color;
            border-radius: 50px;
            min-width: 2.8rem;
            height: 2.8rem;
            @include d_flex_center;
            z-index: 1;
            background: $white-color;
            margin: 0px 4px 0px 0;
            @include position_relative;
            @extend .trans;
            width: max-content;
            &:hover {
              border-color: $black-color;
              background: $reaction_back;
              z-index: 2;
            }
            span {
              &.default_emoji {
                margin-top: 0;
                padding: 0 0.6rem;
                font-size: 1.2rem;
                @include d_flex_center;
                .reaction_count {
                  @extend .fs_12;
                  margin: 0 0 0 0.3rem;
                  @extend .fw_500;
                  color: $msg-date;
                }
              }
            }
            .reaction_popup {
              box-shadow: 0 25px 25px rgba(0, 0, 0, 0.15);
              background: $white-color;
              padding: 1rem 0;
              @include position_absolute;
              z-index: 9;
              min-width: 18rem;
              top: calc(100% + 1.5rem);
              left: 50%;
              right: 0;
              margin: 0 auto;
              transform: translate(-50%, 0px);
              @extend .trans;
              border: 0.1rem solid $border-color;
              border-radius: 0.4rem;
              opacity: 0;
              visibility: hidden;
              &:before {
                content: '';
                @include position_absolute;
                width: 1.5rem;
                height: 1.5rem;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: calc(100% - 0.7rem);
                background: $white-color;
                transform: rotate(45deg);
                pointer-events: none;
                border-top: 0.1rem solid $border-color;
                border-left: 0.1rem solid $border-color;
              }

              ul {
                padding: 0rem 1rem;
                max-height: 10rem;
                overflow: auto;
                background: $white-color;
                @include position_relative;
                li {
                  @include d_flex;
                  @extend .align_items_center;
                  &:not(:last-child) {
                    padding: 0 0 1rem;
                  }
                  .reaction_profile {
                    width: 2.4rem;
                    height: 2.4rem;
                    margin-right: 0.6rem;
                    @extend .radius_round;
                    @include over_hidden;
                    flex-shrink: 0;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  span {
                    margin: 0;
                    font-size: 1.2rem;
                    @extend .fw_500;
                    color: $tab-text;
                    line-height: 1.7rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 12.6rem;
                    width: 100%;
                  }
                }
              }
            }
            &:hover {
              .reaction_popup {
                opacity: 1;
                visibility: visible;
              }
            }
            &.my_rection {
              border-color: $primary-color;
              .default_emoji {
                .reaction_count {
                  color: $primary-color;
                }
              }
            }
          }
          .file_class {
            border: 1px solid hsla(0, 0%, 80%, 0.545);
            border-radius: 1rem;
            padding: 1.4rem 1.5rem;
            width: max-content;
            @include position_relative;
            p {
              box-shadow: none;
              @extend .fw_500;
            }
          }
          .media_img_wrapper {
            .emoji_rection_main {
              margin: -12px 4px 0px 18px;
            }
          }
          .content_wrapper_video {
            .emoji_rection_main {
              margin: 3px 4px -15px 10px;
            }
          }
          &.myquote_msg {
            .quote_msg_content {
              background: $secondary-color;
              border-top-right-radius: 0px;
              box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.1490196078);
              .quote_msg_main {
                background-color: $quote_bg;
                .quote_text_msg {
                  p {
                    color: $white-color;
                  }
                }
                .replay_file_msg {
                  img {
                    width: 3.2rem;
                    height: 3.2rem;
                  }
                  span {
                    color: $white-color;
                    @extend .fw_500;
                  }
                }
                .replay_content_msg {
                  .content_wrapper_video {
                    .content_video_inner {
                      .content_dec {
                        h4,
                        span {
                          color: $white-color;
                        }
                      }
                      .read-more-text {
                        span {
                          color: $white-color;
                        }
                      }
                    }
                  }
                }
                .quote_msg_footer {
                  h4,
                  span {
                    color: $white-color;
                  }
                }
              }
              .chat_text_msg {
                border-radius: 0px;
              }
            }
            .quote_msg_wrap {
              @include d_flex;
              @extend .flex_direction_column;
              @extend .align_items_end;
              width: 100%;
            }
          }
          &.otherquote_msg {
            .quote_msg_content {
              border-top-left-radius: 0px;
              box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.0784313725);
              background-color: $white-color;
              border: 1px solid $border-color;
              .quote_msg_main {
                background: $quote-white-bg;
              }
            }
          }
          .quote_msg_content {
            border-radius: 0.8rem;
            padding: 1.2rem;
            max-width: 80%;
            width: fit-content;
            @include position_relative;
            .quote_msg_main {
              border-radius: 0.8rem;
              padding: 1.2rem;
              margin: 0 0 0.8rem;
              .quote_text_msg {
                p {
                  font-size: 1.6rem;
                  line-height: 2.2rem;
                  letter-spacing: 0.5px;
                  @include fw_400;
                }
              }
              .quote_media_msg {
                img {
                  height: 12.5rem;
                  margin: 0 auto;
                }
              }
              .quote_msg_footer {
                margin: 1.2rem 0 0;
                h4 {
                  font-size: 1.2rem;
                  line-height: 1.6rem;
                  letter-spacing: 0.5px;
                  @include fw_700;
                }
                > span {
                  display: block;
                  margin: 0.4rem 0 0;
                  font-size: 1rem;
                  line-height: 1.3rem;
                  letter-spacing: 0.5px;
                }
              }
              .replay_content_msg {
                min-width: 395px;
                .content_wrapper_video {
                  max-width: 100%;
                  width: 100%;
                  border: 0px;
                  box-shadow: none;
                  .content_video_inner {
                    .content_dec {
                      padding: 0;
                    }
                    .read-more-text {
                      border: none;
                      width: 100%;
                      padding: 1.2rem 0 0;
                    }
                  }
                }
              }
              .replay_audio_msg {
                .voice_msg {
                  margin: 0;
                  border-radius: 0.6rem;
                  padding: 0.3rem 1rem;
                }
              }
            }
            .chat_text_msg {
              border-radius: 0px;
              border: none;
              padding: 0;
              box-shadow: none;
            }
          }
        }
        .user_left {
          @include d_flex;
          .user_left_wrapper {
            border-radius: 4rem;
            border: 0.1rem solid $border-color;
            background: $white-color;
            box-shadow: 0px 0.4rem 4rem 0px rgba(36, 73, 137, 0.08);
            @extend .text_center;
            display: inline-flex;
            margin: 0 auto;
            padding: 1.6rem 2rem;
            max-width: 56rem;

            p {
              font-size: 1.6rem;
              @extend .fw_600;
              color: $msg-date;
            }
          }
        }
        .content_wrapper_video {
          box-shadow: 0px 0.4rem 4rem 0px rgba(36, 73, 137, 0.08);
          border: 0.1rem solid $border-color;
          border-radius: 0.8rem;
          max-width: 26.2rem;
          width: 100%;
          @include position_relative;
          .content_video_inner {
            > span {
              img {
                width: 100%;
                @extend .m_0;
              }
              &.msg_content_pic {
                border-radius: 0.8rem 0.8rem 0 0;
                @include over_hidden;
                display: block;
                img {
                  width: 100%;
                  max-width: 35rem;
                  @extend .img_object_center;
                }
              }
            }
            .content_video {
              .content_dec {
                padding: 2rem;
                > h4 {
                  max-width: 18rem;
                  @extend .text_ellipsis;
                  @extend .white_space_nowrap;
                  @include over_hidden;
                }
                > div {
                  .content_text {
                    color: $primary-color;
                    @extend .fs_12;
                    @include fw_700;
                  }
                  .vid_icon {
                    @extend .fs_14;
                    color: $form-input-color;
                    padding-right: 6rem;
                  }
                }
              }
              p {
                padding: 2.5rem 2rem 1.5rem;
                border-top: 0.1rem solid $border-color;
                @include fs_16;
                @extend .fw_500;
                color: $msg-date;
                margin: 0;
                width: 100%;
                max-width: 35rem;
                word-break: break-word;
              }
            }
          }
        }
        .chat_msg_time {
          font-size: 1.2rem;
          @extend .montserrat_regular;
          line-height: 1.4rem;
          letter-spacing: 0.06px;
          color: $msg-date;
          margin-bottom: 0.5rem;
          @include d_flex;
          @extend .align_items_center;
          @extend .justify_content_start;
          letter-spacing: 0.5px;
          > img {
            margin-left: 0.5rem;
            @include position_relative;
            &.mesg_tick {
              width: auto;
              max-width: 1.6rem;
            }
          }
        }
        .welcome_message {
          @include d_flex;
          @extend .align_items_center;
          @extend .flex_direction_column;
          margin: 0 0 2.5rem;
          .message_main {
            max-width: 70%;
            background: $dark_gray;
            border: 1px solid $white-color;
            border-radius: 0 4px 4px 4px;
            padding: 1.6rem;
            overflow-wrap: break-word;
            .chat_text_msg {
              @extend .text_center;
            }
            p {
              span {
                color: $white-color;
                font-style: italic;
                font-size: 1.6rem;
                line-height: 140%;
                @extend .fw_500;
              }
            }
          }
          .file_class {
            border: 1px solid hsla(0, 0%, 80%, 0.545);
            border-radius: 1rem;
            padding: 1.4rem 1.5rem;
            width: max-content;
            p {
              @include d_flex;
              @extend .align_items_center;
              @extend .fw_500;
              img {
                margin-right: 1rem;
                width: 3.2rem;
                height: 3.2rem;
                vertical-align: middle;
              }
            }
          }
        }
      }
      .emoji-picker-wrapper {
        border: 0.1rem solid $border-color;
        box-shadow: 0px 4px 40px 0px #24498914;
        background-color: $white-color;
        min-width: 20.8rem;
        border-top-left-radius: 0px;
        padding: 1.3rem 2rem;
        z-index: 1;
        @include position_absolute;
        bottom: -4rem;
        left: 55%;
        @extend .radius_8;
        .emoji-picker {
          .icon {
            line-height: 2.2rem;
            @include trans;
            font-size: 1.6rem;
            @include position_relative;
            &:before {
              content: '';
              @include position_absolute;
              background: $primary-color;
              width: 0rem;
              height: 2px;
              left: 0;
              right: 0;
              margin: 0 auto;
              top: calc(100% + 3px);
              @include trans;
            }
            &:hover,
            &.emoji_active {
              @include trans;
              &:before {
                width: 1.6rem;
              }
            }
          }
        }
      }
    }
    .chat_footer {
      background-color: $white-color;
      z-index: 111;
      @include position_relative;
      .chat_footer_box {
        border-top: 0.1rem solid $border-color;
        padding: 2.5rem;
        .chat_footer_box_inner {
          column-gap: 1.2rem;
        }
        .footer_icon:has(.disable) {
          cursor: no-drop;
        }
        .circle_box {
          @extend .msg_border_icon;
          @include trans;
          &.disable {
            pointer-events: none;
          }
          &:hover {
            background-color: $dark-blue;
            @include trans;
            img {
              filter: brightness(21);
              @include trans;
            }
          }
        }
        .chat_input {
          .message_textarea {
            @include d_flex_center;
            .message_textarea_field {
              font-size: 1.6rem;
              @extend .montserrat_regular;
              line-height: 2.2rem;
              color: $tab-text;
              background-color: $search-input;
              padding: 1.6rem;
              min-width: 32.2rem;
              border: none;
              border-radius: 30px;
              max-height: 150px;
              overflow: auto;
              scrollbar-width: none;
              &:active,
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      &.disable {
        opacity: 0.2;
        z-index: -1;
      }
      .voice_msg_box {
        padding: 3.2rem;
        background-color: $search-input;
        position: inherit;
        width: 100%;
        .voice_text {
          margin-bottom: 2.5rem;
          p {
            @extend .montserrat_semibold;
            @include fs_16;
            line-height: 1.9rem;
            color: $black-color;
          }
          span {
            @extend .msg_border_icon;
            background-color: $white-color;
            img {
              width: 2.4rem;
              height: 2.4rem;
            }
          }
        }
        .sound_icon {
          @extend .text_center;
          span {
            @include position_relative;
            @include d_flex_center;

            z-index: 0;
            &::after {
              content: '';
              @include position_absolute;
              width: 8.9rem;
              height: 8.9rem;
              -webkit-animation-delay: 0s;
              animation-delay: 0s;
              -webkit-animation: pulsate1 4s;
              animation: pulsate1 4s;
              -webkit-animation-direction: forwards;
              animation-direction: forwards;
              -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
              border-radius: 50%;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @include apply_bgcolor(Light_blue_clr);
              opacity: 0.3;
              z-index: -1;
            }
            @-webkit-keyframes pulsate1 {
              0% {
                -webkit-transform: translate(-50%, -50%) scale(0.6);
                transform: translate(-50%, -50%) scale(0.6);
                opacity: 1;
                opacity: 0.3;
              }
              50% {
                -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
                opacity: 0.3;
              }
              80% {
                -webkit-transform: translate(-50%, -50%) scale(1.1);
                transform: translate(-50%, -50%) scale(1.1);
                opacity: 0.4;
              }
              100% {
                -webkit-transform: translate(-50%, -50%) scale(1.2);
                transform: translate(-50%, -50%) scale(1.2);
                opacity: 0.2;
              }
            }

            @keyframes pulsate1 {
              0% {
                -webkit-transform: translate(-50%, -50%) scale(0.6);
                transform: translate(-50%, -50%) scale(0.6);
                opacity: 0.3;
              }
              50% {
                -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
                opacity: 0.3;
              }
              80% {
                -webkit-transform: translate(-50%, -50%) scale(1.1);
                transform: translate(-50%, -50%) scale(1.1);
                opacity: 0.4;
              }
              100% {
                -webkit-transform: translate(-50%, -50%) scale(1.2);
                transform: translate(-50%, -50%) scale(1.2);
                opacity: 0.2;
              }
            }
          }
        }
      }
      &.chat_footer_opacity {
        pointer-events: none;
        padding: 5rem 0;
        border-top: 0.1rem solid #e0e0e0;
        .chat_box {
          padding: 0;
          .chatbox_area {
            padding: 0;
          }
        }
        .chat_footer_box {
          opacity: 0.2;
        }
      }
      .shared_msg_box {
        bottom: 100%;
        top: auto;
        .landscape_view img {
          max-width: 15rem;
          object-fit: cover;
        }
      }
    }
  }
  &.expand_box {
    .comman_lay {
      border-right: 0;
    }
    .chat_box,
    .content_share {
      @include d_none;
    }
    .comman_lay {
      .chat_listing {
        .chat_info {
          .chat_profile {
            p {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .content_share {
    padding: 0;
    @extend .d_grid;
    grid-template-rows: repeat(3, 1fr);
    .content_box {
      padding: 0 2.5rem;
      border-bottom: 0.1rem solid $border-color;
      .empty_box {
        @extend .d_flex_align;
        height: 100%;
        @extend .justify_content_center;
        p {
          @extend .text_center;
          color: $primary-color;
          font-size: 1.6rem;
          @extend .montserrat_semibold;
        }
      }
      h2 {
        padding-inline: 0;
      }
      .list_box {
        margin-bottom: 1.5rem;
        span {
          @extend .msg_border_icon;
          img {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
        .text_info {
          margin-left: 1rem;
          p {
            color: $black-color;
            @include fs_16;
            @extend .montserrat_medium;
            @extend .text_capitalize;
          }
          a {
            color: $primary-color;
            line-height: normal;
            border-bottom: 0.1rem solid;
            font-size: 1.4rem;
            @extend .montserrat_regular;
          }
        }
        &.list_box_project {
          span {
            background-color: $project_bg;
            img {
              width: auto;
              height: auto;
            }
          }
        }
      }
    }
  }
  &.chat_wrapper_area {
    @include d_block;
    padding-top: 9rem;
    .chat_listing_inner {
      height: 71.3rem;
      overflow: auto;
    }
  }
}
.image_full_view {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1001;
  .image_full_view_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    .image_full_view_header {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      position: sticky;
      top: 0;
      .image_full_title {
        width: calc(100% - 30px);
        text-align: center;
        font:
          normal 500 2.2rem/2.4rem 'Mulish',
          sans-serif;
        color: #fff;
      }
      .image_full_action {
        display: flex;
        align-items: center;
        .u_plain-icon {
          cursor: pointer;
          line-height: 0;
          display: block;
          color: #4e525f;
          position: relative;
        }

        .u_mr-10 {
          margin-right: 10px;
        }
        .close-icon {
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          display: flex !important;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          cursor: pointer;
        }
        .u_cp {
          cursor: pointer;
        }
        .u_ml-10,
        .u_mx-10 {
          margin-left: 10px;
        }
      }
    }
    .image_full_view-body {
      margin: auto;
      width: calc(100% - 112px);
      height: 100%;
      min-height: 1px;
      text-align: center;
      .image_full_popup {
        text-align: center;
        margin: auto;
        display: inline-flex;
        height: 100%;
        align-items: center;
        .u_image-zoom {
          display: inline-flex;
          overflow: hidden;
          width: 100%;
          height: 100%;
          margin: auto;
          img {
            margin: auto;
            height: auto;
            max-height: 100%;
          }
        }
      }
    }
    .u_thumbnails-file {
      height: 100px;
    }
  }
}
.voice_msg {
  background-color: #e8f1fd;
  border-radius: 2.4rem;
  padding: 0.8rem 1.2rem;
  max-width: 32.2rem;
  .volume_box {
    @include d_flex;
    @extend .align_items_center;
    gap: 1.2rem;
    p {
      border: 0;
      padding: 0;
      @extend .montserrat_regular;
      @extend .fs_14;
      color: $black-color;
      opacity: 0.6;
      background: transparent;
      height: 4rem;
      width: 4rem;
      @include d_flex;
      @extend .align_items_center;
      img {
        width: 4rem;
        height: 4rem;
        @extend .img_object_center;
        background: $white-color;
        border-radius: 10rem;
      }
    }
    .pauseIcon {
      width: 4rem;
      height: 4rem;
      path {
        width: 4rem;
        height: 4rem;
      }
    }
    .wave {
      max-width: 17rem;
    }
    .duration {
      max-width: 5rem;
    }
  }
}
.shared_msg_box {
  padding: 3.2rem;
  background-color: $search-input;
  @include position_absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;

  .close_btn {
    @include position_absolute;
    right: 3.2rem;
    span {
      @extend .msg_border_icon;
      background-color: $white-color;
      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  p {
    margin: 1rem 0;
    @extend .montserrat_semibold;
    @include fs_16;
    color: $black-color;
  }
  span {
    @extend .montserrat_regular;
    color: $light-gray;
    @include fs_16;
  }
  .message,
  .youtube_info {
    @include d_none;
  }
  &.potrait_view {
    max-width: 26.1rem;
    box-sizing: border-box;
    padding: 0;
    position: unset;
    background-color: $white-color;
    .close_btn {
      @include d_none;
    }
    .landscape_view {
      background-color: $white-color;
      border: 0.1rem solid $border-color;
      @include over_hidden;
      min-height: 30.7rem;
      border-radius: 0.8rem;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .big_img {
        width: 100%;
      }
      p {
        @include d_none;
      }
      span {
        @extend .montserrat_semibold;
        font-size: 1.8rem;
        color: $black-color;
        @include d_block;
        padding: 2.3rem 1.8rem;
      }
      .youtube_info {
        padding-inline: 1.8rem;
        margin-bottom: 2.5rem;
        @include d_flex;
        a {
          @extend .montserrat_bold;
          color: $primary-color;
          @extend .fs_14;
        }
        span {
          margin-left: 1.6rem;
          padding: 0;
          img {
            margin-right: 0.7rem;
            @extend .d_inline_block;
            vertical-align: middle;
            width: 2.2rem;
          }
          @extend .montserrat_regular;
          color: $black-color;
          @extend .fs_14;
        }
      }
    }
    .message {
      min-width: 26.1rem;
      @include d_block;
      p {
        @include d_block;
        margin: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0.8rem;
      }
    }
  }
}

.chat_msg_replay {
  max-width: 480px;
  width: 100%;
  margin: 0 auto 1.2rem;
  @include d_flex;
  .replay_close {
    width: 4.4rem;
    height: 4.4rem;
    @include d_flex;
    margin-left: 1.2rem;
    @extend .cursor_pointer;
    flex-shrink: 0;
    img {
      margin: auto;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

.replay_msg_main {
  background-color: $reaction_back;
  padding: 1.2rem;
  border-radius: 1rem;
  flex: 1;
  .replay_text_msg {
    max-height: 15rem;
    overflow: auto;
    scrollbar-width: thin;
    p {
      font-size: 1.4rem;
      letter-spacing: 0.5px;
      line-height: 1.8rem;
      color: rgba(0, 0, 0, 0.8);
      @include fw_400;
    }
  }
  .replay_media_msg {
    img {
      max-width: 150px;
      width: 100%;
      border-radius: 5px;
    }
  }
  .chat_user_name {
    margin: 1.2rem 0 0;
    span {
      letter-spacing: 0.5px;
      color: $msg-date;
      display: block;
    }
    .ct_user_name {
      margin: 0 0 0.4rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      @include fw_700;
    }
    .ct_timezone {
      font-size: 1rem;
      line-height: 1.3rem;
      @include fw_400;
    }
  }
}

.replay_audio_msg {
  .voice_msg {
    max-width: 100%;
    background: transparent;
    padding: 0;
    .audio_type_icon {
      margin-right: auto;
    }
    .volume {
      margin-left: auto;
    }
  }
}

.replay_file_msg {
  @include d_flex;
  @extend .align_items_center;
  img {
    margin-right: 1rem;
  }
  span {
    font-size: 1.6rem;
    line-height: 2.2rem;
    @include fw_400;
    letter-spacing: 0.5px;
  }
}

.msg_preview_pic {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  img {
    object-fit: cover;
    max-height: 15rem;
    max-width: 15rem;
    border-radius: 1rem;
    padding: 1rem;
    margin: 0 auto;
  }
}

.replay_content_msg {
  .content_video_inner {
    .msg_content_pic {
      width: 100%;
      height: 70px;
      overflow: hidden;
      border-radius: 8px 8px 0 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content_video {
      margin-top: 1.2rem;
      .content_dec {
        @include d_flex;
        @extend .align_items_center;
        @extend .justify_content_between;
        h4 {
          @extend .fs_18;
          @extend .fw_600;
          color: $black-color;
          line-height: 2.2rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 300px;
          width: 100%;
        }
        span {
          color: $primary-color;
          font-size: 1.2rem;
          @include fw_700;
          line-height: 2rem;
        }
      }
      .read-more-text {
        margin: 1.2rem 0 0;
        span {
          font-size: 1.6rem;
          @extend .fw_500;
          letter-spacing: 0.5px;
          line-height: 2.2rem;
          color: $msg-date;
        }
      }
    }
  }
}

.reaction_pop_main {
  display: none;
}

.isbtndesable {
  pointer-events: none;
  cursor: not-allowed;
}
