.custom-select-placeholder {
  display: flex;
  align-items: center;
  position: relative;
  color: #1373e7;
}

.custom-select-badge {
  background-color: #1373e7;
  color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.custom-select-i-placeholder {
  color: #686f7d;
}

.custom-select-i-placeholder-focused {
  color: #1373e7;
}
