html {
  @include global_styles; 
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

body {
  @extend .montserrat_regular;
  @extend .fs_14;
  line-height: 1.8rem;
  color: $black-color;
  background: $white-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  scrollbar-width: thin;
  text-rendering: optimizeLegibility;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  @extend .cursor_pointer;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input[type="password"] {
  @extend .fontello_star;
  font-size: 12px !important;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  speak: never;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.3rem;
}

input:not([type="radio"], [type="checkbox"]) {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @extend .montserrat_regular;
}
input {
  outline: 0;
  box-shadow: none !important;
  scrollbar-width: thin;
  &::placeholder {
    color: $form-input-color;
  }
}

body {
  -webkit-text-stroke: 0px !important;
  -webkit-font-smoothing: antialiased !important;
  scrollbar-width: thin;
}
a,
p,
span,
h1,
h2,
h3,
h4,
h5 {
  -webkit-text-stroke: 0px !important;
  -webkit-font-smoothing: antialiased !important;
}

ul,
li,
select {
  scrollbar-width: thin;
}

::selection {
  text-shadow: none;
  background: rgba($dark-blue, 0.3);
}
.clear {
  clear: both;
  height: 0;
  overflow: hidden;
  width: auto;
  @include d_block;
  float: none !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: $form-input-active-bg;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $lighten-dark-blue;
  border-radius: 10px;
}


button {
  background-color: transparent;
  border: none;
}

.nodata_found {
  width: 100%;
  max-width: 30.4rem;
  @extend .m_auto;
  @extend .position_sticky;
  margin-top: 20%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .search_page {
    width: 12rem;
    height: 12rem;
    @extend .radius_round;
    @include d_flex_center;
    @extend .m_auto;
    background: $form-input-active-bg;
    margin-bottom: 2.4rem;
  }
  h5 {
    @extend .fs_24;
    @extend .fw_600;
    @extend .text_center;
    line-height: 110%;
    color: $tab-head;
    margin-bottom: 1.6rem;
  }
  .find_page_content {
    @include fs_16;
    @include fw_400;
    @extend .text_center;
    line-height: 140%;
    color: $light-gray;
  }
}
