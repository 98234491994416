.content_page {
  .add_mentor_header {
    position: inherit;
    padding-left: 0;
  }
  .add_mentors_user {
    padding-left: 14rem;
    padding-top: 1rem;
    .add_events_main {
      padding: 0 0 5rem;
      .add_events {
        margin: 0;
        padding: 0;
        .form-wrapper {
          form {
            .form-steps {
              .main_picture_upload {
                .upload_photo {
                  &.upload_pic {
                    min-width: 22rem;
                    padding: 1rem 1.2rem;
                    &.Upload {
                      min-width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .add_mentor_footer {
      margin-left: 0;
    }
  }
  .i_recipients_popup {
    ul {
      &.active_tabs {
        li {
          min-width: auto;
        }
      }
    }
  }
  .table {
    &.training_list {
      &.defalut_training_table {
        margin-bottom: 0;
        min-width: 110rem;
      }
      tbody {
        tr {
          .main_user_profile {
            .preview_on_hover {
              visibility: hidden;
            }
          }
          td {
            border-top: 0;
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
      td {
        &:nth-child(2) {
          width: 17%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 17%;
        }
        &:nth-child(4) {
          width: 6%;
        }
      }
      &.defalut_training_table {
        tr {
          td {
            .name_toolkit {
              .full_username {
                top: -2.5rem;
              }
            }
            &:first-child {
              width: 17%;
            }
            &:nth-child(2) {
              width: 12%;
            }
            &:nth-child(4) {
              width: 12%;
            }
            &:nth-child(5) {
              width: 16%;
            }
            &:nth-child(6) {
              width: 10%;
            }
            &:nth-child(7) {
              width: 6%;
            }
          }
        }
      }
    }
  }
  .table_wrapper {
    .table_responsive {
      .table_wrapper {
        padding: 0;
        margin-top: 0;
      }
      &.is_Default_Training {
        @include over_hidden;
        height: 100%;
        overflow-y: visible;
        overflow-x: auto;
        .table_wrapper {
          .table_responsive {
            @include over_hidden;
            .project_list {
              min-width: 110rem;
            }
          }
        }
        .defalut_training_table {
          border-radius: 0.8rem;
          border: 0.1rem solid $primary-color;
          background: $table-hover;
          margin-bottom: 3rem;
        }
      }
    }
    &:nth-child(3) {
      .table_responsive {
        .table {
          &.content_list {
            tr {
              &:nth-last-child(-n + 2) {
                .preview_on_hover {
                  bottom: unset;
                }
              }
              &:nth-last-child(-n + 3) {
                .preview_on_hover {
                  bottom: unset;
                }
              }
              &:nth-last-child(-n + 4) {
                .preview_on_hover {
                  bottom: unset;
                }
              }
              &.last_three {
                .preview_on_hover {
                  bottom: unset;
                }
              }
            }
          }
        }
      }
    }
    .table_responsive {
      .table {
        &.content_list {
          td {
            &:nth-child(2) {
              width: 15%;
            }
            &:nth-child(4) {
              width: 15%;
            }
            &:nth-child(5) {
              width: 16%;
            }
            &:nth-child(6) {
              width: 5%;
            }
            .name_toolkit {
              .user_name {
                @include d_block;
              }
              .full_username {
                @extend .white_space_nowrap;
              }
            }
            .name_toolkit {
              .user_name {
                max-width: 21rem;
                @extend .d_inline_block;
                &.category_type {
                  color: $dark-blue;
                  @include fs_16;
                  line-height: 140%;
                  @include d_flex;
                  @extend .align_items_center;
                  border: 0;
                  cursor: default;
                  img {
                    vertical-align: middle;
                  }
                  .category_text {
                    line-height: 1.6rem;
                    font-size: 1.7rem;
                    @include d_flex;
                  }
                  &:hover {
                    .category_text {
                      @extend .fw_500;
                    }
                  }
                }
              }
            }
            .status_tab {
              &.school_inst_tab {
                max-width: 30rem;
              }
            }
          }
        }
        &.project_list {
          tr {
            &:nth-last-child(-n + 3) {
              .preview_on_hover {
                bottom: auto;
              }
            }
            &:nth-last-child(-n + 4) {
              .preview_on_hover {
                bottom: 0;
              }
            }
            &.first_one {
              .preview_on_hover {
                bottom: 0;
              }
              &:first-child {
                .preview_on_hover {
                  bottom: auto;
                }
              }
              &:nth-child(3) {
                .preview_on_hover {
                  bottom: auto;
                }
              }
              &:nth-child(2) {
                .preview_on_hover {
                  bottom: auto;
                }
              }
            }
            &.last_three {
              .preview_on_hover {
                bottom: 0;
              }
            }
            &.last_three.first_one {
              .preview_on_hover {
                bottom: auto;
                @include position_fixed;
              }
            }
          }
          tr {
            td {
              &:nth-child(4) {
                width: 12%;
              }
              &:nth-child(6) {
                width: 10%;
              }
              &:nth-child(7) {
                width: 6%;
              }
            }
          }
        }
        &.content_training_list {
          tr {
            td {
              &:first-child {
                width: 5%;
              }
              &:nth-child(2) {
                width: 15%;
              }
              .status_tab {
                &.school_inst_tab {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .css-1nmdiq5-menu {
    .css-1n6sfyn-MenuList {
      .css-1iozq7c-option {
        padding: 0;
      }
      .css-vuneft-option {
        padding: 0;
      }
    }
  }
  #createContantForm {
    .main_picture_upload {
      .picture_wrapper {
        .profile_img,
        .upload_content_thumbnail {
          img {
            object-fit: none;
          }
          &.edit_content_thumbnail {
            img {
              @extend .img_object_center;
            }
          }
        }
      }
    }
  }
  .table_wrapper {
    + .table_wrapper {
      .table_responsive {
        min-height: auto;
      }
    }
  }
  .form_small_container {
    .form-wrapper {
      form {
        .form-steps {
          .form_group {
            .main_picture_upload {
              .dragBox {
                max-width: 29.5rem;
              }
            }
          }
        }
      }
    }
  }
  .table_wrapper {
    .table_responsive {
      &.content_list_table_responsive {
        height: auto;
      }
    }
  }

  .inner_wrap {
    .all_details {
      .user_que {
      }
    }
  }
}
.main_user_profile {
  &:hover {
    .preview_on_hover {
      display: block !important;
    }
  }
}
.preview_on_hover {
  padding: 1.6rem;
  border-radius: 2rem;
  background: $white-color;
  box-shadow: 0rem 0rem 3rem 0rem rgba(116, 116, 116, 0.15);
  width: 30.8rem;
  @include w_100;
  @include position_absolute;
  z-index: 2;
  @include d_none;
  .inner_wrap {
    .all_details {
      .user_que {
        height: auto;
      }
    }
  }
}
.inner_wrap {
  border-radius: 0.8rem;
  border: 0.1rem solid $border-color;
  background: $white-color;
  box-shadow: 0.8rem 0.4rem 2.5rem 0rem rgba(82, 99, 116, 0.04);
  @include over_hidden;
  .top_img {
    width: 27.6rem;
    height: 18rem;
    @include over_hidden;
    @extend .img_object_center;
    img {
      @include w_100;
      height: 100%;
      border-radius: unset;
    }
  }
  .all_details {
    margin-top: 1.6rem;
    max-width: 22rem;
    @include w_100;
    margin: 1.6rem auto;
    white-space: break-spaces;
    @include media_query(extraLarge) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .main_tital {
      color: $primary-color;
      @include fw_700;
      @extend .fs_12;
    }
    .user_que {
      color: $form-lable-color;
      @include fw_700;
      @extend .fs_18;
      margin-top: 0.8rem;
      height: 11rem;
      overflow: auto;
    }
  }
}
.required_field_star {
  color: #ff0000;
}
