.composer-container {
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 6px;
  background-color: #f9f9fc;

  .relative-wrapper {
    position: relative;

    .composer-textarea {
      width: 100%;
      min-height: 112px;
      padding: 14px 16px;
      border-radius: 6px;
      border: 1px solid #e3eaf2;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      background-color: #fff;
      resize: none;

      &.has-text {
        color: #282828;
      }
    }

    .character-validation {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: #b2c6e2;
      position: absolute;
      right: 12px;
      bottom: 15px;
    }
  }

  &.height-auto {
    height: auto;
  }
}

.composer-textarea::placeholder {
  color: #686f7d;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.composer-textarea:focus {
  outline: none;
}

.multi-choice-options-container {
  margin-top: 16px;

  .multi-choice-option-box {
    margin-top: 16px;

    .option-label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #282828;
      font-family: 'Montserrat', sans-serif;
    }

    .input-and-delete-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;

      .option-input-box {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        border-radius: 6px;
        border: 1px solid #e3eaf2;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #282828;
        background-color: #fff;
        box-sizing: border-box;

        &::placeholder {
          color: #686f7d;
        }
      }

      .delete-option-button {
        background-color: #fff;
        margin-left: 8px;
        height: 48px;
        width: 50px;
        border: 1px solid #e3eaf2;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        .delete-option-icon {
          color: #686f7d;
        }
      }
    }
  }

  .add-new-option-button-box {
    margin-top: 16px;
  }

  .add-new-option-button {
    margin-left: auto;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #1373e7;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;
    color: #1373e7;
    font-family: 'Montserrat_semibold', sans-serif;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
  }
}

.character-validation.exceeded {
  color: #e33b32 !important;
}
