.modal_comman {
  @extend .modal_common;
  &::after {
    @extend .modal_common_after;
  }

  .delete_modal {
    display: none;
    @include w_100;
    max-width: 474px;
    padding: 3.2rem;
    padding-bottom: 3.1rem;
    @extend .text_center;
    border-radius: 2rem;
    background: $white-color;
    box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
    @extend .position_fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    &.active {
      @include d_block;
    }

    .close_btn {
      width: 4rem;
      height: 4rem;
      @include position_absolute;
      top: 1.9rem;
      right: 2rem;
      @extend .cursor_pointer;
    }

    h2 {
      @extend .fs_18;
      @extend .montserrat_bold;
      @extend .m_auto;
      @extend .text_center;
      max-width: 30.2rem;
      color: $black-color;
      line-height: 2.1rem;
      margin-bottom: 2.7rem;
    }

    .user_pic {
      width: 6.55rem;
      height: 6.55rem;
      @include d_block;
      @extend .m_auto;
      margin-bottom: 0.65rem;
      padding: 0;

      img {
        @extend .radius_round;
        @include w_100;
        height: 100%;
        @extend .img_object_center;
        padding: 0;
      }
    }

    .user_name {
      @include d_block;
      color: $black-color;
      @extend .text_center;
      @extend .fs_14;
      @include fw_700;
      line-height: 140%;
      margin-bottom: 2.4rem;
    }

    .delete_msg {
      color: $black-color;
      @extend .text_center;
      @extend .fs_14;
      @include fw_400;
      line-height: 140%;
      margin-bottom: 3.2rem;

      .do_progress {
        @include d_block;
        @include fw_700;
        padding-top: 2.4rem;
      }
    }

    .delete_btn_group {
      gap: 3.2rem;

      &.alert_msg_btn {
        .btn.primary_btn {
          min-width: 13.9rem;
        }

        .btn.danger_btn {
          min-width: 13.4rem;
        }
      }
    }

    &.resend_invite {
      .mail_name {
        @extend .fs_14;
        @include fw_400;
        margin-bottom: 2.4rem;
        color: $black-color;
        @extend .text_center;
        line-height: 21px;
        padding-bottom: 2.5rem;
        border-bottom: 0.1rem solid $border-color;

        span {
          @include fw_700;
        }
      }

      .last_resend {
        @extend .fs_14;
        @include fw_400;
        margin-bottom: 3.2rem;
        color: $black-color;
        @extend .text_center;
        line-height: 18px;

        span {
          @include fw_700;
        }
      }
    }

    &.multiple_delete {
      .delete_user_list {
        @include w_100;
        max-height: 40rem;
        overflow: auto;
        padding-left: 8rem;

        .delete_user {
          @include d_flex_center;
          @extend .justify_content_start;
          margin-bottom: 2.4rem;
          gap: 0.8rem;

          .user_pic {
            width: 3.5rem;
            height: 3.5rem;
            @include d_block;
            margin: unset;
            margin-bottom: 0;
          }

          .user_name {
            margin-bottom: 0;
            max-width: 18.4rem;
            @extend .white_space_nowrap;
            @include over_hidden;
            @extend .text_ellipsis;
          }
        }
      }

      &.multiple_resend {
        .multi_resend_list {
          max-height: 40.5rem;
          overflow: auto;
          margin-bottom: 3.2rem;

          .delete_list_info {
            padding-left: 8rem;
            padding-bottom: 1.6rem;
            margin-bottom: 1.6rem;
            border-bottom: 0.1rem solid $grey-100;
            .delete_user {
              @extend .d_flex_align;
              @extend .justify_content_start;
              gap: 0.8rem;
              margin-bottom: 1.6rem;

              .user_pic {
                width: 3.5rem;
                height: 3.5rem;
                margin: unset;
              }

              .user_name {
                @extend .text_left;
                margin-bottom: 0;

                .mail_name {
                  @include d_block;
                  @extend .fs_14;
                  @include fw_400;
                  line-height: 21px;
                }
              }
            }

            .last_resend {
              @extend .text_left;
              @extend .fs_14;
              @include fw_400;
              line-height: 18px;
              color: $light-gray;
              margin-bottom: 0rem;

              span {
                @extend .fw_600;
              }
            }
          }
        }
      }
    }

    &.csv_modal {
      max-width: 62.4rem;
      min-height: 42.1rem;

      .close_btn {
        @include d_flex_center;
      }

      .upload_box {
        @extend .radius_10;
        background: #f4f7fa;
        padding: 3.2rem 4rem;
        margin-bottom: 3.2rem;
        @include position_relative;

        .csv_content {
          color: $tab-text;
          @extend .text_center;
          @extend .fs_15;
          @extend .fw_500;
          line-height: 140%;
          margin-bottom: 4rem;

          .download {
            @extend .d_inline_block;
            color: $primary-color;
            text-decoration: underline;
            @extend .cursor_pointer;
          }
        }

        .upload {
          @include w_100;
          max-width: 18rem;
          height: 9.6rem;
          @extend .m_auto;
          @include position_relative;

          .csv_up {
            @include position_absolute;
            @extend .cursor_pointer;
            width: 8.6rem;
            height: 8.6rem;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
          }

          .title_csv {
            @include fs_16;
            @extend .fw_500;
          }
        }
      }

      .upload_btn {
        gap: 3.2rem;

        .blue_btn {
          @extend .cursor_pointer;
        }

        .gray_btn {
          background: $grey-text;
        }
      }
    }

    &.setting_modal {
      max-width: 85.1rem;
      max-height: 95%;

      .setting_list {
        margin-left: -3.2rem;
        margin-right: -3.2rem;
        margin-bottom: 3.2rem;

        ul {
          li {
            .personal_setting {
              .per_info {
                @include w_100;
                @extend .d_flex_align;
                @extend .justify_content_between;
                gap: 1rem;
                border-bottom: 0.1rem solid $border-color;
                background: $table-hover;
                min-height: 7.2rem;
                padding: 0 3.2rem;

                .per_detials {
                  @extend .d_flex_align;
                  gap: 1rem;

                  .per_pic {
                    width: 2.4rem;
                    height: 2.4rem;
                  }

                  .per_name {
                    color: $tab-text;
                    @include fs_16;
                    @extend .fw_500;
                    line-height: 44px;
                  }
                }

                .set_arrow {
                  width: 2.4rem;
                  height: 2.4rem;
                  @include d_flex_center;
                  @extend .cursor_pointer;

                  img {
                    width: 2.4rem;
                    height: 2.4rem;
                  }

                  &.close {
                    img {
                      transform: rotate(180deg);
                    }
                  }
                }
              }

              .per_detais {
                padding: 0 3.2rem;
                padding-top: 3.2rem;
                gap: 3.2rem;
                @extend .align_items_baseline;

                .form_group {
                  width: 100%;
                  .change-password-input {
                    position: relative;
                    .eye_pass {
                      position: absolute;
                      right: 15px;
                      top: 16px;
                    }
                  }
                  .input_fild_remove.input_remove_password {
                    right: 2rem;
                  }
                  .input_fild_remove {
                    top: 4.8rem;
                  }

                  label {
                    @extend .fs_14;
                  }

                  .form_control {
                    &.disable {
                      background-color: $form-input-active-bg;
                    }
                  }

                  .input_fild_remove {
                    background: $white-color;
                  }
                }

                &.email_requrd {
                  padding-top: 0;
                  @extend .align_items_baseline;

                  .form_group {
                    &.email_fild {
                      .form_control {
                        border: 0.1rem solid #b6d4f8;
                        background: $form-input-active-bg;
                      }
                    }
                  }

                  .form_group {
                    &.phone_fild {
                      max-width: 29.2rem;
                      @include position_relative;

                      .selected-dial-code {
                        @extend .d_inline_block;
                        @include position_absolute;
                        left: 2.4rem;
                        top: 4.6rem;
                        color: $black-color;
                        @include fs_16;
                        @include fw_400;
                        line-height: 140%;
                      }

                      .conform_plus_one {
                        padding-left: 4.5rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.delete_follow_user {
      .delete_use_content {
        color: $black-color;
        @extend .fs_14;
        @include fw_400;
        line-height: 140%;
        margin-bottom: 3.2rem;
      }

      .btn.danger_btn {
        min-width: 7.3rem;
      }
    }

    &.login_as_modal {
      max-width: 100%;
      height: 100vh;
      border-radius: unset;
    }

    &.skip_modal {
      max-width: 53.9rem;
      min-height: auto;
      h2 {
        max-width: 100%;
      }

      .skip_warning {
        .skip_warning_icon {
          @include d_block;
          @include w_100;
          max-width: 6.4rem;
          @extend .m_auto;
          height: 6.4rem;
          margin-bottom: 2.4rem;

          img {
            width: 6.4rem;
            height: 6.4rem;
            @extend .img_object_center;
            margin: 0 auto;
          }
        }

        .skip_warning_title {
          @include d_block;
          color: $red-required;
          @extend .fs_14;
          @extend .fw_500;
          @extend .text_center;
          line-height: 140%;
          + .skip_warning_title {
            @include fw_700;
            border-bottom: 0.1rem solid $red-required;
            line-height: 1.6rem;
            @extend .cursor_pointer;
          }
        }
      }

      .table_wrapper {
        padding: 0;
        margin-bottom: 3.2rem;

        .skip_modal_listing {
          thead {
            td {
              @extend .text_left;
              padding: 16px;

              .heading {
                @extend .d_inline_block;
                color: $tab-head;
                @include fs_16;
                @include fw_700;
                line-height: 150%;
              }
            }
          }

          tbody {
            td {
              @extend .text_left;
              padding: 16px;

              .skip_data {
                @extend .d_inline_block;
                color: $tab-text;
                @include fs_16;
                @extend .fw_500;
                line-height: 140%;
                width: auto;
                max-width: 260px;
                @extend .white_space_nowrap;
                @include over_hidden;
                @extend .text_ellipsis;
              }
            }
          }
        }

        &.skip_user_modal {
          .table {
            thead {
              tr {
                td {
                  &:first-child {
                    width: 14%;
                  }

                  &:nth-child(2) {
                    width: 10%;
                  }

                  &:nth-child(3) {
                    width: 14%;
                  }

                  &:nth-child(4) {
                    width: 12%;
                  }

                  &:nth-child(5) {
                    width: 9%;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  .skip_data {
                    max-width: 30rem;
                  }
                }
              }
            }
          }
        }
      }

      .modal_pagisition {
        margin-bottom: 3.2rem;

        ul {
          @include d_flex_center;

          li {
            @extend .cursor_pointer;
            padding: 1.8rem 2.3rem;
            color: $grey-text;
            @extend .fs_17;
            @include fw_400;
            line-height: normal;

            &.active {
              color: $primary-color;
              @extend .radius_8;
              border: 0.1rem solid $border-color;
              background: $white-color;
              box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);
            }

            &.next_page {
              @include d_flex_center;
              border-radius: 8px;
              border: 0.1rem solid $border-color;
              background: $white-color;
              box-shadow: 0px 4px 40px 0px rgba(36, 73, 137, 0.08);

              &.previous {
                img {
                  transform: rotate(180deg);
                }
                &.more_previos {
                  margin-right: 1rem;
                  img {
                    width: 1.6rem;
                    height: 1.6rem;
                    transform: rotate(180deg);
                  }
                }
              }
              &.more_next {
                margin-left: 1rem;
                img {
                  width: 1.6rem;
                  height: 1.6rem;
                }
              }
            }
          }
        }
      }

      .delete_btn_group {
        gap: 1.5rem;
        .danger_btn {
          min-width: 19.8rem;
        }
      }
      .close_btn {
        top: 3.2rem;
      }
    }

    &.pairs_csv_modal {
      width: 539px;
      height: 306.59px;
      font-size: 12px !important;
      justify-content: center;

      .upload_statistics {
        width: 401px;
        height: 38px;
        gap: 0px;
        text-align: center;
        justify-content: center;
        margin-bottom: 15px;
        margin-top: -30px;

        h2 {
          font-size: 14px;
          font-weight: normal !important;
          margin: 0;
          padding: 0;
        }
      }

      .with_margin {
        margin-bottom: 12.5px;
      }

      .table_responsive .skip_modal_listing tr td:nth-child(6) {
        width: 12% !important;
      }
    }
  }

  .adduser {
    display: none;
    width: 100%;
    max-width: 637px;
    padding: 3.2rem;
    border-radius: 20px;
    background: var(--black-colors-white, $white-color);
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
    @extend .position_fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.active {
      @include d_block;
    }

    .close_btn {
      width: 2.4rem;
      height: 2.4rem;
      @include position_absolute;
      top: 1.5rem;
      right: 2rem;
      @extend .cursor_pointer;
    }

    .modal-head {
      h2 {
        @extend .text_capitalize;
        line-height: 2.2rem;
        margin-bottom: 1.6rem;
        @extend .montserrat_bold;
        font-size: 1.8rem;
      }

      margin-bottom: 3.2rem;
    }

    .form_group {
      label {
        @extend .fs_14;
        line-height: 2.2rem;
        @include d_block;
        @include fw_700;
        color: $form-lable-color;
        margin-bottom: 0.8rem;
      }

      .form_control {
        border-color: $form-input-color;
        @extend .montserrat_regular;
        @include fs_16;
        color: $form-input-color;
        line-height: 2.2rem;

        &::placeholder {
          @extend .montserrat_regular;
          @include fs_16;
          color: $form-input-color;
          line-height: 2.2rem;
        }
      }

      .custom-msg {
        @extend .montserrat_regular;
        @extend .fs_14;
        color: $form-input-color;
        line-height: 2.2rem;
        margin-top: 0.8rem;
      }
    }

    .modal-btns {
      gap: 0.83rem;
      margin-block: 0.8rem;

      .btn {
        width: 48%;
        @extend .justify_content_center;
        min-height: 48px;

        &.cancel-btn {
          border: 2px solid $primary-color;
          color: $form-lable-color;
          background-color: transparent;

          &:hover {
            border-color: $dark-bg;
            color: $white-color;
          }
        }

        &.add-btn {
          background-color: $grey-text;
        }
      }
    }
  }

  .add_partner {
    display: none;
    width: 100%;
    max-width: 63.7rem;
    padding: 3.2rem;
    @extend .text_center;
    border-radius: 20px;
    background: var(--black-colors-white, $white-color);
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
    @extend .position_fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    overflow: hidden;

    &.active {
      @include d_block;
    }

    .close_btn {
      width: 4rem;
      height: 4rem;
      @extend .cursor_pointer;
      @include position_absolute;
      top: 2.4rem;
      right: 3rem;
    }

    h2 {
      @extend .fs_18;
      @include fw_700;
      @extend .m_auto;
      @extend .text_center;
      max-width: 29.2rem;
      color: $black-color;
      line-height: 36px;
    }

    .partner_name_title {
      @include d_flex_center;
      @extend .justify_content_start;
      gap: 3.2rem;
      margin-bottom: 3.4rem;

      .part_regin_name {
        color: $tab-text;
        @include fs_16;
        @extend .fw_500;
        line-height: 140%;
      }

      .name_of {
        @extend .d_inline_block;
        color: $tab-text;
        @include fs_16;
        @include fw_700;
        line-height: 150%;
        padding-right: 0.5rem;
      }
    }

    .add_part_content {
      @extend .fs_14;
      @include fw_400;
      @extend .m_auto;
      @extend .text_center;
      line-height: 140%;
      color: $black-color;
      margin-bottom: 3.2rem;
    }

    .form-wrapper {
      padding-top: 0;

      .form_group {
        margin-bottom: 3.3rem;

        .form_control {
          min-height: 4.8rem;
        }

        .add_plus_icon {
          @extend .cursor_pointer;
          @include d_block;
          width: 3.2rem;
          height: 3.2rem;
          @include position_absolute;
          top: 34px;
          right: 1.6rem;
        }

        .type_school {
          @include d_block;
          @extend .fs_14;
          @include fw_400;
          @extend .m_auto;
          @extend .text_left;
          line-height: 140%;
          color: $form-input-color;
          margin-bottom: 3.2rem;
          margin-top: 1.2rem;
        }

        .add_school_list {
          @include d_none;
        }

        .options {
          .all_location {
            color: $tab-text;
            @include fs_16;
            @extend .fw_500;
            line-height: 140%;
          }
        }
      }
    }

    .add_btn_group {
      gap: 0.8rem;
      margin-bottom: 0.8rem;
    }

    &.add_plus_btn {
      .form_group {
        .form_control {
          padding-right: 5.6rem;
        }

        .add_plus_icon {
          @include d_block;
        }

        .type_school {
          small {
            @include fw_700;
          }
        }

        .more_school_list {
          max-height: 15rem;
          overflow: auto;
          padding: 0.5rem;

          .add_school_list {
            @include d_flex_center;
            @extend .justify_content_start;
            @extend .flex_wrap;
            gap: 0.8rem;

            .add_school_inst_name {
              @include fs_16;
              @extend .fw_500;
              line-height: 2.4rem;
              color: $black-color;
              padding: 0.8rem 1.6rem;
              @extend .radius_30;
              background: $grey-100;
              margin-bottom: 0.8rem;
              @include d_flex_center;
              gap: 0.8rem;

              img {
                @extend .cursor_pointer;
              }
            }
          }
        }
      }

      .add_btn_group {
        .add_btn {
          @include apply_bgcolor(Light_blue_clr);
          min-width: 22.6rem;

          &.gray_btn {
            background: $grey-text;
            min-width: 22.6rem;
          }
        }
      }

      &.add_disable {
        .form_group {
          .form_control {
            padding-right: 5.6rem;
          }

          .add_plus_icon {
            @include d_block;

            svg {
              rect {
                fill: $primary-color;
              }
            }
          }
        }
      }
    }

    &.edit_plus_btn {
      &.add_disable {
        .form_group {
          .form_control {
            padding-right: 5.6rem;
          }

          .add_plus_icon {
            @include d_block;
            animation: button_animate 0.5s ease-in-out forwards;
            @keyframes button_animate {
              0% {
                transform: translateY(-0.5rem);
                opacity: 0;
              }
              100% {
                transform: translate(0);
                opacity: 1;
              }
            }
            svg {
              path {
                fill: $white-color;
                stroke: $white-color;
              }
            }
          }
        }

        .add_btn_group {
          .border_btn {
            padding: 1.1rem 2.4rem;
            line-height: 153%;
            height: 4.8rem;
          }
          .add_btn {
            background: $grey-text;
            padding: 1.2rem 2.4rem;
            max-width: 27.4rem;
          }

          .blue_btn {
            @include apply_bgcolor(Light_blue_clr);
            max-width: 27.4rem;
          }
        }
      }
      &.resion_back_modal {
        .form_group .more_school_list .add_school_list .add_school_inst_name {
          margin-bottom: 0.7rem;
        }
      }
    }

    &.assigned_modal {
      .add_part_content {
        margin-bottom: 4rem;
      }

      .list_assign_name {
        width: 100%;
        margin-bottom: 4.8rem;
        height: 12.5rem;
        overflow: auto;
        padding-left: 6rem;

        li {
          @include d_flex_center;
          @extend .justify_content_start;
          @include fs_16;
          @include fw_400;
          line-height: 2.2rem;
          margin-bottom: 2.7rem;
          gap: 1rem;
          color: $black-color;
        }
      }

      .add_btn_group {
        .btn {
          &.border_btn {
            min-width: 13.9rem;
          }
        }
      }
    }

    &.mail_modal {
      max-width: 47.5rem;

      .send_emial_box {
        h2 {
          margin-bottom: 2.4rem;
        }

        .mail_icon {
          width: 100%;
          max-width: 6.4rem;
          height: 6.4rem;
          margin: auto;
          margin-bottom: 2.4rem;
        }

        .mail_content {
          color: $black-color;
          @extend .text_center;
          @extend .fs_14;
          @include fw_400;
          line-height: 140%;
        }

        .change_pass {
          width: 100%;
          max-width: 20.4rem;
          @include d_block;
          @extend .white_space_nowrap;
          margin: auto;
          margin-top: 3.2rem;
        }
      }
    }
  }
}

.dot_menu_modal {
  width: auto;
  min-width: 23rem;
  border-radius: 0.4rem;
  border: 0.1rem solid $border-color;
  box-shadow: 0 0 3rem 0 rgba(116, 116, 116, 0.15);
  @include position_absolute;
  right: 5.2rem;
  background: $white-color;
  z-index: 99;
  padding-bottom: 0;
  @extend .cursor_pointer;

  .list_dot_menu {
    @include d_flex;
    @extend .align_items_center;
    @extend .justify_content_start;
    padding: 1.6rem;

    &:hover {
      background: $form-input-active-bg;
    }

    img {
      margin-right: 1.6rem;
      width: 1.6rem;
      height: 1.6rem;
      @extend .img_object_center;
    }

    &.disable_menu {
      opacity: 0.5;
      cursor: default;
    }
  }
}

table {
  &.mentors {
    .dot_menu_modal {
      max-width: 22rem;
      bottom: 0;
    }
  }
  &.user_data_listing {
    .dot_menu_modal {
      min-width: 25.8rem;
    }
  }
}

.i_match_into_pair {
  .i_popup_footer {
    button {
      &.pair_back {
        border: 0.2rem solid $primary-color !important;
        background-color: $white-color;
        max-width: 20rem;
        min-width: 20rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: $black-color;

        &:hover {
          color: $white-color;
        }
      }

      &.go_pairs {
        background-color: $primary-color;
        max-width: 24.5rem;
      }
    }
  }

  .imentor_info {
    .disable_user_animate_text {
      @include d_none;
    }
  }

  .disable_user_animate {
    @include trans;
    display: block !important;

    .user_one {
      @include d_none;
    }

    .user_two {
      @include d_none;
    }

    h3 {
      display: block !important;
      opacity: 1;
      @include trans;
      @include fw_700;
      font-size: 1.4rem;
      color: $black-color;
    }

    + .imentor_info {
      .without_animate_text {
        @include d_none;
      }
    }

    + .imentor_info {
      .disable_user_animate_text {
        @include d_block;
      }
    }

    + .imentor_info {
      + .i_popup_footer {
        .i_footer_title {
          @include d_none;
        }

        button {
          &.pair_back {
            border: 0.2rem solid $primary-color !important;
            background-color: $white-color;
            color: $black-color;
            max-width: 20rem;
            min-width: 20rem;
            padding-top: 1rem;
            padding-bottom: 1rem;

            &:hover {
              color: $white-color;
            }
          }

          &.go_pairs {
            background-color: $primary-color;
            color: $white-color;
            max-width: 24.5rem;
          }
        }

        > div {
          padding-top: 2.5rem;
        }
      }
    }
  }

  .s_checkmark {
    img {
      width: 3.2rem;
      height: 3.2rem;
      @include trans;
    }

    &.animate_success {
      width: 9.3rem;
      height: 9.3rem;
      @include d_flex_center;
      margin: 3rem auto 0;

      img {
        width: 9.3rem;
        height: 9.3rem;
        @include trans;
      }
    }
  }

  .matches_user {
    &.user_one {
      @include d_block;
      animation: slideInLeft linear;
      animation-duration: 0.35s;
      animation-fill-mode: forwards;
      transition: all 0.2s ease-in-out;

      h6 {
        background-color: $light-blue;
        z-index: 99999;
        @include position_relative;
      }
    }

    &.user_two {
      @include d_block;
      animation: slideInRight linear;
      animation-duration: 0.35s;
      animation-fill-mode: forwards;
      transition: all 0.2s ease-in-out;

      h6 {
        background-color: $light-blue;
        z-index: 99999;
        @include position_relative;
      }
    }

    @keyframes slideInLeft {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(60%);
      }
    }

    @keyframes slideInRight {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-60%);
      }
    }
  }
}

.edit_to_more {
  .click_edit_btn {
    padding: 0.9rem 2.2rem;
    min-width: 17.9rem;
    min-height: 4.8rem;
    gap: 1.2rem;
    color: $grey-text;
    @include fs_16;
    @include fw_400;
    line-height: 2.1rem;
    border-radius: 8px;
    border: 0.1rem solid $grey-text;
    @include d_flex_center;
    margin: 2.4rem 0;
    @include position_relative;
    &:hover {
      .tooltip {
        @include d_block;
        top: -3.6rem;
        left: 5.5rem;
      }
    }
  }
}

.skip_warning_icon {
  img {
    margin: 0 auto;
  }
}

.main_update_profile {
  border-radius: 1.6rem;
  background-color: $lighten-blue;
  padding: 2.4rem;
  @include d_flex;
  @extend .align_items_center;
  @extend .justify_content_between;
  @extend .flex_wrap;
  width: 100%;
  .left_sec {
    gap: 2.4rem;
    .setting_profile {
      border-radius: 6.7rem;
      border: 0.2rem solid #b6d4f8;
      background: #dceafb;
      width: 8.8rem;
      height: 8.8rem;
      @include position_relative;
      .loader {
        width: 3rem;
      }
      img {
        @include w_100;
        @include h_100;
        @extend .img_object_center;
        @extend .radius_round;
      }
    }
    .content {
      p {
        color: $black-color;
        max-width: 25.5rem;
        @extend .text_center;
      }
    }
    input [type='file'] {
      top: 5rem;
      @extend .cursor_pointer;
      width: 0;
      height: 0;
    }
  }
  .right_sec {
    .button_upload {
      @extend .cursor_pointer;
      button {
        min-width: 22.9rem;
        gap: 1.2rem;
        @extend .cursor_pointer;
        &:hover {
          > img {
            filter: brightness(21);
            @include trans;
          }
        }
      }
      &:hover {
        button {
          background-color: #1773e3;
          color: $white-color;
          img {
            filter: brightness(21);
            @include trans;
          }
          &.btn_remove {
            background-color: transparent;
            color: #dd2025;
          }
        }
      }
    }

    .btn_remove {
      color: #dd2025;
      @extend .text_center;
      @extend .montserrat_bold;
      @include fs_16;
      line-height: 150%;
      &:hover {
        background-color: transparent;
        color: #dd2025;
      }
    }
  }
  .show_error_msg {
    width: 100%;
  }
}
