.header_sticky {
  + form {
    .events_page {
      padding-top: 12rem;
      padding-bottom: 8rem;
    }
  }
}

.events_page {
  .add_mentor_header {
    position: inherit;
    h2 {
      font-size: 3.2rem;
    }
    > p {
      color: $light-gray;
      @extend .fs_14;
      @include fw_400;
    }
  }
  .calenderview_btn {
    padding-left: 4.4rem;
    label {
      @include fw_700;
      color: $primary-color;
      font-size: 1.4rem;
    }
    .calender {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .form_small_container {
    &.add_events {
      margin: 0;
      max-width: 66.4rem;
    }
    .form-wrapper {
      form {
        .form-steps {
          .form_group {
            .main_picture_upload {
              .dragBox {
                @extend .white_space_nowrap;
                @extend .text_ellipsis;
                @include over_hidden;
              }
            }
          }
        }
      }
    }
  }
  .add_mentor_header {
    padding: 1.2rem 14rem;
  }

  .add_events_main {
    padding: 0 14rem;
    .add_events {
      margin: 0;
      padding: 0;
      .form-wrapper {
        .mentor_info {
          .form-steps {
            .form_group {
              .radio-box {
                .radio-info {
                  width: auto;
                  padding-right: 3rem;
                  [type="radio"]:checked {
                    + label {
                      width: auto;
                      height: auto;
                    }
                  }
                  [type="radio"]:not(:checked) {
                    + label {
                      padding: 1rem 1.5rem 1rem 4.4rem;
                    }
                  }
                  [type="radio"] {
                    + label {
                      &.event_radio_disabled {
                        border: 0.1rem solid $border-color;
                        border-radius: 0.7rem;
                        &:before {
                          border-color: $border-color;
                          width: 2.5rem;
                          height: 2.5rem;
                        }
                        &:after {
                          background: $border-color;
                          left: 1.6rem;
                          transform: translateY(-50%);
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
              }
              textarea {
                height: 13.3rem;
              }
              .view_btn {
                @include fw_700;
                font-size: 1.3rem;
                color: $primary-color;
                text-decoration: underline;
                margin-bottom: 1.5rem;
              }
              .primary_btn {
                background-color: $primary-color;
                color: $white-color;
                border-radius: 0.4rem;
              }
              .form_control {
                border: 0.1rem solid $separtor-color;
                padding-right: 1.6rem;
              }
              .main_picture_upload {
                & .upload_pic_wrapper{
                  min-width: 12rem;
                }
                .upload_photo {
                  max-width: 22.4rem;
                  padding-left: 0;
                  padding-right: 0;
                 
                  &.upload_pic {
                    &.upload{
                      min-width: 22.4rem;
                    }
                    &.Upload_sec{
                      min-width: auto;
                      max-width: 12rem;
                    }
                  }
                }
                .picture_wrapper {
                  .profile_img {
                    &.square_img {
                      border-radius: 0.8rem;
                      border: 0;
                      img {
                        border-radius: 0.8rem;
                        @extend .img_object_center;
                      }
                    }
                    .pdf_wrapper {
                      img {
                        width: 3.2rem;
                        height: 3.2rem;
                      }
                    }
                  }
                }
              }
              .err_field {
                .error_icon {
                  padding: 0.6rem;
                  top: 3.7rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .add_mentor_footer {
    ul {
      li {
        .cancel {
          @extend .fw_500;
          color: $light-gray;
          font-size: 1.311rem;
          gap: 0.8rem;
          span {
            color: $primary-color;
          }
        }
        &:nth-child(2) {
          @include w_100;
        }
      }
    }
  }
  .table_wrapper {
    .table_responsive {
      .table.more_page {
        tbody {
          tr {
            td {
              span {
                &.user_name {
                  max-width: 12rem;
                  @include over_hidden;
                  @include d_block;
                  @extend .text_ellipsis;
                  @extend .white_space_nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
  .guest_name {
    margin-bottom: 1.5rem;
    gap: 0.8rem;
    .guest_info {
      .image {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 10rem;
        @include over_hidden;
        img {
          width: 2.2rem;
          height: 2.2rem;
          @extend .img_object_center;
        }
      }
      .inner_guest {
        border-radius: 3rem;
        background: $grey-100;
        padding: 0.8rem 1.6rem;
        gap: 0.8rem;
        span {
          @extend .fw_500;
          color: $black-color;
          font-size: 1.6rem;
          max-width: 15rem;
          @extend .text_ellipsis;
          @include over_hidden;
          @extend .white_space_nowrap;
          margin-bottom: 0;
        }
      }
    }
    .guest_detail
    {
      @include d_block;
    }
  }
  .table_wrapper {
    .table_responsive {
      .content_list {
        tr {
          &.last_three {
            td {
              .preview_on_hover {
                bottom: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
  .form_small_container {
    .form-wrapper {
      form {
        .form-steps {
          .form_group {
            .main_picture_upload {
              .profile_img {
                img {
                  object-fit: none;
                }
                &.upload_event_thumbnail {
                  img {
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .inner_wrap {
    .top_img {
      @include w_100;
    }
  }
  .events_date_picker {
    width: 48%;
    &.w_100 {
      width: 100%;
    }
  }
  .form_small_container {
    .form-wrapper {
      form {
        .form-steps {
          .form-box {
            .form_group {
              label {
                @include position_relative;
                input {
                  @include position_absolute;
                  top: 50%;
                  right: 50%;
                  transform: translate(50%, -50%);
                }
                .checked {
                  + span {
                    &::before {
                      margin-left: 0;
                    }
                    &::after {
                      left: 0;
                      top: 0.3rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .modal_comman {
    .delete_modal {
      h2 {
        margin-bottom: 2.8rem;
      }
    }
  }
  .events_partner_region_row {
    span {
      &.user_name {
        &.event_location {
          color: $tab-text;
          @extend .fw_500;
          @include fs_16;
          line-height: 140%;
        }
      }
    }
    span {
      &.user_name {
        @extend .fs_12;
        @extend .fw_500;
        color: $light-gray;
        line-height: 140%;
        &.fs_16 {
          @include fs_16;
        }
      }
    }
  }
}

.events_result_main {
  .events_left_Sec {
    max-width: 71.3rem;
    .events_left_wrapper {
      min-width: 71.3rem;
      border-radius: 0.8rem;
      border: 0.1rem solid $border-color;
      background: $white-color;
      box-shadow: 0px 0.4rem 4rem 0px rgba(36, 73, 137, 0.08);
      padding: 2rem;
      margin-bottom: 1.6rem;
      @include trans;
      .events_head {
        padding-bottom: 2.2rem;
        .events_tag {
          border-radius: 0.48rem;
          background: $grey-100;
          @include fw_400;
          font-size: 1.4rem;
          padding: 0.32rem 0.96rem;
        }
        .date {
          @include fw_700;
          color: $tab-head;
          gap: 0.8rem;
          span {
            @extend .fw_500;
          }
        }
      }
      &:hover {
        border-radius: 0.8rem;
        border: 0.1rem solid $border-color;
        background: $table-hover;
        box-shadow: 0px 0.4rem 4rem 0px rgba(36, 73, 137, 0.08);
        @include trans;
      }
    }
    .events_content {
      padding-bottom: 2rem;
      > div {
        .text {
          p {
            @extend .fw_600;
            color: $black-color;
            font-size: 2rem;
            line-height: 3rem;
            max-width: 55.3rem;
          }
        }
      }
      .location_date_wrapper {
        gap: 3rem;
        @include w_100;
        .location_date {
          min-width: 29rem;
          padding-top: 3.2rem;
          padding-left: 1.2rem;
          h4 {
            @include fw_700;  
            color: $tab-head;
            font-size: 1.6rem;
            margin-bottom: 1.2rem;
          }
          .address {
            @extend .fw_500;
            color: $tab-text;
            font-size: 1.6rem;
            max-width: 27.5rem;
            img {
              height: 2.4rem;
            }
            span {
              line-height: 2.1rem;
            }
            .img_wrapper {
              border-radius: 6.71rem;
              border: 0.1rem solid #b6d4f8;
              background: #dceafb;
              width: 4.8rem;
              height: 4.8rem;
            }
            .file_name {
              @extend .fw_600;
              color: $form-input-color;
              font-size: 1.4rem;
              max-width: 30rem;
              @extend .text_ellipsis;
              @include over_hidden;
              @extend .white_space_nowrap;
            }
            .meet_link {
              @extend .fw_500;
              color: $primary-color;
              font-size: 1.6rem;
              word-break: break-all;
            }
            &.attachment {
              .img_wrapper {
                img {
                  width: auto;
                  padding: 1.3rem;
                  height: 4.8rem;
                }
              }
            }
          }
          .table_timezone{
            .name_toolkit {
              @include position_relative;
              .time_zone_label{
                line-height: normal;
                background: $gray-100;
              }
              .full_username{
                color: $white-color;
                @extend .fs_14;
                @include fw_400;
                line-height: 140%;
                padding: 0.8rem 1rem;
                @extend .radius_4;
                background: $dark-bg;
                @extend .d_flex_align;
                gap: 1rem;
                @include position_absolute;
                top: -3.7rem;
                display: none;
                @extend .white_space_nowrap;
                left: unset ;
                right: 50% !important;
                transform: translate(50%, 0px);
              }
              &:hover {
                .full_username {
                  @include d_flex;
                }
              }
            }
          }
        }
        .dot_icon_modal {
          .dot_menu_modal {
            right: 0;
            top: 2rem;
          }
          .option_menu {
            border: 0.1rem solid transparent;
            padding: 0.3rem;
            &.active {
              border: 0.1rem solid $primary-color;
              @extend .radius_round;
              padding: 0.3rem;
              @include d_flex_center;
            }
            img{
              min-width: 2.5rem;
              min-height: 2.4rem;
            }
          }
        }
      }
      .image {
        width: 6.4rem;
        height: 6.8rem;
        img {
          @include w_100;
          height: 100%;
          @extend .img_object_center;
          border-radius: 0.8rem;
        }
      }
    }
  }
}
.virtual_span {
  margin-top: 0.8rem;
  .custom-checkbox {
    margin-bottom: 0;
    .checked {
      & + span::before {
        width: 1.6rem;
        height: 1.6rem;
      }
      & + span::after {
        width: 0.4rem;
        height: 1rem;
        top: -0.2rem !important;
      }
    }
  }
}

.event_table {
  .table_wrapper {
    .table_responsive {
      .table {
        &.content_list {
          td {
            &:first-child {
              width: 25%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 10%;
            }
            &:nth-child(4) {
              width: 19%;
            }
            &:nth-child(5) {
              width: 18%;
            }
            &:nth-child(6) {
              width: 28%;
            }
            &:nth-child(7) {
              width: 30%;
              &.reject_row{
                width: 25%;
              }
            }
            &:nth-child(8) {
              width: 8%;
              &.dot_td{
                width: 7%;
              }
            }
          }
        }
      }
    }
  }
}

.chat_calendar {
  margin-top: 0;
  margin-left: 3.2rem;
  margin-right: 3.2rem;

  .demo-app {
    border: 0.1rem solid $border-color;
    padding: 1.2rem;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 4rem 0 rgba(36, 73, 137, 0.08);
    @include position_relative;

    &::before {
      content: "";
      background: url(../../../public/images/calender_rec.png);
      height: 26rem;
      width: 100%;
      @include position_absolute;
      z-index: 0;
      background-repeat: no-repeat;
    }

    .month_week_btns {
      background-color: $white_color;
      @extend .text_center;
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;

      button {
        color: $primary-color;
        @include fw_400;
        @include fs_16;
      }
    }

    .next_prev_btns {
      .prev_btn {
        @include position_absolute;
        left: -10px;
        top: 48px;
      }

      .next_btn {
        @include position_absolute;
        right: -10px;
        top: 48px;
        transform: rotate(180deg);
      }
    }

    .date_days_txt {
      color: $tab-text;
      @extend .fw_600;
      font-size: 2rem;
      @extend .text_center;
    }

    .fc {
      min-width: 35rem;
      max-width: 40rem;
      @extend .montserrat_semibold;
      font-size: 1.5rem;
      border: 0;
      .fc-scroller-liquid-absolute {
        overflow: hidden !important;
      }
      .fc-scroller {
        overflow: hidden !important;
      }
      .fc-header-toolbar {
        @include d_none;
      }
      .fc-scrollgrid-liquid {
        @extend .border_0;
      }
      .fc-daygrid-day-top {
        @extend .justify_content_center;
        + .fc-daygrid-day-events {
          min-height: 1.5em;
          .fc-daygrid-event-harness {
            .fc-daygrid-event-dot {
              border-color: $primary-color;
            }
          }
        }
      }
      .fc-event-title {
        @include d_none;
      }
    }

    .demo-app-main {
      padding: 2.4rem 2.8rem;
      position: relative;

      &.week_view_cal {
        .month_week_btns {
          margin-top: 2.2rem;
        }
      }

      .calendar_style {
        border-radius: 2rem;
        border: 0.1rem solid $border-color;
        background-color: $white-color;
        box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
        padding-bottom: 3.3rem;
        .month_year_sec {
          @extend .text_center;
          padding: 2.5rem 0;
          @include d_flex_center;
          gap: 2.3rem;

          select {
            appearance: none;
            &#month {
              @extend .montserrat_medium;
              @include fs_16;
              background: url(../../../public/images/Polygon_calendar.svg) no-repeat;
              background-position: 100%;
              padding-right: 2rem;
              @extend .cursor_pointer;
              border: 0;
            }

            &#year {
              @extend .fw_500;
              @include fs_16;
              background: url(../../../public/images/Polygon_calendar.svg) no-repeat;
              background-position: 100%;
              min-width: 7rem;
              padding-right: 1.2rem;
              @extend .cursor_pointer;
              border: 0;
            }
          }
        }

        .fc-media-screen {
          .fc-dayGridWeek-view {
            padding: 3rem 1rem;
            border: 0.1rem solid $border-color;
            background-color: $white-color;
            box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
            border-radius: 2rem;

            table {
              width: 100% !important;
            }

            .fc-scrollgrid-sync-inner {
              font-size: 1.2rem;
            }

            .fc-col-header-cell-cushion {
              @extend .cursor_pointer;
              padding: 1.1rem 0.5rem;
              @include w_100;
              max-width: 3.2rem;
              &.selected-date-week {
                background-color: $form-input-active-bg;
                border: 0.5px solid $primary-color;
                border-radius: 1rem;
                color: $black-color;
                height: 6rem;
                max-width: inherit;
                width: 4rem;
              }
              .weekDay {
                color: $light-gray;
                @include fw_400;
                @extend .fs_12;
              }
              span {
                color: $black-color;
                @extend .fw_500;
              }
            }
          }

          .fc-daygrid-day-number {
            @extend .cursor_pointer;
          }
        }

        .fc-dayGridMonth-view {
          .fc-theme-standard {
            td {
              @extend .border_0;
            }
          }
        }

        .fc-dayGridMonth-view {
          .fc-daygrid-dot-event {
            @extend .justify_content_center;

            &:hover {
              background-color: transparent;
            }
          }
        }

        .fc-day-today {
          background-color: transparent;

          .fc-daygrid-day-number {
            background-color: $dark-blue;
            color: $white-color;
            border-radius: 5rem;
            width: 2.6rem;
            height: 2.6rem;
            @include d_flex_center;
            padding: 0.6rem;
          }
          .fc-col-header-cell-cushion {
            .custom-day-header {
              .weekDay {
                color: $white-color;
              }
              span {
                color: $white-color;
                @extend .fw_500;
              }
            }
            &.selected-date-week {
              .custom-day-header {
                .selected-date-week {
                  color: $black-color;
                }
              }
              > .selected-date-week {
                .weekDay {
                  color: $light-gray;
                }
                span {
                  color: $black-color;
                }
              }
            }
          }
        }

        .fc-dayGridWeek-view {
          .fc-day-today {
            .fc-col-header-cell-cushion {
              background-color: $dark-blue;
              color: $white-color;
              border-radius: 1rem;
              padding: 1.1rem 0.5rem;
              @include w_100;
              max-width: 4rem;
            }
          }

          .fc-daygrid-dot-event {
            @extend .justify_content_center;
            .fc-daygrid-event-dot {
              border: $primary-color;
            }

            &:hover {
              background-color: transparent;
            }
          }
        }
        &.weekly_view {
          background: transparent;
          @extend .border_0;
          box-shadow: none;
          padding-bottom: 0;
          position: relative;
          .month_week_btns {
            background-color: transparent;
          }
          .fc-theme-standard {
            margin-bottom: 9rem;
          }
        }
      }

      .online_event_box {
        border-radius: 0.8rem;
        border: 1.5px solid $border-color;
        padding: 1.5rem;
        margin-bottom: 1.5rem;

        .online_event_txt {
          background-color: $light_blue;
          color: $tab-head;
          padding: 0.3rem 0.9rem;
          border-radius: 0.5rem;
          @extend .d_inline_block;
          @extend .montserrat_regular;
          margin-bottom: 1.5rem;
        }

        .income_txt {
          @extend .montserrat_semibold;
          color: $black-color;
        }

        .time_detail {
          @extend .montserrat_regular;
          color: $black-color;
        }

        .event_boxes_btns {
          @include d_flex_between;
          margin-top: 1.5rem;

          .btn_outline {
            max-width: 15.7rem;
            padding: 1.3rem 1.7rem;
            @include d_flex_center;
            border: 0.1rem solid $red-required;
            font-size: 1.2rem;

            &:hover {
              background-color: $red-required;
              color: $white-color;
            }
          }

          .btn_primary {
            max-width: 15.7rem;
            padding: 1.1rem 1.7rem;
            @include d_flex_center;
            font-size: 1.2rem;

            &:hover {
              color: $white-color;
            }
          }
        }

        &.complete_box {
          .completed_txt {
            background: rgba(165, 255, 165, 0.6);
            @extend .d_inline_block;
            padding: 0.3rem 0.9rem;
            @extend .montserrat_regular;
            border-radius: 0.5rem;
            color: $tab-head;
          }

          .task_title {
            @extend .montserrat_semibold;
            color: $black-color;
            margin-top: 1.5rem;
          }

          .hashtag_detail {
            @extend .montserrat_regular;
            color: $black-color;
          }
        }
      }
    }
  }
}

.full_calendar_view {
  .demo-app-main {
    select {
      @include fs_16;
      padding: 2rem;

      &#month {
        appearance: none;
        background: url(../../../public/images/Polygon_calendar.svg) no-repeat;
        background-position: 70%;
        @extend .cursor_pointer;
      }

      &#year {
        appearance: none;
        background: url(../../../public/images/Polygon_calendar.svg) no-repeat;
        background-position: 100%;
        @extend .cursor_pointer;
      }
    }

    .fc-media-screen {
      @include fs_16;
      @extend .montserrat_semibold;

      .fc-event {
        &:hover {
          background-color: transparent;
        }

        .fc-daygrid-event-dot {
          border-color: $primary-color;
        }
      }
    }
  }
}

.fc-theme-standard {
  thead {
    tr {
      th {
        border: 0;
        padding-bottom: 0.7rem;
      }
    }
  }
  tbody {
    tr {
      td {
        @extend .border_0;
        &.selected-date {
          .fc-daygrid-day-frame {
            .fc-daygrid-day-top {
              .fc-daygrid-day-number {
                background-color: $form-input-active-bg;
                border: 0.5px solid $primary-color;
                color: $black-color;
                border-radius: 10rem;
                width: 2.6rem;
                height: 2.6rem;
                padding: 0.6rem;
                @include d_flex_center;
              }
            }
          }
        }
      }
    }
  }
}

.fake-placeholder {
  @include position_absolute;
  top: 4.3rem;
  left: 1.3rem;
  color: $form-input-color;
  pointer-events: none;
  transition: opacity 0.3s;
  opacity: 1;
  background: $white-color;
}

input[type="time"]:focus + .fake-placeholder {
  @include d_none;
}
input::-webkit-calendar-picker-indicator {
  @extend .cursor_pointer;
}
input::-webkit-date-picker-indicator {
  @extend .cursor_pointer;
}
.events_date_picker {
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      width: 31.8rem;
      right: 0 !important;
      left: auto !important;
    }
    .react-datepicker {
      .react-datepicker__triangle {
        transform: translate(16.5rem, 0) !important;
      }
    }
  }
  input {
    @extend .cursor_pointer;
  }
}
.rc-time-picker {
  .rc-time-picker-clear {
    display: none;
  }
}

.i_events_memebrs_list {
  .i_popup_content {
    .i_popup_footer {
      ul {
        li {
          .events_member_delete_btn {
            margin: 0;
          }
        }
      }
    }
  }
}

.guest_popup_head {
  .inner_guest_head {
    border: 0.1rem solid $border-color;
    border-bottom: 0;
    border-radius: 0.8rem 0.8rem 0 0;
    padding: 2.4rem 2.4rem 1.6rem;
    .comman_head{
      padding: 0;
    }
    .guest_title {
      h3 {
        color: $black-color;
        @extend .fs_20;
        @extend .fw_500;
        line-height: normal;
      }
    }
    .right_sec {
      .comman_head {
        padding: 0;
      }
      .form_group{
        .serch_icon_remove{
          &:hover{
            .tooltip{
              bottom: 3rem;
            }
          }
        }
      }
      .disable_filter{
        pointer-events: none;
        opacity: 0.8;
      }
    }
  }
  .filter_dropdown{
    padding: 0 2.4rem 1.6rem;
    border-left: 0.1rem solid #e0e0e0;
    border-right: 0.1rem solid #e0e0e0;
  }
}

.event_noresult {
  .nodata_found {
    max-width: 100%;
    border: 0.1rem solid $border-color;
    padding: 2rem;
    border-radius: 0 0 0.8rem 0.8rem;
    position: unset;
    transform: none;
    .search_page {
      width: 9rem;
      height: 9rem;
      svg {
        width: 4.5rem;
        height: 4.5rem;
      }
    }
  }
}
