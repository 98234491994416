.btn {
  @extend .montserrat_semibold;
  @include fs_16;
  line-height: 2.2rem;
  @include fw_700;
  @extend .text_center;
  @extend .text_capitalize;
  border: 0;
  @extend .radius_5;
  @extend .cursor_pointer;
  min-width: 8.7rem;
  @include apply_bgcolor(Light_blue_clr);
  color: $white-color;
  padding: 0.9rem 1.5rem;
  outline: none;
  &:hover {
    @include apply_bgcolor(dark_blue);
    color: $white-color;
  }
  &.primary_btn {
    &:hover {
      color: $white-color;
    }
    &.blue_btn {
      color: $white-color;
      @include apply_bgcolor(Light_blue_clr);
    }
  }
  &.resend_btn {
    &:hover {
      color: $white-color;
    }
  }
  &.danger_btn {
    img {
      filter: brightness(0) invert(1);
    }
    &:after {
      background-color: $red-required;
    }
  }
  &.assign_btn {
    &:hover {
      color: $white-color;
    }
  }
  &.border_btn {
    @extend .justify_content_center;
    @extend .radius_8;
    @extend .text_center;
    &:hover {
      border: 0.2rem solid transparent;
      color: $white-color;
    }
    &.add_btn {
      color: $white-color;
      background: $grey-100;
      border: transparent;
      &.add_plus {
        @include apply_bgcolor(Light_blue_clr);
      }
    }
  }
  &.unselect_btn {
    @include d_flex_center;
    &:hover {
      color: $form-lable-color;
      &::after {
        opacity: 0;
      }
    }
  }
  &.dark_blue_btn {
    background-color: $dark-blue !important;
    color: $white-color;
  }
  &.download_csv_event {
    padding-left: 4rem;
    padding-right: 4rem;
    min-height: 4.4rem;
  }

  @include apply_btn(primary_btn);
  @include apply_btn(resend_btn);
  @include apply_btn(
    danger_btn,
    (
      justify-content: center,
    )
  );
  @include apply_btn(assign_btn);
  @include apply_btn(border_btn);
  @include apply_btn(danger_btn);
  @include apply_btn(unselect_btn);
  @include apply_btn(gray_btn);
  &.save_btn {
    color: $white-color;
    background: $grey-text;
    &.blue_btn {
      @include apply_bgcolor(Light_blue_clr);
    }
  }
}

.preview_button {
  border-radius: 6px !important;
  width: fit-content !important;
  min-width: 120px !important;
  height: 44px !important;
  min-height: 44px !important;
  max-height: 44px !important;
  padding: 10px 16px 10px 16px !important;
  border: 1px solid #1373e7;
  background-color: transparent;
  font-family: Montserrat_semibold, sans-serif !important;
  color: #1373e7;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
  margin-left: auto;
}

.send_button {
  border-radius: 6px !important;
  width: fit-content !important;
  height: 44px !important;
  min-height: 44px !important;
  max-height: 44px !important;
  padding: 10px 16px 10px 16px !important;
  background-color: #1373e7;
  color: white;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
  gap: 5px !important;

  &.disabled_button {
    background-color: #e6e6e6;
  }

  .send_button_icon {
    width: 18px;
    height: 18px;
    margin-right: 0 !important;
  }
}

.upload_img {
  @include button;
  font-size: 1.4rem;
  line-height: 140%;
  max-width: 18.7rem;
  color: $form-lable-color;
  @include w_100;
  border: 0.2rem solid $primary-color;
  margin: 0;
  min-height: 4.8rem;
}

.blue_border_btn {
  @include fs_16;
  @include fw_700;
  line-height: 150%;
  min-width: 27.4rem;
  color: $form-lable-color;
  @extend .text_center;
  @extend .radius_8;
  background: $white-color;
  border: 0.2rem solid $Light-blue-clr;
  padding: 1.2rem 2.4rem;
  @extend .justify_content_center;
  @extend .text_capitalize;
  @extend .cursor_pointer;
  @include trans;
  &:hover {
    @include apply_bgcolor(Light_blue_clr);
    border: 0.2rem solid transparent;
    color: $white-color;
  }
  &.add_btn {
    color: $white-color;
    background: $grey-text;
    border: transparent;
    &.add_plus {
      @include apply_bgcolor(Light_blue_clr);
    }
  }
}

button {
  &.clear_blue_btn {
    @include fs_16;
    @include fw_700;
    color: $primary-color;
  }
}

button {
  &:hover {
    .loader {
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
    .btn_loader {
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
  &.upload_img {
    .loader {
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
    .btn_loader {
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
  &.btn {
    .loader {
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
    .btn_loader {
      img {
        filter: brightness(0) invert(1) !important;
      }
    }
  }
}

.link_btn {
  color: $secondary-color;
  font-style: italic;
  text-decoration: underline;
}

.markSafeButton {
  @extend .btn;
  background-color: $white-color;
  color: $flagged-message-dark-green;
  border-color: $flagged-message-dark-green;
  border: solid;
  margin-right: 1rem;
  &:hover {
    background-color: $flagged-message-dark-green;
  }
  &:after {
    background-color: $flagged-message-dark-green;
  }
}

.markUnsafeButton {
  @extend .btn;
  background-color: $white-color;
  color: $flagged-message-dark-red;
  border-color: $flagged-message-dark-red;
  border: solid;
  &:hover {
    background-color: $flagged-message-dark-red;
  }
  &:after {
    background-color: $flagged-message-dark-red;
  }
}
