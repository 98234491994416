.events_details_wrap{
    padding: 5rem 1.5rem 15rem;
    .event_details_page{
        max-width: 118.5rem;
        @include w_100;
        padding: 0 1.7rem;
        transform: translate(0px, 0px);
        .ed_page-header{
            margin: 0 0 2.4rem;
            h5{
                @extend .fs_32;
                line-height: 1.1;
                @extend .montserrat_semibold;
                color: $black-color;
            }
            .edit_events_btn{
                border-radius: 1rem;
                background: #e7f1fd;
                padding: 0 1.6rem;
                min-height: 4rem;
                color: $form-lable-color;
                @include fs_16;
                @extend .fw_500;
                line-height: 140%;
                max-width: 15.6rem;
            }
        }
        .events_wrapper{
            .image{
                height: 26.5rem;
                border-radius: 0.8rem;
                @include over_hidden;
                margin: 0 0 2.4rem;
                img{
                    @include w_100;
                    @include h_100;
                    @extend .img_object_center;
                }
            }
            .content_area{
                h4{
                    @extend .montserrat_semibold;
                    @extend .fs_18;
                    line-height: 1.2;
                    color: $black-color;
                }
                .event_time_popup{
                    @include d_flex;
                    @extend .align_items_center;
                    > span{
                        color: $primary-color;
                        @include fs_16;
                        line-height: 1.2;
                        margin: 0.5rem 0;
                    }
                    .table_timezone{
                      .name_toolkit {
                        @include position_relative;
                        .time_zone_label{
                          line-height: normal;
                          background: $gray-100;
                          margin-left: 8px;
                        }
                        .full_username{
                          color: $white-color;
                          @extend .fs_14;
                          @include fw_400;
                          line-height: 140%;
                          padding: 0.8rem 1rem;
                          @extend .radius_4;
                          background: $dark-bg;
                          @extend .d_flex_align;
                          gap: 1rem;
                          @include position_absolute;
                          top: -3.7rem;
                          display: none;
                          @extend .white_space_nowrap;
                          left: unset ;
                          right: 50% !important;
                          transform: translate(50%, 0px);
                        }
                        &:hover {
                          .full_username {
                            @include d_flex;
                          }
                        }
                      }
                    }
                  }

                .desc{
                    p{
                      color: $light-gray;
                      @extend .fs_14;
                      line-height: 1.5;  
                      @extend .montserrat_medium;
                      span{
                        color: $primary-color;
                        font-style: italic;
                        margin-left: 0.5rem;
                      }
                    }
                }
                .desc_card_group{
                    margin: 2.4rem 0;
                    gap: 1.6rem;
                    &.rsvp_part{
                        .desc_card{
                            h3{
                                margin: 0 0 0.4rem;
                                @extend .text_capitalize;
                            }
                        }
                    }
                }
                .desc_card{
                    border-radius: 0.8rem;
                    background: $white-color;
                    padding: 2.5rem;
                    border-radius: 0.8rem;
                    border: 1px solid $border-color;
                    box-shadow: 0px 0px 40px rgba(36, 73, 137, 0.15);
                    h3{
                        @extend .fs_20;
                        line-height: 1.2;
                        @extend .montserrat_medium;
                        margin: 0 0 1.5rem;
                    }
                    h2{
                        @extend .fs_24;
                        color: $primary-color;
                        line-height: 1.3;
                        @extend .montserrat_bold;
                    }
                    .inner_date_time{
                        img{
                            margin-right: 0.8rem;
                        }
                        span{
                            color: $light-gray;
                            &:not(:last-child){
                                margin: 0 0 1.6rem;
                            }
                        }
                        .date,
                        .time{
                            width: 100%;
                            @extend .align_items_center;
                        }
                    }
                    .by_name{
                        margin: 1.5rem 0 0;
                        color: $light-gray;
                        .by_name_img{
                            width: 4rem;
                            height: 4rem;
                            @extend .radius_round;
                            @include over_hidden;
                            margin-right: 1.6rem;
                            img{
                                @include w_100;
                                @include h_100;
                                @extend .img_object_center;

                            }
                        }
                    }
                    .meet_link{
                        .google_link{
                            width: 4rem;
                            height: 4rem;
                            @extend .radius_round;
                            background-color: $form-input-active-bg;
                            margin-right: 1.6rem;
                            + span{
                                color: $primary-color;
                            }
                        }
                        
                    }
                }
                .event_details_table{
                    box-shadow: 0px 0px 40px rgba(36, 73, 137, 0.08);
                    border-radius: 0.8rem;
                    .event_details_table{
                         .table_wrapper.calenderPopUp_wrapper .table_responsive {
                            border-radius: 0 0 0.8rem 0.8rem;
                         }
                    }
                }
            }
        }
    }
}