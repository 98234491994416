.partners_page {
  .comman_head {
    .main-head {
      .select_user {
        margin-top: 0;
      }
    }
  }
  .modal_comman {
    .add_partner {
      max-width: 72.6rem;
      .form-wrapper {
        form {
          max-width: 100%;
          .form-steps {
            margin-bottom: 0;
            .form_group {
              .type_school {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .table_wrapper {
    .table_responsive {
      .table {
        tbody {
          tr {
            td {
              .name_toolkit {
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}

.partner_modal {
  width: 100%;
  max-width: 73.2rem;
  @extend .m_auto;
  @extend .radius_20;
  @extend .position_fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white-color;
  box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
  z-index: 1;
  transition: all 0.6s ease-in-out;
  opacity: 1;
  animation: partnerModal 0.7s ease-in-out forwards;
  @keyframes partnerModal {
    0% {
      opacity: 0;
      transition: all 0.6s ease-in-out;
    }
    100% {
      opacity: 1;
      transition: all 0.6s ease-in-out;
    }
  }
  .close_btn {
    @include position_absolute;
    top: 1.6rem;
    right: 3.2rem;
  }
  .assign_institution {
    border-bottom: 0.1rem solid $border-color;
    padding-bottom: 1.6rem;
    padding: 2.4rem;
    border-radius: 20px 20px 0px 0px;
    background: $white-color;
    box-shadow: 2px 2px 4px 0px rgba(125, 125, 125, 0.08);

    .assign_new_school {
      @extend .d_flex_align;
      @include fs_16;
      @include fw_700;
      gap: 3.2rem;
      line-height: 23px;
      color: $tab-head;
      padding-left: 1.6rem;
      margin-bottom: 2.4rem;
      @extend .text_capitalize;
      .assign_new {
        @include d_flex_center;
        @extend .fs_14;
        @include fw_700;
        line-height: 140%;
        color: $primary-color;
        @extend .cursor_pointer;
        gap: 1.2rem;
      }
      .blue_plus_icon {
        @include d_flex_center;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
    h4 {
      @include fs_16;
      @include fw_700;
      gap: 3.2rem;
      line-height: 22px;
      color: $tab-head;
      @extend .text_capitalize;
      .schoo_name {
        @include d_flex;
        @extend .align_items_center;
      }
    }
  }

  .school_list {
    max-height: 41rem;
    @extend .overflow_auto;
    width: 100%;
    li {
      width: 100%;
      @extend .d_flex_align;
      min-height: 6.2rem;
      border-bottom: 0.1rem solid $border-color;
      &:hover {
        background: $table-hover;
      }
      &:first-child {
        .check_with_name {
          .schoo_name {
            .list_remove {
              &:hover {
                border: 0.1rem solid $primary-color;
                .tooltip {
                  @include d_block;
                  left: -9rem;
                  top: 0;
                }
              }
            }
          }
        }
      }
      .check_with_name {
        width: 100%;
        padding: 0.8rem 2.4rem;
        @extend .d_flex_align;

        .schoo_name {
          @extend .d_flex_align;
          @extend .justify_content_between;
          @include fs_16;
          @include fw_400;
          line-height: 43px;
          color: $tab-text;
          @extend .text_capitalize;
          width: calc(100% - 0rem);
          .list_remove {
            @include d_flex_center;
            width: 4rem;
            height: 4rem;
            @extend .radius_round;
            @extend .cursor_pointer;
            @include position_relative;
            border: 0.1rem solid transparent;
            &:hover {
              border: 0.1rem solid $primary-color;
              .tooltip {
                @include d_block;
                left: -2.6rem;
                top: -5rem;
              }
            }
          }
          > span {
            .status_tab {
              line-height: normal;
            }
          }
        }
      }
    }
  }
  .assign_btn_group {
    @extend .montserrat_bold;
    gap: 0.8rem;
    padding: 1.6rem 3.2rem;
    background: $white-color;
    border-radius: 0px 0px 20px 20px;
    box-shadow: -8px -8px 8px 0px rgba(139, 125, 125, 0.08);
  }
  &.resion_modal_list {
    .assign_institution {
    }
    .school_list {
      max-height: 41rem;
    }
  }
}

.edit_partner {
  @include w_100;
  .edit_title_btn {
    @include d_flex_center;
    @extend .justify_content_between;
    padding: 1.2rem 1rem 1.2rem 3.2rem;
    margin-bottom: 2.4rem;
    .edit_partner_title {
      @include d_flex_center;
      gap: 0.8rem;
      @extend .fs_18;
      @extend .montserrat_medium;
      img{
        width: 2.4rem;
        height: auto;
      }
    }
    .add_btn_group {
      .blue_border_btn {
        @include d_flex_center;
        min-width: 16.7rem;
        padding: 1rem 0.4rem;
        gap: 1.2rem;
        &:hover {
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
  .edit_details {
    padding-left: 3.8rem;
    width: 100%;
    max-width: 55.2rem;
    h3 {
      @extend .fs_24;
      @extend .fw_600;
      line-height: 110%;
      color: $black-color;
      margin-bottom: 3.2rem;
    }
    .edit_partner_deatils {
      border-bottom: 0.1rem solid $grey-100;
      padding-bottom: 1.8rem;
      margin-bottom: 4.2rem;
      .edit_info {
        @extend .d_flex_align;
        padding-bottom: 2.4rem;
        gap: 1.5rem;
        .edit_name_date {
          width: 50%;
          .name_title {
            @extend .fs_14;
            @include fw_700;
            line-height: 140%;
            color: $tab-text;
            margin-bottom: 0.4rem;
          }
          .edit_name {
            @include fs_16;
            @include fw_700;
            line-height: 150%;
            color: $form-input-color;
          }
        }
      }
    }
    .edit_assign_school_info {
      border-radius: 8px;
      background: rgba(231, 241, 253, 0.5);
      padding: 1.6rem;
      h4 {
        @include fs_16;
        @include fw_700;
        line-height: 150%;
        color: $form-lable-color;
        border-bottom: 0.1rem solid #dceafb;
        padding-bottom: 2.4rem;
        margin-bottom: 2.4rem;
      }
      ul {
        li {
          @include fs_16;
          @extend .fw_500;
          color: $dark-blue;
          line-height: 140%;
          margin-bottom: 2.8rem;
        }
      }
    }
  }
  &.group_details{
    .edit_title_btn{
      padding: 1.2rem 3.2rem;
    }
  }
}

.partner_tooltip {
  &:hover {
    .tooltip {
      @include d_block;
      top: -5.6rem;
    }
  }

  .tooltip {
    color: $white-color !important;
    text-transform: initial;
  }
}
