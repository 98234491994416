.mentees_page {
  .i_mentor_details_page {
    .all_info_details {
      .form-steps {
        .form_group {
          margin-bottom: 2.5rem;
          label {
            color: $tab-text;
            margin-bottom: 1.2rem;
          }
          table {
            tr {
              .radio-box {
                .radio-info {
                  [type="radio"]:checked + label {
                    outline: 0;
                  }
                }
              }
            }
          }
          .language {
            ul {
              gap: 1.6rem;
              li {
                border-radius: 3rem;
                background: $grey-100;
                padding: 0.75rem 1.21rem;
                @include fw_400;
                font-size: 1.2rem;
              }
            }
          }
          .option_group {
            row-gap: 1rem;
            .selected_value {
              &.option {
                @include position_relative;
                @include w_100;
                img {
                  @include d_none;
                }
                &::after {
                  content: "";
                  @include position_absolute;
                  @include apply_bgcolor(Light_blue_clr);
                  border-radius: 50%;
                  width: 0.8rem;
                  height: 0.8rem;
                  left: 0.8rem;
                }
                p {
                  padding-left: 1.8rem;
                }
              }
              &.text {
                p {
                  @extend .fw_500;
                  font-size: 1.4rem;
                  max-width: 59.7rem;
                  line-height: 140%;
                }
              }
            }
          }
        }
      }
    }
    .unselect_btn {
      border: 0.2rem solid $primary-color !important;
      &:first-child {
        margin-right: 0;
      }
    }
  }
  .personal_info {
    .form-wrapper {
      padding-top: 0;
    }
  }

  .toolkit_date {
    .date_toolkit {
      top: -3.5rem;
    }
    &:hover {
      .date_toolkit {
        top: -3rem;
      }
    }
  }
  .table_wrapper {
    .table_responsive {
      .table {
        overflow: auto;
        @extend .mentees_table_width;
        td {
          &.checkbox_hidden {
            @include d_none;
          }
          &:first-child {
            width: 4%;
          }
          &:nth-child(3) {
            width: 12%;
          }
          &:nth-child(5) {
            width: 12%;
          }
          &:nth-child(6) {
            width: 12%;
          }
          &:nth-child(7) {
            width: 14%;
          }
          &:nth-child(8) {
            width: 8%;
          }

          .name_toolkit {
            .full_username {
              right: -10rem;
              left: auto;
            }
          }
        }
        tbody {
          tr.last_three {
            &:nth-child(2) {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: unset;
                }
              }
            }
          }
          tr.first_one {
            &:first-child {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: auto;
                  @include position_absolute;
                }
              }
            }
          }
        }
        .thead {
          .down_arrow {
            @include position_relative;
            border-radius: 10rem;
            width: 2.4rem;
            height: 2.4rem;
            @extend .text_center;
            @include d_flex_center;
            border: 0.1rem solid transparent;
            &:hover {
              border: 0.1rem solid $primary-color;
              .tooltip {
                @include d_block;
                left: auto;
                right: -4rem;
              }
              .tooltip_descending {
                @include d_none;
              }
              &.descending {
                .tooltip_ascending {
                  @include d_none;
                }
                .tooltip_descending {
                  @include d_block;
                }
              }
            }
          }
        }
        &.mentee {
          tr {
            td {
              .name_toolkit {
                &:hover {
                  .full_username {
                    left: 0;
                    right: auto;
                    @extend .white_space_nowrap;
                    z-index: 9;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .type_school {
    margin-top: 0.8rem;
    @include d_block;
    @extend .fs_14;
    @include fw_400;
    color: $form-input-color;
    > b {
      @include fw_700;
    }
  }
  .filter_dropdown {
    padding-right: 0;
    .raect_dropdown {
      .css-13cymwt-control {
        min-height: 4.8rem;
      }
      .css-t3ipsp-control {
        min-height: 4.8rem;
      }
    }
    > div {
      .form_group {
        @include position_relative;
        .err_field {
          @include position_absolute;
          top: 6.5rem;
          right: 1.3rem;
          background: $white-color;
          padding: 0.3rem;
        }
        &.react_common_error {
          .err_field {
            top: 4.3rem;
          }
        }
      }
    }
  }
  .form-wrapper {
    form {
      .form-steps {
        .form_group {
          .select_contry_list {
            .list_school {
              scrollbar-width: thin;
              &.edit_option_list {
                height: 22rem;
              }
            }
          }
        }
      }
    }
  }

  .table_wrapper {
    .table_responsive {
      .table.mentee {
        tr.last_three {
          &:nth-last-child(-n + 2) {
            .dot_icon_modal {
              .dot_menu_modal {
                bottom: 0 !important;
              }
            }
          }
          &:nth-last-child(-n + 3) {
            .dot_icon_modal {
              .dot_menu_modal {
                bottom: 0 !important;
              }
            }
          }
          &.first_one {
            .dot_icon_modal {
              .dot_menu_modal {
                bottom: auto !important;
                @extend .position_fixed;
              }
            }
          }
        }
        tr {
          td {
            &:nth-child(4) {
              width: 15%;
            }
          }
        }
      }
    }
  }
  .form_group {
    &.statue_tabing {
      .options_status {
        .all_location {
          .list_status {
            .select_tab {
              .region_tab {
                
              }
            }
          }
        }
      }
    }
  }
  .custom-checkbox {
    [type="checkbox"] {
      margin-right: -1.2rem;
    }
    .checkmark {
      &::after {
        left: 0 !important;
      }
    }
  }
}

.toolkit_date {
  @include position_relative;
  .date_toolkit {
    display: none;
    @include trans;
    border-radius: 0.4rem;
    background: $dark-bg;
    @include fw_400;
    color: $white-color;
    @include position_absolute;
    top: -4.9rem;
    left: auto;
    right: 0;
    padding: 1rem;
  }
  &:hover {
    .date_toolkit {
      @include d_block;
      @include trans;
      z-index: 9;
    }
  }
}

.header_sticky {
  + .add_mentors_user {
    padding-top: 16rem;
    padding-bottom: 46rem;
  }
}

.react_common_error,
.error_msg {
  .raect_dropdown {
    .css-13cymwt-control {
      border: 0.2rem solid $error-msg !important;
      input {
        border: 0 !important;
      }
    }
    .css-t3ipsp-control {
      border: 0.2rem solid $error-msg !important;
      box-shadow: none !important;
      input {
        border: 0 !important;
      }
    }
  }
}

.i_mentor_details_page {
  .main_m_tab {
    .mentor_all_info {
      .container {
        .m_staus_date {
          &.border_bottom {
            border-top: 0;
          }
        }
      }
      .mentor_name_detail_main {
        max-width: 38.1rem;
        border-radius: 0.8rem;
        background: rgba(231, 241, 253, 0.5);
        padding: 0.8rem 2.4rem;
        .mentor_img_detail {
          .user_pic {
            padding: 0;
            width: 6.4rem;
            height: 6.4rem;
            border: 1.455px solid $white-color;
          }
        }
        .mentor_name_detail {
          p {
            color: $form-input-color;
          }
          span {
            color: $form-input-color;
            @extend .white_space_nowrap;
            @extend .text_ellipsis;
            max-width: 23rem;
            @include d_block;
            @include over_hidden;
          }
        }
      }
    }
  }
}

.add_mentors_user {
  &.edit_mentors_user {
    .custom-checkbox {
      .checked {
        width: 3rem;
        height: 3.6rem;
        @include position_absolute;
        opacity: 0;
        z-index: 999;
        top: -0.1rem;
        @extend .cursor_pointer;
        + span {
          @extend .cursor_pointer;
          &::before {
            margin-left: 0;
            width: 2.6rem !important;
            height: 2.6rem !important;
          }
          &::after {
            left: 0;
            top: -0.4rem;
          }
          &.checkmark {
            width: 2.7rem;
            height: 2.7rem;
            @extend .d_inline_block;
          }
        }
        &.mentees_checked {
          + label {
            &::before {
              margin-left: 0;
              width: 2.5rem;
              height: 2.5rem;
            }
          }
          + label {
            &::after {
              left: 0;
              top: -0.3rem;
              width: 0.9rem;
              height: 1.9rem;
            }
          }
        }
        [type="checkbox"]:checked {
          + label {
            + .checkbox_que_option {
              color: $form-lable-color;
              @include fw_700;
              @extend .fs_14;
            }
          }
        }
      }
      .checkbox_que_option {
        @include d_block;
        margin: -0.4rem 0 0 0;
      }
    }
  }
}
