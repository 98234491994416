.add_partner_user {
  padding: 1.6rem 3.2rem 12rem;
  h2 {
    font-size: 24px;
    @extend .fw_600;
    line-height: 110%;
  }
  .form-wrapper {
    padding-top: 4.8rem;
    form {
      .form-steps {
        .step-info {
          p {
            span {
              color: $light-gray;
              font-size: 1.2rem;
            }
          }
        }
        .step-info-box {
          margin-top: 2rem;
        }
        .form_group {
          @include position_relative;
          input {
            &::placeholder {
              color: $form-input-color;
            }
          }
          .upload-btn {
            @include position_absolute;
            top: 3.4rem;
            right: -5rem;
            border-radius: 10rem;
          }
          .comman_head {
            padding: 0;
          }
        }
        .type_school_name {
          color: $form-input-color;
          @extend .fs_14;
          line-height: 140%;
          padding-top: 0.8rem;
          span {
            @extend .montserrat_bold;
          }
        }

        .add_logo_upload {
          .upload_file, .up_box_file {
            @extend .d_flex_align;
            gap: 2.4rem;

            .up_file, .up_box_file {
              @include apply_bgcolor(dark_blue);
              @include position_relative;
              overflow: hidden;
              @include d_flex_center;
              border: 0.1rem solid $border-color;
              
              .uploaded {
                opacity: 0;
                @include position_absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                @extend .radius_round;
                height: 100%;
                @extend .cursor_pointer;
              }

              .loader_bg {
                background: transparent;
                z-index: 0;

                img {
                  width: auto;
                  height: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }

              &.up_profile_pic {
                img {
                  @extend .img_object_center;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }

            .up_file {
              width: 24.5rem;  // Larger size
              height: 10rem;
            }

            .up_box_file {
              width: 55px;
              height: 55px;
            }

            .up_file_name {
              .file_name {
                color: $dark-bg;
                @extend .fs_18;
                @extend .fw_500;
                line-height: 140%;

                span {
                  @include d_block;
                  color: $light-gray;
                  @extend .fs_14;
                  @include fw_400;
                  line-height: 140%;
                  padding-top: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .partner_last_drop_down {
    .select_contry_list {
      scrollbar-width: thin;
      .form_control {
        background: url(../../../public/images/filter_drop_down_arrow.svg) no-repeat center
          right 1.6rem $white-color;
        @extend .cursor_pointer;
      }
      .add_option_list {
        &.open {
          padding-bottom: 17rem;
        }
      }
    }
  }
}

.form_group {
  .geosuggest__suggests-wrapper {
    @include position_relative;
    .all_location {
      background: $white-color;
      max-height: 23.5rem;
      overflow: auto;
      border-radius: 0.4rem;
      border: 0;
      box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
      @include position_absolute;
      top: 0.6rem;
      width: 100%;
      z-index: 99;
      .geosuggest__item {
        &.geosuggest__item--active {
          background: #b6d4f8;
        }
        @extend .d_flex_align;
        padding: 0.8rem;
        min-height: 5.5rem;
        color: $tab-text;
        @include fs_16;
        @extend .fw_500;
        line-height: 140%;
        &:hover {
          background: #b6d4f8;
        }
      }
    }
  }
  &.border_sky {
    .geosuggest__suggests-wrapper {
      .all_location {
        border: 0.1rem solid #bdbdbd;
        &.geosuggest__suggests--hidden {
          border: 0;
        }
      }
    }
  }
}
