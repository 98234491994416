.loginPage {
  width: 100%;
  min-height: 100vh;
  background-position: right;
  background-size: contain;
  @include position_relative;

  .mobile_logo{
    display: none;
    padding: 2.5rem 0 0;
    @include media_query(medium) {
      display: block;
    }

    img{
      margin: 0 auto;
    }
  }

  &::after {
    content: "";
    @include d_block;
    width: 50%;
    height: 100%;
    background: url(../../../public/images/login_bg.png) no-repeat;
    background-size: cover;
    @include position_absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include media_query(medium) {
      display: none;
    }
  }

  .container {
    width: 100%;
    max-width: 144rem;
    margin: auto;
    padding: 0 1.5rem;

    .login_form_content {
      @include d_flex_center;

      .login {
        width: 100%;
        max-width: 50%;
        background: $white-color;
        @include media_query(medium) {
          max-width: 100%;
        }

        &.content{
          padding-right: 1.5rem;
          @include media_query(medium) {
            padding: 2.4rem 0 0;
          }
        }

        &.managment {
          padding-top: 8rem;
          padding-left: 6.4rem;
          background: transparent;
          @include media_query(medium) {
            display: none;
          }
          .manag_title {
            @extend .fw_500;
            @extend .fs_40;
            line-height: 4.9rem;
            color: $black-color;
            margin-bottom: 8.1rem;
          }

          .manag_content {
            @extend .fw_500;
            @include fs_16;
            line-height: 2rem;
            color: $tab-text;
            margin-bottom: 6.4rem;
            max-width: 47.2rem;
          }

          span {
            img {
              width: 100%;
            }
          }
        }

        form {
          width: 100%;
          max-width: 362px;
          margin: 0 auto;
          @include media_query(medium) {
            max-width: 346px;
          }

          h2 {
            @extend .fw_600;
            @extend .fs_40;
            @extend .mb_15;
            @extend .text_center;
            line-height: 4.9rem;
            color: $black-color;
            @include media_query(medium) {
              text-align: left;
            }
          }

          .log_details {
            @extend .fs_14;
            line-height: 1.7rem;
            font-style: normal;
            @extend .fw_600;
            @extend .fs_14;
            color: $light-gray;
            margin-bottom: 3.2rem;
            @include media_query(medium) {
              text-align: left;
            }
          }

          .form_group {
            label {
              @include fs_16;
              line-height: 2.2rem;
              @include d_block;
              @extend .fw_600;
              color: $tab-text;
              margin-bottom: 0.8rem;
            }

            .form_control {
              border: 0.1rem solid #bdbdbd;
              padding-right: 4.3rem;
              &::placeholder {
                opacity: 60%;
                color: $form-input-color;
              }
            }

            .btn {
              width: 100%;
              @extend .inter_bold;
              @include fw_700;
              @include fs_16;
              line-height: 2.2rem;
              color: $white-color;
              min-height: 5.5rem;
              @extend .justify_content_center;
            }

            .forgot_pass {
              @include d_block;
              @extend .fw_600;
              @extend .fs_14;
              line-height: 1.7rem;
              color: $light-gray;
              margin-top: 0.5rem;
              a {
                color: $light-gray;
              }
            }

            .account_yet {
              @extend .fw_500;
              @include fs_16;
              line-height: 1.8rem;
              color: $light-gray;

              a {
                @extend .d_inline_block;
                @include fw_700;
                color: $primary-color;
                margin-top: 1.8rem;
                @extend .cursor_pointer;
              }
            }
            .border_red {
              border: 0.1rem solid $red-required;
            }
            .border_green {
              border: 0.2rem solid $success-msg-green;
            }
            .eye_pass {
              @extend .cursor_pointer;
              @include position_absolute;
              top: 4.5rem;
              right: 1.7rem;
              &.not_visible {
                @include d_none;
              }
            }
            .comple_pass {
              color: $light-gray;
              @extend .fs_14;
              @extend .fw_500;
              line-height: 18px;
              margin-top: 0.9rem;
            }
          }

          .copyright {
            @extend .fw_600;
            @extend .fs_14;
            line-height: 1.7rem;
            color: $light-gray;
            @extend .text_center;
            margin-top: 13.4rem;
            @include media_query(medium) {
              margin: 0;
              padding: 4rem 0;
            }
          }
        }
      }
    }
  }
}
