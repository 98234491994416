.comman_head {
  padding: 1.25rem 3.2rem;

  .table_wrapper {
    padding: 0;
  }

  .pagination {
    padding: 0;
  }

  h3 {
    color: $black-color;
    @extend .fs_18;
    @extend .fw_500;
    line-height: 140%;
    @extend .white_space_nowrap;
  }

  ul.user_info {
    @include d_flex_center;
    @extend .radius_8;
    border: 0.2rem solid $primary-color;
    padding: 1.2rem 2.4rem;
    gap: 4rem;

    li {
      @include d_flex_center;
      @include fs_16;
      @include fw_700;
      line-height: 140%;
      color: $black-color;
      @extend .white_space_nowrap;
      @extend .text_center;
      @extend .cursor_pointer;

      .icon_select {
        width: 2.4rem;
        height: 2.4rem;

        .black_icon {
          img {
            filter: brightness(0);
          }
        }

        &.gray_icon {
          img {
            filter: grayscale(1.3);
          }
        }
      }

      .blue_text {
        margin-left: 1.2rem;
        color: $primary-color;
        line-height: 2.4rem;
      }

      .gray_text {
        color: $grey-text;
        @include fw_700;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-left: 1.2rem;
      }

      .select_list_user {
        @extend .d_inline_block;
        @include position_relative;

        &::after {
          content: '';
          @include d_block;
          width: 0.1rem;
          height: 100%;
          background: $separtor-color;
          @include position_absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -3.2rem;
          cursor: default;
        }
      }

      &.tool_full_name {
        @include position_relative;

        &:hover {
          .tooltip {
            @include d_block;
            top: -5rem;
            left: -10rem;
          }
        }
      }
      &:last-child {
        .select_list_user {
          &::after {
            @include d_none;
          }
        }
      }
    }
  }

  ul.active_tabs {
    @include d_flex_center;
    gap: 0.8rem;

    li {
      @include d_flex_center;
      @extend .fs_18;
      @extend .fw_500;
      line-height: 140%;
      border-radius: 0.8rem 0.8rem 0.2rem 0.2rem;
      color: $tab-text;
      background: transparent;
      padding: 0.8rem 1.5rem;
      min-height: 4.7rem;
      @include w_100;
      min-width: 21.2rem;
      @extend .white_space_nowrap;
      @extend .text_center;
      @extend .cursor_pointer;
      border-bottom: 0.4rem solid transparent;

      &.active {
        @include fw_700;
        color: $secondary-color;
        background: $form-input-active-bg;
        border-bottom: 0.4rem solid $primary-color;
      }
    }
  }

  .comman_menu {
    @include w_100;
    @include d_flex_center;
    @include justify_content_end;
    gap: 1.6rem;

    .filter_menu {
      @include position_relative;
      padding: 0.8rem;
      border-radius: 10rem;
      border: 0.2rem solid transparent;

      .filter_icon {
        width: 2.4rem;
        height: 2.4rem;
        @include d_flex_center;
      }

      &:hover {
        .full_username {
          @include d_flex;
        }
      }

      .full_username {
        color: $white-color;
        @extend .fs_14;
        @include fw_400;
        line-height: 140%;
        padding: 0.8rem 1rem;
        @extend .radius_4;
        background: $dark-bg;
        @extend .d_flex_align;
        gap: 1rem;
        @include position_absolute;
        top: -3.7rem;
        left: -2rem;
        display: none;
        @extend .white_space_nowrap;

        img {
          width: 1.6rem;
          height: 1.6rem;
          @extend .img_object_center;
          @include d_none;
        }
      }

      &.active {
        border-radius: 10rem;
        border: 0.2rem solid #1168d0;
        background: $white-color;
        box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
      }
    }

    .form_group {
      @include position_relative;
      margin: 0;
      width: 266px;
      height: 46px;
      min-height: 46px;
      max-width: 366px;
      box-shadow: 0 4px 30px 0 #2e2d740d;

      &.admin_serch {
        max-width: 25rem;
      }

      &.partner_serch {
        max-width: 26.95rem;
      }

      .form_control {
        @extend .fw_500;
        line-height: 22px;
        font-size: 14px;
        color: #282828;

        border: 0.5px solid #e3eaf2;
        padding: 12px;
        padding-left: 4.2rem;
        border-radius: 6px;
        background-color: #ffffff !important;
        height: inherit;
        min-height: inherit;
        max-width: inherit;

        &::placeholder {
          @extend .fw_500;
          line-height: 22px;
          font-size: 14px;
          color: #686f7d;
        }
        &:focus {
          border: 0.5px solid #1373e7;
        }
      }

      .zoom_search_icon {
        position: absolute;
        left: 12px;
        top: 10px;
        padding: 6px;
        transition: 0.3s;
        width: 24px;
        height: 24px;
        background: url(../../../public/images/zoom_search_default.svg) center bottom/cover no-repeat;
      }

      &:focus-within {
        .zoom_search_icon {
          background: url(../../../public/images/zoom_search_active.svg) center bottom/cover no-repeat;
        }
      }

      .clear_search_icon {
        @include position_absolute;
        @extend .cursor_pointer;
        right: 0.8rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        width: 23.67px;
        height: 24px;
        background: url(../../../public/images/clear_search_icon_active.svg) center bottom/cover no-repeat;
        opacity: 0.7;
      }

      .clear_search_icon:hover {
        opacity: 1;
      }

      .serch_icon {
        @include d_flex_center;
        width: 2.4rem;
        height: 2.4rem;
        @include position_absolute;
        left: 0.8rem;
        top: 50%;
        transform: translateY(-50%);
      }

      .serch_icon_remove {
        @include d_flex_center;
        width: 2.4rem;
        height: 2.4rem;

        &:hover {
          .tooltip {
            @include d_block;
            top: auto;
            bottom: -4rem;
            left: auto;
            right: 0;
            z-index: 999;
          }
        }
      }
    }

    .edit_btn {
      @include position_relative;

      .border_btn {
        min-width: 20.5rem;
        min-height: 4rem;
        padding: 0.5rem 1.4rem;
      }

      &:hover {
        .tooltip {
          @include d_block;
          top: -3.5rem;
        }
      }
    }

    .add_plus_btn {
      .add_btn {
        padding: 0.7rem 2.7rem;
        min-height: 4rem;
        background: $primary-color;
        @extend .cursor_pointer;
        @extend .radius_8;

        .add_btn_name {
          @include fs_16;
          @include fw_700;
          @extend .white_space_nowrap;
          line-height: 150%;
          color: $white-color;
          margin-inline: 0.5rem;
        }
      }
    }

    .upload-btn {
      @extend .cursor_pointer;
      @include position_relative;

      &:hover {
        .tooltip {
          @include d_block;
          top: -3.6rem;
          left: -13rem;
          padding: 0.9rem 1.8rem;
          z-index: 9999;

          .need_help {
            @include d_flex_center;
            gap: 0.8rem;
            color: #3489ef;
            @extend .text_center;
            @extend .fs_14;
            @include fw_700;
            line-height: 140%;
            padding: 1rem 0;
          }
        }
      }

      .shape {
        width: 4rem;
        height: 4rem;
        border: 0.1rem solid $border-color;
        border-radius: 50px;
        @extend .position-relative;
        background-color: $white-color;
        @include trans;
        padding: 0.5rem;

        &:hover {
          background-color: $secondary-color;
          @include trans;
        }

        &:hover img {
          filter: brightness(0) invert(1);
        }

        &.active {
          border: 0.1rem solid #1168d0;
        }
      }

      &.partner_up_csv {
        &:hover {
          .tooltip {
            left: -5rem;
          }
        }
      }
    }
  }

  ul.react-tabs__tab-list {
    @include d_flex;
    @extend .justify_content_start;
    gap: 0.8rem;
    border: 0;

    li.react-tabs__tab {
      @include d_flex_center;
      @extend .fs_18;
      @extend .fw_500;
      line-height: 140%;
      border-radius: 0.8rem 0.8rem 0.2rem 0.2rem;
      color: $tab-text;
      background: transparent;
      padding: 0.8rem 1.5rem;
      min-height: 4.7rem;
      min-width: 21.2rem;
      @extend .white_space_nowrap;
      @extend .text_center;
      @extend .cursor_pointer;
      border-bottom: 0.4rem solid transparent;
      @include trans;

      &.active {
        @include fw_700;
        color: $secondary-color;
        background: $form-input-active-bg;
        border-bottom: 0.4rem solid $primary-color !important;
        @include trans;
      }
    }

    li.url_tabs {
      @include d_flex_center;
      padding: 0.8rem 0;

      .url_link {
        @include d_flex_center;
        gap: 1.2rem;
        color: $primary-color;
        @include fs_16;
        @extend .fw_600;
        line-height: 2.4rem;
        @extend .white_space_nowrap;
        min-width: 11.6rem;
      }
    }
  }

  &.anouce_modal {
    ul.active_tabs {
      li {
        min-width: unset;
      }
    }
  }
}

.table_wrapper {
  padding: 0;
  margin-top: 2rem;
  padding: 0 3.2rem;
  .count_page {
    @extend .fs_14;
    @extend .montserrat_bold;
    color: $black-color;
    line-height: 140%;

    span {
      @include fw_700;
    }
  }

  .clear_filter {
    @include d_flex_center;
    gap: 1.2rem;
    color: $grey-text;
    @extend .fs_14;
    @include fw_700;
    line-height: 2.4rem;
    margin: 0 0 2.6rem;
    @extend .cursor_pointer;

    svg {
      @extend .cursor_pointer;

      path {
        stroke: $light-gray;
      }
    }

    &.active {
      color: $primary-color;

      svg {
        path {
          stroke: $primary-color;
        }
      }
    }
  }
  .table_responsive {
    @include w_100;
    overflow: auto !important;
    height: auto;
    max-height: 96rem;
    border: 0.1rem solid $border-color;
    border-radius: 0.8rem;
    &.is_Default_Training {
      @extend .border_0;
    }
    .table {
      @include w_100;
      min-width: 120rem;
      border-collapse: separate;
      border-radius: 0.8rem;
      border-spacing: 0;
      table-layout: fixed;
      @include position_relative;
      thead {
        tr {
          @include position($sticky);
          top: -1px;
          background: $white-color;
          left: 0;
          right: 0;
          z-index: 1;
          td {
            border-bottom: 0.1rem solid $border-color;
          }
        }
      }
      &.more_page {
        border-radius: 0.8rem 0.8rem 0 0;

        &.mentors_tablewrap {
          tr {
            td {
              > div {
                &.position_relative {
                  display: inline-grid;

                  &:hover {
                    .tooltip {
                      @include d_block;
                      right: 0;
                      left: auto;
                      @extend .white_space_nowrap;
                    }
                  }
                }
              }
            }
            &.first_one {
              .dot_menu_modal {
                bottom: auto;
              }
              &.last_three {
                .dot_menu_modal {
                  @extend .position_fixed;
                }
              }
            }
          }
        }

        &.reporting_table {
          min-width: 170rem;
          tbody {
            tr {
              td {
                padding: 1.6rem 0.5rem;
              }
            }
          }
          thead {
            tr {
              td {
                .thead {
                  padding: 1.6rem 0.5rem;
                }
              }
            }
          }
          tr {
            td {
              &.w_adjustment {
                width: 1%;
              }

              &:nth-child(2) {
                width: 16%;
              }

              &:nth-child(3) {
                width: 15%;
              }

              &:nth-child(4) {
                width: 10%;
              }

              &:nth-child(5) {
                width: 16%;
              }

              &:nth-child(6) {
                width: 11%;
              }

              &:nth-child(7) {
                width: 13%;
              }

              &:nth-child(8) {
                width: 11%;
              }

              &:nth-child(9) {
                width: 14%;
              }
              &:nth-child(10) {
                width: 12%;
              }
              &:nth-child(11) {
                width: 14%;
              }

              &:last-child {
                .thead {
                  .down_arrow {
                    &:hover {
                      > .tooltip {
                        right: 0;
                        left: auto;
                      }
                    }
                  }
                }
              }

              .position_relative {
                &:hover {
                  > .tooltip {
                    @include d_block;
                    right: auto;
                    left: -3rem;
                    top: -2.9rem;
                  }
                }
              }

              .message_tooltip_hover {
                &:hover {
                  .tooltip {
                    @include d_block;
                  }
                }
              }
            }
          }
        }
      }

      .thead {
        @include fs_16;
        @include fw_700;
        @extend .white_space_nowrap;
        line-height: 150%;
        color: $tab-head;
        padding: 1.6rem 1.2rem;
        @include d_flex;
        @extend .align_items_center;

        .down_arrow {
          @include position_relative;
          border-radius: 10rem;
          min-width: 2.4rem;
          height: 2.4rem;
          @extend .text_center;
          @include d_flex_center;
          border: 0.1rem solid transparent;

          &:hover {
            border: 0.1rem solid $primary-color;

            .tooltip {
              @include d_block;
              top: 2.4rem;
              left: auto;
              right: -2.5rem;
            }

            .tooltip_descending {
              @include d_none;
            }

            &.descending {
              .tooltip_ascending {
                @include d_none;
              }

              .tooltip_descending {
                @include d_block;
              }
            }
          }

          img {
            opacity: 0.4;
          }

          &.short_list {
            img {
              opacity: 1;
            }
          }

          &.descending {
            img {
              @include trans;
              transform: rotate(180deg);
            }
          }
        }
      }

      tbody {
        tr {
          @include position_relative;

          &:hover {
            background-color: $table-hover;
          }

          &.selected {
            background-color: #dceafb;
          }

          &.disabled {
            opacity: 0.85;
          }

          &.last_three {
            &:nth-child(1) {
              .dot_icon_modal {
                @include position_relative;

                .dot_menu_modal {
                  bottom: -5.5rem;
                }
              }
            }

            &:nth-child(2) {
              .dot_icon_modal {
                @include position_relative;

                .dot_menu_modal {
                  bottom: -1rem;
                }
              }
            }

            &.first_one {
              &:nth-child(1) {
                .dot_menu_modal {
                  @extend .position_fixed;
                  bottom: unset;
                }
              }

              &:nth-child(2) {
                .dot_menu_modal {
                  @extend .position_fixed;
                  bottom: unset;
                }
              }
            }
          }

          &.green_row {
            background: rgba(212, 248, 211, 0.22);
          }

          td {
            @include fs_16;
            @extend .fw_500;
            @extend .white_space_nowrap;
            line-height: 2.2rem;
            padding: 1.6rem 1.2rem;
            border-top: 0.1rem solid $border-color;
            color: $tab-text;

            .user_pic {
              @include d_flex_center;
              @extend .fs_10;
              @extend .fw_600;
              width: 2.4rem;
              min-width: 2.4rem;
              height: 2.4rem;
              @extend .radius_round;
              margin-right: 1rem;
              color: $white-color;
              padding: 0;

              &.padding_pic {
                padding: 1rem;
              }

              img {
                width: 2.4rem;
                height: 2.4rem;
                @extend .radius_round;

                &.generate_pic {
                  width: 1.4rem;
                  min-width: 1.4rem;
                  height: 1.4rem;
                  border-radius: 10rem;
                }
              }
            }

            .name_toolkit {
              @include position_relative;
              transition: all 0.8s ease-in-out;
              padding-top: 0;

              &.blank_name {
                @include d_none;
              }

              &:hover {
                .full_username {
                  @include d_flex;
                }
              }

              .user_name {
                @extend .d_inline_block;
                width: auto;
                max-width: 20rem;
                @extend .white_space_nowrap;
                @include over_hidden;
                @extend .text_ellipsis;
                @extend .cursor_pointer;
                border-bottom: 0.1rem solid transparent;
                min-height: 0;
                line-height: 2.1rem;
                color: $tab-text;

                &:hover {
                  @include fs_16;
                  @include fw_700;
                  line-height: 150%;
                  color: $primary-color;
                  border-bottom: 0.1rem solid $primary-color;
                }
                .link_tag {
                  color: $tab-text;
                }
              }

              .full_username {
                @extend .fs_14;
                @include fw_400;
                line-height: 140%;
                padding: 0.8rem 1rem;
                @extend .radius_4;
                background: $dark-bg;
                color: $white-color;
                @extend .d_flex_align;
                gap: 1rem;
                @include position_absolute;
                top: -3.7rem;
                left: 0;
                right: auto;
                z-index: 9;
                display: none;
                @extend .cursor_pointer;
                @extend .white_space_nowrap;

                img {
                  width: 1.6rem;
                  height: 1.6rem;
                  @extend .img_object_center;
                  @include d_none;
                }
              }
            }

            .status_tab {
              @include fs_16;
              @include fw_400;
              line-height: normal;
              color: $tab-head;
              padding: 0.5rem 1.2rem;
              min-height: 3rem;
              background-color: $success-color;
              @extend .radius_6;
              vertical-align: middle;
              @extend .d_inline_block;

              &.active {
                background-color: $success-color;
              }

              &.pending {
                background-color: $grey-color;
              }

              &.inprogress {
                background-color: $light-yellow;
              }

              &.completed {
                background-color: $light-sky;
              }

              &.notstarted {
                background-color: #fbe7e9;
              }

              &.matching_inprogress {
                background-color: #f7dcb4;
                @extend .gap_4;
                @extend .fs_14;
                line-height: 134%;
              }

              &.ready_to_be_matched {
                background-color: #d1f3f1;
              }

              &.matched {
                background-color: $success-color;
              }

              &.invited {
                background-color: $grey-color;
              }

              &.archived {
                background-color: $border-color;
              }

              &.draft {
                background-color: $white-color;
                color: $tab-text;
              }

              &.declined {
                background-color: #ea332333;
                color: $black-color;
              }

              .warning_icon {
                @extend .d_inline_block;
                width: 1.6rem;
                height: 1.6rem;
                vertical-align: middle;
              }

              &.school_inst_tab {
                @extend .fs_14;
                @extend .fw_500;
                line-height: 140%;
                color: $tab-head;
                padding: 0.9rem 1.2rem;
                min-height: 3rem;
                line-height: 140%;
                @extend .radius_30;
                @extend .d_inline_block;
                background: $grey-color;
                color: $black-color;
                margin-bottom: 0.5rem;

                &:nth-child(1) {
                  width: auto;
                  max-width: 50%;
                  @extend .white_space_nowrap;
                  @include over_hidden;
                  @extend .text_ellipsis;
                }

                &:nth-child(2) {
                  width: auto;
                  max-width: 30%;
                  @extend .white_space_nowrap;
                  @include over_hidden;
                  @extend .text_ellipsis;
                }
              }
              &.time_zone_label {
                background: $gray-100;
                margin-left: 8px;
              }
            }

            .som_percentage {
              .status_tab {
                color: $tab-text;
                @include fs_16;
                @include fw_700;
                padding: 0.8rem 1.2rem;
                width: 7.7rem;
                height: 4.8rem;
                @include d_flex_center;
              }
            }

            .day_left {
              padding-right: 0;

              .days_progress {
                @include d_flex_center;
                gap: 0.8rem;
                border-radius: 0.8rem;
                background: transparent;
                padding: 0 1.2rem;
                border: 0 !important;

                .days {
                  @include fs_16;
                  @include fw_700;
                  line-height: 150%;
                  color: $black-color;
                }

                &.progressing {
                  background: #fbf8f1;

                  .progress_clock {
                    @include d_flex_center;
                  }
                }
              }
            }

            .plus_more {
              @extend .fs_12;
              @include fw_700;
              line-height: 140%;
              color: $primary-color;
              text-decoration: underline;
              @extend .cursor_pointer;
            }

            .dot_icon {
              min-height: 4rem;
              @extend .text_right;
              padding-right: 1rem;

              .option_menu {
                width: 2rem;
                height: 2rem;
                margin-left: auto;
                padding: 0.5rem;
                @include d_flex_center;
                @include justify_content_end;
                @extend .cursor_pointer;
                img {
                  min-width: 2.5rem;
                  max-width: 2.5rem;
                  height: 2.4rem;
                }

                &.active {
                  width: 4rem;
                  height: 4rem;
                  border: 0.2rem solid $blue-color;
                  @extend .radius_round;
                  background: $white-color;
                }
              }
            }

            .match_edit_menu {
              .dot_replace_edit_btn {
                @include d_flex_center;
                @include justify_content_end;
                @extend .cursor_pointer;
              }
            }

            .mentore_decide {
              @include d_flex;
              @extend .align_items_center;
              @extend .justify_content_end;
              gap: 2rem;

              .decide_btn {
                @extend .fs_14;
                @include fw_700;
                line-height: 140%;
                @extend .radius_8;
                padding: 1rem 2.4rem;
                background: $white-color;
                border: 0.2rem solid $primary-color;
                @include trans;

                &:hover {
                  background-color: $primary-color;
                  color: $white-color;
                  @include trans;

                  img {
                    filter: brightness(21);
                  }
                }
              }
            }

            .mess_title {
              .title_set {
                color: $secondary-color;
                @extend .fs_12;
                @include fw_700;
                line-height: 140%;
                @include d_block;
                margin-bottom: 0.8rem;
                width: auto;
                max-width: 17.5rem;
                @extend .white_space_nowrap;
                @include over_hidden;
                @extend .text_ellipsis;

                .date_set {
                  @include d_block;
                  color: $tab-text;
                  @include fs_16;
                  @extend .fw_500;
                  line-height: 140%;
                }
              }
            }

            .massege_hover {
              @include position_relative;

              .massges_content {
                color: $tab-text;
                @extend .white_space_wrap;
                @include over_hidden;
                @extend .text_ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                &:hover {
                  .tooltip {
                    @include d_block;
                    top: -3.5rem;
                    left: 0rem;
                    max-width: 45rem;
                    height: auto;
                    word-wrap: break-word;
                    white-space: inherit;
                    @extend .cursor_pointer;

                    &.shoe_msg_tooltip {
                      @include d_flex_center;
                      gap: 1.2rem;
                    }
                  }
                }
              }
            }

            .mentee_status {
              &:hover {
                .tooltip {
                  @include d_block;
                }
              }

              .tooltip {
                left: auto;
                right: 0;
                margin: 0 auto;
                @extend .text_center;
              }
            }
            .table_timezone {
              @include d_flex;
              @extend .align_items_center;
              padding: 0 0 0 2.2rem;
              .name_toolkit {
                .full_username {
                  left: unset !important;
                  right: 50% !important;
                  transform: translate(50%, 0px);
                }
              }
            }
          }
        }
      }

      td {
        &.w_adjustment {
          width: 1%;
        }

        &:first-child {
          width: 4%;
          padding-left: 2rem;
          padding-right: 0;
        }

        &:nth-child(2) {
          width: 14%;
        }

        &:nth-child(3) {
          width: 12%;
        }

        &:nth-child(4) {
          width: 9%;
        }

        &:nth-child(5) {
          width: 16%;
        }

        &:nth-child(6) {
          width: 17%;
        }

        &:nth-child(7) {
          width: 12%;
        }

        &:nth-child(8) {
          width: 5%;
        }
        .inner_tooltip {
          &:hover {
            .tooltip {
              &.reject_reason_tooltip {
                @include d_flex;
                min-width: sum(10rem, 10rem);
                max-width: sum(10rem, 17rem);
                white-space: normal;
              }
            }
          }
        }
      }

      &.school_table_list {
        td {
          &:first-child {
            width: 5%;
            padding-left: 2.2rem;
            padding-right: 0;
          }

          &:nth-child(2) {
            width: 37%;
          }

          &:nth-child(3) {
            width: 22%;
          }

          &:nth-child(4) {
            width: 15%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 10%;
          }
        }
      }

      &.user_data_listing {
        td:nth-child(7) {
          width: 20%;
        }
      }

      &.partners {
        td {
          &:first-child {
            width: 2%;
            padding-left: 0;
            padding-right: 0;

            p {
              display: none;
            }
          }

          &:nth-child(2) {
            width: 22%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 38%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 25%;
          }

          &:nth-child(6) {
            width: 8%;
          }
        }
      }

      &.mentee {
        tr {
          &:nth-last-child(-n + 4),
          &:nth-last-child(-n + 5) {
            .dot_icon_modal {
              .dot_menu_modal {
                bottom: 0;
              }
            }
          }
        }
        td {
          &.w_adjustment {
            width: 1% !important;
          }
        }
      }

      &.mentors {
        td {
          &:first-child {
            width: 6%;
          }

          &.w_adjustment {
            width: 1% !important;
          }

          &:nth-child(2) {
            width: 18%;
          }

          &:nth-child(3) {
            width: 14%;
          }

          &:nth-child(4) {
            width: 16%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 10%;
          }

          &:nth-child(6) {
            width: 18%;
          }

          .join_time {
            display: inline;
            @include position_relative;

            &:hover {
              .tooltip {
                @include d_block;
                top: -6rem;
                left: -8rem;
              }
            }
          }
        }
      }

      &.matches_listing {
        min-width: 140rem;

        td {
          &.checkbox_hidden {
            @include d_none;
          }

          &.w_adjustment {
            width: 1% !important;
          }

          &:first-child {
            width: 5%;
            padding-left: 2.2rem;
            padding-right: 0;
          }

          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(3) {
            width: 12%;
          }

          &:nth-child(4) {
            width: 12%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 16%;
          }

          &:nth-child(6) {
            width: 6%;
          }

          &:nth-child(7) {
            width: 16%;
          }

          &:nth-child(8) {
            width: 5%;
          }

          &:last-child {
            width: 4%;

            .name_toolkit {
              &:hover {
                .full_username {
                  left: auto;
                  right: 0;
                }
              }
            }
          }

          .days {
            @include fs_16;
            @include fw_700;
            color: $tab-text;
          }
        }
      }

      &.announcement_listing {
        td {
          &:first-child {
            width: 23%;
            padding-left: 2.2rem;
            padding-right: 0;
          }

          &:nth-child(2) {
            width: 24%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 22%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 11%;
          }

          .title_user_view {
            .user_pic {
              padding: 0;
            }
          }
        }
      }

      &.select_pairs_Matches {
        min-width: 130rem;

        td {
          &.checkbox_hidden {
            @include d_none;
          }

          &:first-child {
            width: 18%;
            padding-left: 2.2rem;
            padding-right: 0;
          }

          &:nth-child(2) {
            width: 18%;
          }

          &:nth-child(3) {
            width: 15%;
          }

          &:nth-child(4) {
            width: 18%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 12%;
          }

          &:nth-child(6) {
            width: 9%;
          }

          &:nth-child(7) {
            width: 14%;
          }

          &:nth-child(8) {
            width: 7%;
          }
        }
      }

      &.selected_matches_listing {
        td {
          &.w_adjustment  {
            width: 1% !important;
          }

          &:nth-child(2) {
            width: 16%;
          }

          &:nth-child(3) {
            width: 14%;
          }

          &:nth-child(4) {
            width: 14%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 14%;
          }

          &:nth-child(6) {
            width: 10%;
          }

          &:nth-child(7) {
            width: 19%;
          }
          &:nth-child(8) {
            width: 0;
          }
          &:nth-child(9) {
            width: 6%;
          }
        }

        &.disable_table {
          tbody {
            background: #f6f6f6;

            div,
            .dot_icon {
              opacity: 0.6;
            }
          }
        }
      }

      &.pairs_user_listing {
        td {
          .thead {
            white-space: normal;
          }
          &.w_adjustment {
            width: 2% !important;
          }

          &:first-child {
            width: 2%;
            padding-left: 2.2rem;
            padding-right: 0;
          }

          &:nth-child(2) {
            width: 8%;
            .pair_user_list {
              .full_username {
                pointer-events: none;
              }
              .user_mentee_icon {
                margin-left: 1.4rem;
              }
              &:not(:last-child) {
                margin: 0 0 10px;
              }
            }
          }

          &:nth-child(3) {
            width: 16%;
          }

          &:nth-child(4) {
            width: 7%;
          }

          &:nth-child(5) {
            width: 7%;
          }

          &:nth-child(6) {
            width: 3%;
          }
        }
        .pr_message_info {
          @include d_flex;
          @extend .align_items_start;
          @extend .flex_direction_column;
          span {
            @include d_flex;
            @extend .align_items_center;
          }
        }
      }

      &.group_data_listing {
        td {
          &:first-child {
            width: 2%;
            padding-left: 2.2rem;
            padding-right: 0;
          }

          &:nth-child(2) {
            width: 13%;
          }

          &:nth-child(3) {
            width: 10%;
          }

          &:nth-child(4) {
            width: 18%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 24%;
          }

          &:nth-child(6) {
            width: 19%;
          }

          &:nth-child(7) {
            width: 5%;
          }
        }

        &.group_super_list {
          td {
            &:first-child {
              width: 5%;
              padding-left: 2.2rem;
              padding-right: 0;
            }
          }
        }
        .last_three {
          .dot_icon_modal {
            .dot_menu_modal {
              bottom: 0;
            }
          }
        }
      }

      &.group_data_listing {
        &.partner_super_admin {
          td {
            &:first-child {
              width: 3%;
              padding-left: 2.2rem;
              padding-right: 0;
            }

            &:nth-child(2) {
              width: 10%;
            }

            &:nth-child(3) {
              width: 9%;
            }

            &:nth-child(4) {
              width: 13%;
              white-space: normal;
            }

            &:nth-child(5) {
              width: 20%;
            }

            &:nth-child(6) {
              width: 10%;
            }

            &:nth-child(7) {
              width: 4%;
            }
          }

          &.group_super_list {
            td {
              &:first-child {
                width: 5%;
                padding-left: 2.2rem;
                padding-right: 0;
              }
            }
          }
        }
      }

      &.group_data_listing {
        &.partner_local_admin {
          td {
            &:first-child {
              width: 3%;
              padding-left: 2.2rem;
              padding-right: 0;
            }

            &:nth-child(2) {
              width: 10%;
            }

            &:nth-child(3) {
              width: 9%;
            }

            &:nth-child(4) {
              width: 13%;
              white-space: normal;
            }

            &:nth-child(5) {
              width: 20%;
            }

            &:nth-child(6) {
              width: 10%;
            }

            &:nth-child(7) {
              width: 4%;
            }
          }

          &.group_super_list {
            td {
              &:first-child {
                width: 5%;
                padding-left: 2.2rem;
                padding-right: 0;
              }
            }
          }
        }

        &.im_local_admin {
          td {
            &:first-child {
              width: 3%;
              padding-left: 2.2rem;
              padding-right: 0;
            }

            &:nth-child(2) {
              width: 10%;
            }

            &:nth-child(3) {
              width: 9%;
            }

            &:nth-child(4) {
              width: 13%;
              white-space: normal;
            }

            &:nth-child(5) {
              width: 20%;
            }

            &:nth-child(6) {
              width: 10%;
            }

            &:nth-child(7) {
              width: 4%;
            }
          }

          &.group_super_list {
            td {
              &:first-child {
                width: 5%;
                padding-left: 2.2rem;
                padding-right: 0;
              }
            }
          }
        }
      }

      &.skip_modal_listing {
        min-width: 99.7rem;

        td {
          &:first-child {
            width: 25%;
            padding-left: 2.2rem;
            padding-right: 0;
          }

          &:nth-child(2) {
            width: 13%;
          }

          &:nth-child(3) {
            width: 13%;
          }

          &:nth-child(4) {
            width: 25%;
            white-space: normal;

            .skip_data {
              max-width: 18rem !important;
            }
          }

          &:nth-child(5) {
            width: 10%;
          }
        }
      }

      &.content_list {
        .short_list {
          .tooltip {
            right: -4rem !important;
          }
        }
        td {
          &:first-child {
            width: 15%;

            .thead {
              padding-left: 0;
            }
          }

          &:nth-child(2) {
            width: 25%;
          }

          &:nth-child(3) {
            width: 13%;
          }

          &:nth-child(4) {
            width: 16%;
            white-space: normal;
          }

          &:nth-child(5) {
            width: 18%;
          }

          .user_pic {
            width: 5.6rem;
            height: 5.6rem;
            @include over_hidden;
            border-radius: 0.4rem;
            padding: 0;
            justify-content: unset;
            &.location_pic {
              width: 2.4rem;
              height: 2.4rem;
              border-radius: 10rem;
              margin-right: 0.8rem;
            }
            img {
              @include w_100;
              height: 100%;
              @extend .img_object_center;
              border-radius: 0.4rem;
              border: 0.1rem solid rgba(19, 115, 231, 0.4);
            }
          }

          .name_toolkit {
            .user_name {
              gap: 0.4rem;
              @extend .d_flex_align;

              img {
                width: 1.6rem;
                height: 1.6rem;
              }
            }

            .full_username {
              img {
                @include d_block;
              }
            }

            &.events_name_toolkit {
              .user_name {
                max-width: 100%;
                @include d_block;
              }
            }

            .admin_type_name {
              @extend .d_inline_block;
              width: auto;
              max-width: 20rem;
              @extend .white_space_nowrap;
              min-height: 0;
              line-height: 2.1rem;
            }
          }
        }

        tbody {
          tr {
            &:first-child {
              .preview_on_hover {
                top: 100%;
              }
              .preview_on_hover {
                min-width: 30.8rem;
              }
            }

            &:nth-last-child(-n + 2) {
              .preview_on_hover {
                bottom: 0%;
                top: unset;
                transform: translateY(0);
                right: 0;
              }
            }

            &:nth-last-child(-n + 3) {
              .preview_on_hover {
                bottom: 0%;
                top: unset;
                transform: translateY(0);
                right: 0;
              }
            }

            &:nth-last-child(-n + 4) {
              .preview_on_hover {
                bottom: 0%;
                top: unset;
                transform: translateY(0);
              }
            }

            &.first_one {
              &:nth-last-child(-n + 4) {
                .preview_on_hover {
                  top: 0;
                  transform: translateY(0);
                  bottom: auto;
                }
              }
            }
          }
        }
        .name_toolkit {
          &:hover {
            .tooltip {
              @include d_block;
            }
          }
        }
      }

      &.project_list {
        .user_pic {
          width: 5.6rem;
          height: 5.6rem;
          @include over_hidden;
          border-radius: 0.4rem;
          padding: 0;
          justify-content: unset;

          img {
            @include w_100;
            height: 100%;
            @extend .img_object_center;
            border-radius: 0.4rem;
            border: 0.1rem solid rgba(19, 115, 231, 0.4);
          }
        }
      }

      &.disable_table {
        tbody {
          tr {
            opacity: 0.5;
            pointer-events: none;
            background: linear-gradient(0deg, $border-color, $border-color), linear-gradient(0deg, #f6f6f6, #f6f6f6);
          }
        }
      }
      &.events_guest_table {
        min-width: 100%;
        border: 0;
        thead {
          tr {
            z-index: 2;
          }
        }
        tbody {
          tr {
            td {
              > div {
                @include position_relative;
              }
              .guest_inner {
                @include position_relative;
                flex: 1;
                .guest_name {
                  margin-bottom: 0;
                  gap: 0.2rem;
                  .guest_name_wp {
                    @extend .align_items_center;
                    .mentor_mentee {
                      &:before {
                        content: '|';
                        color: $border-color;
                        margin: 0 12px;
                      }
                      span {
                        @extend .fs_12;
                        @extend .montserrat_bold;
                        color: $secondary-color;
                        text-transform: uppercase;
                      }
                    }
                  }
                  .guest_detail {
                    color: $tab-text;
                    @include fs_16;
                    @extend .montserrat_medium;
                  }
                  .mentor_mentee {
                    .guest_detail {
                      color: $tab-text;
                      @extend .fs_12;
                      @extend .montserrat_medium;
                      line-height: 140%;
                      @include d_block;
                    }
                  }
                  .guest_detail {
                    &:hover {
                      .tooltip {
                        @include d_block;
                        left: 0;
                      }
                    }
                  }
                }
                .guest_pic {
                  width: 3.2rem;
                  min-width: 3.2rem;
                  height: 3.2rem;
                  img {
                    width: 3.2rem;
                    height: 3.2rem;
                  }
                }
              }
              .guest_pop_icon {
                @include position_relative;
                padding: 0 1.6rem;
                &::after {
                  content: '';
                  @include position_absolute;
                  top: 50%;
                  left: 0;
                  border-right: 0.1rem solid #e0e0e0;
                  width: 0.1rem;
                  height: 2.5rem;
                  transform: translateY(-50%);
                }
                ul {
                  li {
                    &:hover {
                      .tooltip {
                        @include d_block;
                        white-space: normal;
                        min-width: 27rem;
                        top: -6rem;
                      }
                    }
                  }
                }
              }
              .dot_icon {
                border-radius: 3rem;
                padding: 0.8rem 1.2rem;
                max-width: max-content;
                @extend .cursor_pointer;
                @include d_flex_center;
                margin: 0 auto;
                min-height: 3.2rem;
                min-width: 11.7rem;
                .attendance {
                  color: $black-color;
                  @extend .text_center;
                  @extend .fw_500;
                  @extend .fs_12;
                  line-height: 140%;
                }
                &.invitation_wrap {
                  background: rgba(234, 51, 35, 0.2);
                }
                &.event_disabled {
                  background-color: #eeeeee;
                  opacity: 0.6;
                  cursor: default;
                }
              }
              .dot_menu_modal {
                z-index: 1;
              }
            }
            &:nth-child(4) {
              .dot_icon_modal {
                .dot_menu_modal {
                  height: 16.4rem;
                }
              }
            }
          }
        }
        &.table {
          td {
            &:first-child {
              width: 18%;
              padding-left: 1.2rem;
              .thead {
                padding-left: 0;
              }
            }
            &:nth-child(2) {
              width: 15%;
            }
            &:nth-child(3) {
              width: 15%;
            }
            &:nth-child(4) {
              width: 18%;
            }
            &:nth-child(5) {
              width: 15%;
            }
            &:nth-child(6) {
              width: 15%;
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(6) {
              .thead {
                @extend .justify_content_center;
              }
            }
          }
        }
      }
    }
  }
  &.calenderPopUp_wrapper {
    padding: 0;
    margin: 0;
    .table_responsive {
      border-radius: 0;
    }
    .event_skeleton {
      max-width: 15rem;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.pagination {
  padding: 0 3.2rem;

  &.mentor_pagination {
    padding: 0;
  }

  .par_page {
    @include d_flex_center;
    @extend .justify_content_end;
    gap: 4rem;
    @include w_100;

    .row_par_page {
      @include d_flex_center;
      gap: 1.6rem;

      h4 {
        @include fs_16;
        @extend .fw_500;
        line-height: 140%;
        color: $black-color;
      }

      .form_control {
        @extend .radius_4;
        padding: 0.8rem 1.6rem;
        border: 0.1rem solid $separtor-color;
        appearance: none;
        outline: none;
        background: url(../../../public/images/pagi_arrow.svg) no-repeat center right $white-color;
        background-size: auto;
        @include w_100;
        max-width: 15.2rem;
        @extend .cursor_pointer;
      }

      .basic-single {
        min-width: 15.2rem;
        max-width: 15.2rem;
        border-radius: 0.4rem;
        background: $white-color;
        box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
        outline: none;

        .css-1u9des2-indicatorSeparator {
          opacity: 0;
        }
      }
    }

    .count_page {
      margin-bottom: 0;

      p {
        @include fs_16;
        line-height: 140%;
        @extend .fw_500;
        @extend .text_center;
        color: $black-color;
      }
    }

    .left_right_arrow {
      @include d_flex_center;
      gap: 3.2rem;

      .click_arrow {
        @include d_block;
        @extend .cursor_pointer;
        @extend .radius_round;
        @include d_flex_center;
        border: 0.2rem solid transparent;
        padding: 0.6rem;

        &:hover {
          border: 0.2rem solid $primary-color;
          @extend .radius_round;
          @include d_flex_center;
          padding: 0.6rem;
        }

        &.left_page {
          img {
            transform: rotate(180deg);
          }
        }

        &.right_list {
          img {
            transform: rotate(180deg);
          }
        }

        &.disable {
          img {
            opacity: 0.4;
          }
        }
      }
    }
  }
}

.custom-checkbox {
  @extend .position-relative;
  .checked {
    & + label {
      @extend .position-relative;
      @extend .cursor_pointer;
      padding: 0;
    }

    & + label::before {
      content: '';
      @extend .d_inline_block;
      vertical-align: text-top;
      width: 1.6rem;
      height: 1.6rem;
      background-color: $white-color;
      border: 0.1rem solid $tab-text;
      border-radius: 0.3rem;
      margin-left: -1.3rem;
      margin-top: 0.1rem;
      @include trans;
    }

    label::before {
      background-color: $primary-color;
      border-color: transparent;
    }

    &:checked + label:before {
      background-color: $primary-color;
      border-color: transparent;
      @include trans;
    }

    & + label::after {
      content: '';
      @include position_absolute;
      top: -0.3rem;
      left: -1.3rem;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 0.4rem;
      height: 1rem;
      border: 0.1rem solid $white-color;
      border-width: 0 0.2rem 0.2rem 0;
      transform: scale(0) rotate(45deg);
      @include trans;
    }

    &:checked + label::after {
      transform: scale(1) rotate(45deg);
    }
  }
}

.form_group {
  &.custom-search-box {
    .form_control {
      border-radius: 10rem;
      border: 0.1rem solid var(--grey-colors-grey-5, $border-color);
      background: $white-color;
      min-height: 4rem;
      @include fs_16;
      line-height: 140%;
      color: $title-color;
      padding-left: 4.5rem;

      &::placeholder {
        color: $form-input-color;
      }
    }

    .serch_icon {
      @include d_flex_center;
      width: 2.4rem;
      height: 2.4rem;
      @include position_absolute;
      left: 0.8rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.toster_waper {
  @extend .position_fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 999;

  .toster {
    @extend .radius_8;
    border: 0.2rem solid $success-msg-color;
    background: $success-bg;
    padding: 0.8rem 2.4rem;
    min-height: 6.6rem;
    @include d_flex;
    @extend .align_items_center;
    margin-bottom: 1.5rem;

    .msgwrapper {
      @include w_100;
      gap: 3.2rem;

      .msg-text {
        gap: 0.8rem;

        p {
          @include fs_16;
          @include fw_400;
          line-height: 140%;
          color: $black-color;
        }
      }

      .undo_close {
        .undobtn {
          @include fs_16;
          @include fw_700;
          line-height: 150%;
          color: $primary-color;
          margin-right: 3.2rem;
        }
      }
    }

    &.Danger {
      border: 0.2rem solid $red-border;
      background-color: #fffafa;

      .msgwrapper {
        @include w_100;

        .msg-text {
          svg {
            path {
              fill: $red-required;
            }
          }
        }
      }
    }

    &.Draft {
      border-radius: 0.8rem;
      border: 0.2rem solid $primary-color;
      background: #f3f9ff;
    }
  }
}

.pagination {
  padding: 0 3.2rem;
  .par_page {
    @include d_flex_center;
    @extend .justify_content_end;
    gap: 4rem;
    @include w_100;
    background: $white-color;
    box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
    border-radius: 0 0 8px 8px;
    border: 0.1rem solid $border-color;
    border-top: 0;
    min-height: 5.6rem;
    @extend .white_space_nowrap;
    padding: 0.8rem 6.4rem;

    .row_par_page {
      @include d_flex_center;
      gap: 16px;

      h4 {
        @include fs_16;
        @extend .fw_500;
        line-height: 140%;
        color: $black-color;
      }

      .form_control {
        @extend .radius_4;
        padding: 0.8rem 1.6rem;
        border: 0.1rem solid $separtor-color;
        appearance: none;
        outline: none;
        background: url(../../../public/images/pagi_arrow.svg) no-repeat center right$white-color;
        background-size: auto;
        @include w_100;
        max-width: 15.2rem;
        @extend .cursor_pointer;
      }

      .basic-single {
        min-width: 15.2rem;
        max-width: 15.2rem;
        border-radius: 4px;
        background: $white-color;
        box-shadow: 0px 0px 30px 0px rgba(116, 116, 116, 0.15);
        outline: none;
      }
    }

    .count_page {
      margin-bottom: 0;

      p {
        @include fs_16;
        line-height: 140%;
        @extend .fw_500;
        @extend .text_center;
        color: $black-color;
      }
    }

    .left_right_arrow {
      @include d_flex_center;
      gap: 3.2rem;

      .click_arrow {
        @include d_block;
        @extend .cursor_pointer;
        @extend .radius_round;
        @include d_flex_center;
        border: 0.2rem solid transparent;
        padding: 0.6rem;

        &:hover {
          border: 0.2rem solid $primary-color;
          @extend .radius_round;
          @include d_flex_center;
          padding: 0.6rem;
        }

        &.left_page {
          @include position_relative;

          &:hover {
            .tooltip {
              @include d_block;
              top: -5.5rem;
              left: -3rem;
            }
          }

          img {
            transform: rotate(180deg);
          }
        }

        &.right_list {
          @include position_relative;
          &:hover {
            .tooltip {
              @include d_block;
              top: -5.5rem;
              left: -3rem;
            }
          }

          img {
            transform: rotate(180deg);
          }
        }

        &.right {
          @include position_relative;
          &:hover {
            .tooltip {
              @include d_block;
              top: -5.5rem;
              left: -3rem;
            }
          }
        }

        &.left {
          @include position_relative;
          &:hover {
            .tooltip {
              @include d_block;
              top: -5.5rem;
              left: -3rem;
            }
          }
        }

        &.disable {
          pointer-events: none;

          img {
            opacity: 0.4;
          }
        }
      }
    }
  }
}

.remove_tooltip {
  @include position_relative;

  &:hover {
    .tooltip {
      @include d_block;
      left: -7rem;
    }
  }
}

.switch {
  @include d_flex_center;

  .switch_name {
    color: $tab-text;
    @extend .fs_14;
    @include fw_700;
    line-height: 140%;
    margin-right: 1.6rem;

    &.text_blue {
      color: $primary-color;
    }
  }

  .switch_btn {
    width: 4.4rem;
    height: 2.4rem;
    background: $border-color;
    border-radius: 3rem;
    @include position_relative;
    @include over_hidden;

    span {
      left: 0.2rem;
      top: 50%;
      transform: translateY(-50%);
      @extend .d_inline_block;
      background: $white-color;
      width: 2rem;
      height: 2rem;
      @include d_block;
      @extend .radius_round;
      font-size: 0;
      @include position_relative;
      @include trans;
    }
  }

  .switch_checkbox {
    @include w_100;
    height: 100%;
    @include position_absolute;
    opacity: 0;
    z-index: 1;
    @extend .cursor_pointer;
  }

  .switch_checkbox:checked + span {
    left: 50%;
    @include trans;
  }

  .switch_btn:has(> .switch_checkbox:checked) {
    background: $primary-color !important;
    @include trans;
  }
}

.switch .switch_name:has(~ .switch_btn :checked) {
  color: $primary-color !important;
}

.form_group {
  .react-datepicker-wrapper {
    @include w_100;

    .react-datepicker__input-container {
      input {
        background: url(../../../public/images/Calendar.svg) right 1.6rem center no-repeat;
        background-repeat: no-repeat;
      }
    }
  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      @include w_100;

      .react-datepicker {
        @include w_100;
        border: 0.1rem solid $separtor-color;

        .react-datepicker__month-container {
          float: inherit;

          .react-datepicker__current-month {
            font-size: 1.4rem;
            padding: 0.5rem 0 1.2rem;
          }

          .react-datepicker__day-name {
            font-size: 1.2rem;
            width: 3.5rem;
          }

          .react-datepicker__day {
            width: 3.5rem;
            font-size: 1.2rem;
          }
        }
      }

      .react-datepicker__navigation--previous {
        top: 1rem;
      }
    }
  }
}

.form_group {
  .more_school_list_wrapper {
    max-height: 11.5rem;
    overflow: auto;
    .more_school_list {
      max-height: 15rem;
      overflow: auto;
      padding: 0.5rem;

      .add_school_list {
        @include d_flex_center;
        @extend .justify_content_start;
        @extend .flex_wrap;
        gap: 0.8rem;

        .add_school_inst_name {
          @include fs_16;
          @extend .fw_500;
          line-height: 2.4rem;
          color: $black-color;
          padding: 0.8rem 1.6rem;
          @extend .radius_30;
          background: $grey-color;
          margin-bottom: 0.8rem;
          @include d_flex_center;
          gap: 0.8rem;

          img {
            @extend .cursor_pointer;
          }

          .recepint_overflow {
            @include d_block;
            @include over_hidden;
            @extend .text_ellipsis;
            @extend .white_space_nowrap;
            width: auto;
          }
        }
        .add_school_inst_name_child {
          background-color: $grey-color;
        }
      }
    }
  }
}

.status_tab {
  &.school_inst_tab {
    background: $grey-color;
    color: $tab-head;
    color: $black-color;
    border-radius: 10rem;
    margin-bottom: 0.5rem;
    min-height: 3rem;
    padding: 0.9rem 1.2rem;

    .user_pic {
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 10rem;

      &.content_user_pic {
        padding: 0;
        width: 2.4rem;
        height: 2.4rem;
        @extend .radius_round;
        @extend .m_0;
      }
    }
    .annaunce_mentees {
      @extend .m_0;
    }
  }
}

.user_pic {
  @include d_flex_center;
  @extend .fs_10;
  @extend .fw_600;
  width: 2.4rem;
  height: 2.4rem;
  @extend .radius_round;
  margin-right: 1rem;
  color: $white-color;
  padding: 1rem;
  &.profile_img_width {
    width: 3rem;
    height: 3rem;
    margin-right: 0;
  }
  img {
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    @extend .radius_round;
  }

  &.user_pic_name {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
  }

  &.mentees_ganert_pic {
    width: 3.2rem;
    height: 3.2rem;
    margin: 0;
  }

  &.mentess_genrat_name_listing {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1rem;
  }

  &.user_name_ganret {
    width: 3rem;
    height: 3rem;
    color: $white-color !important;
    font-size: 1.2rem !important;
  }

  &.content_user_pic {
    padding: 0;
    width: 2.4rem;
    height: 2.4rem;
    @extend .radius_round;
    @extend .m_0;
  }
}

.group_modal_sending {
  max-height: initial;
  overflow: auto;

  .mesg_image_upld_wrapper {
    padding: 0 2.8rem !important;
    height: 26.5rem;

    .upload_file_mesg {
      grid-template-columns: 29rem 29rem;
    }

    ul {
      padding-bottom: 0 !important;
      max-width: 70rem;
    }

    .upload_file_mesg {
      .profile_img {
        .pdf_name {
          max-width: 15rem !important;
        }
      }
    }
  }
}

.pairs_img_view {
  .user_pic {
    margin: 0;

    .common_match {
      border: 0.2rem solid $form-input-border;
      border-radius: 10rem;
      max-width: 2.4rem;
      height: 1.4rem;
      @include position_relative;
      padding: 1rem;

      img {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 10rem;
      }

      .generate_pic {
        width: 1.6rem;
        min-width: 1.6rem;
        height: 1.6rem;
        border-radius: 10rem;
      }

      .generate_name_of_mentees {
        @include d_flex_center;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 10rem;
        font-size: 0.5rem;
        color: $white-color;
      }

      .top_pic {
        @include position_absolute;
        top: -0.6rem;
        left: -0.5rem;
      }

      .bottom_pic {
        @include position_absolute;
        bottom: -0.6rem;
        right: -0.5rem;
      }
    }
  }
}

.css-1jqq78o-placeholder {
  color: $black-color !important;
  @include fs_16;
  @include fw_400;
  opacity: 60%;
}

.reporting_page {
  .date_range_picker_filter {
    .rdrDefinedRangesWrapper {
      @include d_none;
    }
  }
}

.date_range_picker_filter {
  @include position_absolute;
  z-index: 2;
  background: $white-color;
  border-radius: 0.4rem;
  background: $white-color;
  box-shadow: 0px 0px 3rem 0px rgba(116, 116, 116, 0.15);
  right: auto;
  left: 100%;
  @include media_query(four_extra_large) {
    left: -20rem;
  }

  .rdrMonths {
    padding: 0 4rem;
    .rdrMonth {
      .rdrMonthName {
        @extend .text_center;
        color: $black-color;
        @include fs_16;
        @extend .fw_600;
      }

      .rdrDays {
        .rdrDay {
          .rdrDayNumber {
            width: 2.4rem;
            height: 2.4rem;
            left: 1rem;

            > span {
              color: $tab-text;
              color: $black-color;
              @extend .poppins_font;
              font-size: 1.2139rem;
              font-weight: 400;
              line-height: normal;

              &::after {
                @include d_none;
              }
            }
          }

          .rdrStartEdge,
          .rdrEndEdge {
            background-color: $primary-color !important;
            + .rdrDayNumber {
              > span {
                color: $white-color;
              }
            }
          }

          .rdrInRange {
            background-color: $primary-color !important;
            height: auto;
            right: 0;
            left: 0;
          }

          &.rdrDayPassive {
            .rdrDayNumber {
              > span {
                color: $light-gray;
              }
            }
          }

          &.rdrDayToday {
            .rdrDayNumber {
              > span {
              }
            }
          }

          .rdrInRange {
            + .rdrDayNumber {
              > span {
                color: $white-color;
              }
            }
          }
        }
      }

      .rdrWeekDays {
        .rdrWeekDay {
          color: $black-color;
          @extend .poppins_font;
          font-size: 1.2139rem;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .rdrDayDisabled {
    background-color: $white-color;

    .rdrDayNumber {
      span {
        color: lightgrey !important;
      }
    }
  }

  .rdrMonthPicker {
    select {
      font-size: 1.3rem;
      color: $black-color;
      @extend .fw_500;

      &:hover {
        background-color: $white-color;
      }
    }
  }

  .rdrYearPicker {
    select {
      font-size: 1.3rem;
      color: $black-color;
      @extend .fw_500;

      &:hover {
        background-color: $white-color;
      }
    }
  }

  .rdrNextPrevButton {
    @include position_absolute;
    top: 50%;
    border: 0.1rem solid $primary-color;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 10rem;
    background-color: $white-color;
    @include d_flex_center;

    &.rdrPprevButton {
      left: 0;

      &::after {
        content: '';
        @include position_absolute;
        background: url(../../../public/images/calendar_left_arrow.svg) no-repeat top center;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &.rdrNextButton {
      right: 0;

      &::after {
        content: '';
        @include position_absolute;
        background: url(../../../public/images/calendar_right_arrow.svg) no-repeat top center;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    > i {
      @include d_none;
    }

    &:hover {
      background-color: $white-color;
    }
  }
}

.form_group {
  .rc-time-picker {
    @include d_block;

    + .input_remove {
      padding: 0.5rem;
      margin-top: -0.4rem;
      background-color: $white-color;
    }
  }
}

.rc-time-picker {
  .rc-time-picker-input {
    border: 0.1rem solid $separtor-color;
    padding-right: 1.6rem;
    min-height: 4.8rem;
    @extend .cursor_pointer;
    @include fs_16;
    color: $black-color;
    background: url(../../../public/images/clock_icon.svg) no-repeat right 1.5rem center;

    &::placeholder {
      opacity: 60%;
    }
  }
}

.rc-time-picker-panel {
  min-width: 32rem;
  top: 65rem !important;
  z-index: 10 !important;

  .rc-time-picker-panel-inner {
    @include w_100;
  }

  .rc-time-picker-panel-select {
    width: 33.33%;
    scrollbar-width: thin;

    /* For FireFox Only */
    ul {
      @include d_flex;
      @extend .flex_wrap;
      @extend .flex_direction_column;

      li {
        &:hover {
          background-color: $blue-color;
          color: $white-color;
        }

        &.rc-time-picker-panel-select-option-selected {
          background-color: $primary-color;
          color: $white-color;
        }
      }
    }

    &:nth-child(2) {
      ul {
        @include d_flex;

        li {
          &:first-child {
            order: initial;
          }
        }
      }
    }
  }

  .rc-time-picker-panel-input-wrap {
    @include d_none;
  }
}

.alternate_que {
  color: $form-lable-color;
  @include fw_700;
  @extend .fs_14;
  line-height: 140%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;

  span {
    @include fw_400;
  }
}

.multi_note {
  .serch_icon_remove {
    @include position_absolute;
    right: 0.7rem;
    bottom: 0.5rem;
  }
}

.table_wrapper {
  .table_responsive {
    .table {
      &.more_page {
        tr {
          &.last_three {
            .dot_icon_modal {
              .dot_menu_modal {
                bottom: auto !important;
              }
            }

            &:last-child {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: 0;
                }
              }
            }

            &:nth-last-child(-n + 1) {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: 0 !important;
                }
              }
            }

            &:nth-last-child(-n + 3) {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: 0 !important;
                }
              }
            }

            &:last-child {
              .dot_icon_modal {
                .dot_menu_modal {
                  bottom: 0 !important;
                }
              }
            }
          }

          &.first_one {
            .dot_icon_modal {
              .dot_menu_modal {
                bottom: auto;
              }
            }
            &:first-child {
              .dot_icon_modal {
                .dot_menu_modal {
                  @include position_absolute;
                }
              }
            }
            &.last_three {
              &:first-child {
                .dot_icon_modal {
                  .dot_menu_modal {
                    @extend .position_fixed;
                  }
                }
              }
            }
          }

          &.last_three {
            .dot_icon_modal {
              .dot_menu_modal {
                &.last_dot_menu_modal {
                  bottom: auto;
                }
              }
            }
          }

          &.first_one {
            .dot_icon_modal {
              .dot_menu_modal {
                &.last_dot_menu_modal {
                  bottom: auto;
                }
              }
            }
          }

          .dot_icon_modal {
            .dot_menu_modal {
              &.last_dot_menu_modal {
                bottom: auto;
              }
            }
          }

          &:nth-last-child(-n + 3) {
            .dot_icon_modal {
              .dot_menu_modal {
                &.last_dot_menu_modal {
                  bottom: 0;
                }
              }
            }
          }

          &:nth-last-child(-n + 2) {
            .dot_icon_modal {
              .dot_menu_modal {
                &.last_dot_menu_modal {
                  bottom: 0;
                }
              }
            }
          }

          &.last_three.first_one {
            .dot_icon_modal {
              .dot_menu_modal {
                bottom: auto !important;
                @extend .position_fixed;

                &.default_table_modal {
                  @extend .position_fixed;
                  bottom: auto;
                }
              }
            }
          }
        }
      }

      tr {
        td {
          @include position_relative;

          &.w_adjustment {
            width: 1%;
          }
        }
      }
    }
  }
}

textarea#templateTextArea {
  height: 36rem !important;
}

.resize_none {
  resize: none;
}

.custom-checkbox {
  [type='checkbox'] {
    opacity: 0 !important;
    @include position_absolute;
  }

  &.checked {
    opacity: 0 !important;
  }
}
.popup_header_sticky {
  position: sticky;
  top: -0.1rem;
  right: 2rem;
  left: 3rem;
  z-index: 999;
  background-color: $white-color;
}

.progress_bar_csv {
  background-color: $white-color;
  @extend .radius_10;
  .progress {
    background-color: $primary-color;
    height: 0.5rem;
    @include d_block;
    @extend .radius_10;
    max-width: 100%;
  }
}

.schoo_name {
  .pair_title_name {
    color: $secondary-color;
    @include fw_700;
    font-size: 1rem;
    @extend .fs_10;
    line-height: 140%;
  }
}

.table_wrapper .table_responsive .table.events_guest_table tbody tr.last_three:last-child td .dot_menu_modal {
  bottom: 0 !important;
}

.table_wrapper
  .table_responsive
  .table.events_guest_table
  tbody
  tr.last_three:nth-last-child(-n + 3)
  td
  .dot_menu_modal {
  bottom: 0 !important;
  height: 16.4rem;
}

.table_wrapper .table_responsive .table.events_guest_table tbody tr.first_one.last_three:last-child td .dot_menu_modal {
  bottom: auto !important;
  right: auto;
}
.table_wrapper .table_responsive .table.events_guest_table tbody tr.first_one.last_three td .dot_menu_modal {
  right: auto;
}

.table_wrapper
  .table_responsive
  .table.events_guest_table
  tbody
  tr.first_one.last_three:nth-last-child(-n + 3)
  td
  .dot_menu_modal {
  bottom: auto !important;
}
